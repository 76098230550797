import React from "react";
import { Form } from "react-bootstrap";
import Header from "../../Dashboard/Static/Header/Header";
import Footer from "../../Dashboard/Static/Footer/Footer";
import moment from "moment";
import Axios from "axios";
import { apiBaseUrl } from "../../../configs";
import { CostNumberFormat } from "../../Components/NumberFormat/CostNumberFormat";
import { ReusableModal } from "../../Components/ReusableModal/ReusableModal";
import Swal from 'sweetalert2';
import refreshToken from "../../../RefreshToken";
import { CreateGAPCard } from "../../Dashboard/Oppportunity/GAP/CreateGAPCard";
import { dateSanitizer } from "../../Utils/dateSanitizer";
import { emptyLeadingSpaceSanitizer, numberFormatSanitizerInt } from "../../Utils/sanitizeNumber";
import { logger } from "../../../logger";
import AdditionalSponsors from "../../Dashboard/Oppportunity/GAP/AdditionalSponsors";
import { MyBorrowerDocumentTableComponent } from "../../Dashboard/Dashboard/BorrowerProfile/MyBorrowerDocumentTableComponent";
import { capitalizeText } from "../../Utils/capitalizeText";
import { ReactDatePicker } from "../../Components/DateFormat/ReactDatePicker";
import AddButton from "../../Components/AddButton/AddButton";
import AddButtonSmall from "../../Components/AddButtonSmall/AddButtonSmall";
import { ClosedGapOppAdmin, PendingGapChangesAdmin, PendingOppAdmin, PublishedOppAdmin } from "../../Components/CloseOpportunityBanner/CloseOpportynityBanners";
import { Link } from "react-router-dom";
import { revokeAccessApi } from "../../../Store/Api/OpportunitiesApi/Opportunity";
import { GAPExclusiveMembers } from "../../Dashboard/Oppportunity/GAP/GAPExclusiveMembers";
import NumberFormat from "react-number-format";
import AdminSidePanel from "../../Dashboard/Static/SidePanel/AdminSidePanel";

const log = logger("AdminGapOpportunitySummary");

class GAPOpportunitySummary extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount_requested: 0,
            original_borrower: "",
            assignedBroker: "",
            closing_at: "",
            loan_term: 0,
            opportunityName: "",
            proposed_fee: 0,
            sponsors: [],
            external_id: "",
            account_id: "",
            summary: "",
            use_of_funds: "",
            owner_name: "",
            owner_id: "",
            publish_status: "",
            admin_status: "",
            store_status: "",
            visibility: "",
            mp_id: "",
            borrower_name: "",
            borrowerName: "",
            borrower_type: "",
            borrower_id: "",
            email: "",
            phone: "",
            reason: "",
            summary_external_id: "",
            summary_internal_id: "",
            summary_new: "",
            amount_new: "",
            loan_term_new: 0,
            proposed_fee_new: 0,
            use_of_funds_new: "",
            sponsors_new: [],
            borrower_profile_external_id: "",
            borrower_profile_internal_id: "",
            borrower_name_new: "",
            borrower_type_new: "",
            attachments: [],
            fileNames: [{ url: '' }],
            fileNamesFromApi: [{ url: '' }],
            access: [],
            bans: [],
            brokerList: [],
            lenderList: [],
            excludedMembers: [],
            invites: [],
            success: false,
            opportunityInfoPending: false,
            borrowerProfilePending: false,
            closeOpportunityModal: false,
            reloadInterestedLender: false,
            openApproveConfirmation: false,
            openRejectConfirmation: false,
            openConfirmation: false,
            openConfirmInvite: false,
            showApproveModal: false,
            showRejectModal: false,
            isBlocking: false,
            showError: false,
            errorMsg: false,
            validationError: false,
            openPrompt: false,
            nextLocation: null,
            profile_status: localStorage.getItem("profile_status"),
            dateValidationError: false,
            showPending: false,
            openSummaryPendingApprove: false,
            openSummaryPendingReject: false,
            openBorrowerProfilePendingApprove: false,
            openBorrowerProfilePendingReject: false,
        };
        this.baseState = { ...this.state }

    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        this.setState({
            mp_id: this.props.match.params.mp_id,
        }, () => {
            if (this.state.mp_id !== undefined && this.state.mp_id !== null) {
                this.getGapSummary(this.state.mp_id);
                this.getGapPendingChanges(this.state.mp_id);
                this.getBorrowerProfile(this.state.mp_id);
                this.getAccessList();
                this.getBansList();
                this.getFilteredBrokers();
                this.getLenderList();
            }
        })
    }

    getGapPendingChanges = (mp_id) => {

        var token = localStorage.getItem('token');

        Axios.get(apiBaseUrl + "/admin/mp/" + mp_id + "/pending_gap", {
            headers: {
                Authorization: token
            }
        }).then(response => {
            if (response.data.summary_previous !== null) {
                this.setState({
                    opportunityInfoPending: true,
                    summary_internal_id: response.data.summary.internal_id,
                    summary_external_id: response.data.summary.external_id,
                    summary_new: response.data.summary.summary,
                    amount_new: response.data.summary.amount_requested,
                    loan_term_new: response.data.summary.loan_term,
                    proposed_fee_new: response.data.summary.proposed_fee,
                    use_of_funds_new: response.data.summary.use_of_funds,
                    sponsors_new: response.data.summary.sponsors,
                })
            } else {
                this.setState({
                    opportunityInfoPending: false
                })
            }

            if (response.data.borrower_profile_previous !== null) {
                this.setState({
                    borrowerProfilePending: true,
                    borrower_name_new: response.data.borrower_profile.name,
                    borrower_type_new: response.data.borrower_profile.type,
                    borrower_email_new: response.data.borrower_profile.email,
                    borrower_phone_new: response.data.borrower_profile.phone,
                    borrower_profile_external_id: response.data.borrower_profile.external_id,
                    borrower_profile_internal_id: response.data.borrower_profile.internal_id,
                })
            } else {
                this.setState({
                    borrowerProfilePending: false
                })
            }
        })

    }

    getGapSummary = (mp_id) => {

        var token = localStorage.getItem('token');

        Axios.get(apiBaseUrl + "/mp/summary_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {

                this.setState({
                    publish_status: response.data.summary.publish_status,
                    admin_status: response.data.summary.admin_status,
                    store_status: response.data.summary.store_status,
                    amount_requested: response.data.summary.amount_requested,
                    original_borrower: response.data.summary.borrower_profile_id,
                    borrower_name: response.data.summary.borrower_name,
                    closing_at: response.data.summary.closing_at === "" ? response.data.summary.closing_at : moment(response.data.summary.closing_at).toDate(),
                    loan_term: response.data.summary.loan_term,
                    opportunityName: response.data.summary.name,
                    proposed_fee: response.data.summary.proposed_fee,
                    sponsors: response.data.summary.sponsors,
                    summary: response.data.summary.summary,
                    use_of_funds: response.data.summary.use_of_funds,
                    visibility: response.data.summary.visibility,
                    owner_name: response.data.owner_name,
                    reason: response.data.summary.conclusion === null ? "" : response.data.summary.conclusion.reason
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getBorrowerProfile = (mp_id) => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/borrower_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    borrowerName: response.data.borrower_profile.name,
                    borrower_type: response.data.borrower_profile.type,
                    borrower_id: response.data.borrower_profile.external_id,
                    email: response.data.borrower_profile.email,
                    phone: response.data.borrower_profile.phone,
                    attachments: response.data.borrower_profile.attachments,
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getAccessList = () => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/access/" + this.state.mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    access: response.data.access
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getBansList = () => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/bans/" + this.state.mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    bans: response.data.bans
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

    }

    handleDateChange = (date) => {
        const dateValid = dateSanitizer(date);
        this.setState({
            isBlocking: true,
            closing_at: dateValid,
            dateValidationError: false
        });
    };

    handleChange = (event) => {
        const isBlocking = event.target.value.length > 0;
        log.info("event", event)
        this.setState({
            [event.target.name]: emptyLeadingSpaceSanitizer(event.target.value),
            isBlocking
        });
    }

    handleNumber = (event) => {
        const isBlocking = event.target.value.length > 0
        this.setState({
            [event.target.name]: numberFormatSanitizerInt(event.target.value),
            isBlocking
        });
    }

    updateField = (e) => {
        e.preventDefault();
        const isBlocking = true;
        this.setState({
            [e.target.name]: emptyLeadingSpaceSanitizer(e.target.value),
            isBlocking
        }, () => {

        });
    };

    close = () => {
        this.setState({
            showModal: false,
            openConfirmation: false,
            openConfirmInvite: false,
            openApproveConfirmation: false,
            openRejectConfirmation: false,
            closeOpportunityModal: false,
            openSummaryPendingApprove: false,
            openSummaryPendingReject: false,
            openBorrowerProfilePendingApprove: false,
            openBorrowerProfilePendingReject: false
        });
    };

    open = () => {
        if (this.state.status === 200) {
            this.setState({ showModal: true });
        }
    };

    hasRealEstateSection = (borrowerType, sectionId) => {

        if (borrowerType === "individual") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment") || sectionId.has("real_estate.insurance") || sectionId.has("real_estate.strata")
        } else if (borrowerType === "corporation") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment")
        }
    }

    approveClick = () => {
        this.setState({
            openApproveConfirmation: true,
        })
    }

    approveSummaryPendingClick = () => {
        this.setState({
            openSummaryPendingApprove: true
        })
    }

    approveBorrowerPendingClick = () => {
        this.setState({
            openBorrowerProfilePendingApprove: true
        })
    }

    handleSummaryApprovePendingClick = () => {

        const token = localStorage.getItem('token');
        const external_id = this.state.summary_external_id;
        const internal_id = this.state.summary_internal_id;

        const data = {
            "internal_id": internal_id
        }

        Axios.put(apiBaseUrl + '/admin/mp/gap_summary/' + external_id + '/approve', data, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    openSummaryPendingApprove: false,
                    opportunityInfoPending: false,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Opportunity Changes Approved',
                        text: '',
                        timer: 2000
                    })
                }

                this.getGapSummary(this.state.mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    rejectSummaryPendingClick = () => {
        this.setState({
            openSummaryPendingReject: true
        })
    }

    handleSummaryRejectPendingClick = () => {

        const token = localStorage.getItem('token');
        const external_id = this.state.summary_external_id;
        const internal_id = this.state.summary_internal_id;

        const data = {
            "internal_id": internal_id
        }

        Axios.put(apiBaseUrl + '/admin/mp/gap_summary/' + external_id + '/reject', data, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    showModal: true,
                    openSummaryPendingReject: false,
                    opportunityInfoPending: false,
                    success: true
                });
                this.getGapSummary(this.state.mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

        if (this.state.success) {
            Swal.fire({
                icon: 'success',
                title: 'Opportunity Rejected',
                text: '',
                timer: 2000
            })
        }
    }

    rejectBorrowerPendingClick = () => {
        this.setState({
            openBorrowerProfilePendingReject: true
        })
    }

    handleBorrowerProfileRejectPendingClick = () => {

        const token = localStorage.getItem('token');
        const external_id = this.state.borrower_profile_external_id;
        const internal_id = this.state.borrower_profile_internal_id;

        const data = {
            "internal_id": internal_id
        }

        Axios.put(apiBaseUrl + '/admin/mp/borrower_gap/' + external_id + '/reject', data, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    showModal: true,
                    openBorrowerProfilePendingReject: false,
                    borrowerProfilePending: false,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Borrower Changes Rejected',
                        text: '',
                        timer: 2000
                    })
                }

                this.getBorrowerProfile(this.state.mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    handleBorrowerProfileApprovePendingClick = () => {
        const token = localStorage.getItem('token');
        const external_id = this.state.borrower_profile_external_id;
        const internal_id = this.state.borrower_profile_internal_id;

        const data = {
            "internal_id": internal_id
        }

        Axios.put(apiBaseUrl + '/admin/mp/borrower_gap/' + external_id + '/approve', data, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    success: true,
                    showModal: true,
                    borrowerProfilePending: false,
                    openBorrowerProfilePendingApprove: false,
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Borrower Changes Approved',
                        text: '',
                        timer: 2000
                    })
                }

                this.getBorrowerProfile(this.state.mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }


    handleApproveClick = () => {

        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;
        Axios.put(apiBaseUrl + '/admin/mp/' + mp_id + '/approve', {}, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    showModal: true,
                    openApproveConfirmation: false,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Opportunity Approved',
                        text: '',
                        timer: 2000
                    })
                }

                this.getGapSummary(mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    rejectClick = () => {
        this.setState({
            openRejectConfirmation: true
        })
    }

    handleRejectClick = () => {

        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;
        Axios.put(apiBaseUrl + '/admin/mp/' + mp_id + '/reject', {}, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    showModal: true,
                    openRejectConfirmation: false,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Opportunity Rejected',
                        text: '',
                        timer: 2000
                    })
                }

                this.getGapSummary(mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getFilteredBrokers = () => {

        const token = localStorage.getItem('token');

        Axios.get(apiBaseUrl + '/admin/accounts?role=broker', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    brokerList: response.data.accounts
                })
                this.handleAssignBroker(this.state.brokerList);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                    if (error.response.data.code === 404) {
                        return error.response.data.code;
                    }
                }

            });
    }

    getLenderList = () => {

        const token = localStorage.getItem('token');

        Axios.get(apiBaseUrl + '/admin/accounts?role=lender&mp_type=gap', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    lenderList: response.data.accounts
                })
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                    if (error.response.data.code === 404) {
                        return error.response.data.code;
                    }
                }

            });
    }


    handleAssignBroker = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
            external_id: event.target.value,
            openConfirmation: true,
        })
    }


    handleInviteLender = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
            account_id: event.target.value,
            openConfirmInvite: true,
        })
    }



    assignBroker = () => {

        const data = {
            "owner_id": this.state.external_id
        }

        const token = localStorage.getItem("token");

        Axios.post(apiBaseUrl + '/admin/mp/' + this.state.mp_id + '/assign_owner', data, {
            headers: { Authorization: token }
        })
            .then(response => {
                this.setState({
                    success: true,
                    openConfirmation: false,
                    assignedBroker: response.data.owner_name
                })

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Opportunity Assigned',
                        text: '',
                        timer: 2000
                    })
                    this.getGapSummary(this.state.mp_id);
                }

            })
            .catch(error => {
                if (error.response.data.code === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Opportunity is already assigned to the user',
                        text: '',
                        timer: 2000
                    })
                }
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

    }

    sendInvitation = () => {

        const data = {
            "account_id": this.state.account_id
        }

        const token = localStorage.getItem("token");

        Axios.post(apiBaseUrl + '/admin/mp/access/' + this.state.mp_id + '/invite', data, {
            headers: { Authorization: token }
        })
            .then(response => {
                this.setState({
                    success: true,
                    reloadInterestedLender: true,
                    openConfirmInvite: false,
                })

                if (this.state.success) {
                    if (this.state.publish_status === "draft" || (this.state.publish_status === "published" && this.state.admin_status === "pending")) {
                        Swal.fire({
                            icon: 'success',
                            title: 'Lender Added',
                            text: '',
                            timer: 2000
                        })
                    } else {
                        Swal.fire({
                            icon: 'success',
                            title: 'Invitation Sent',
                            text: '',
                            timer: 2000
                        })
                    }
                }

                this.getAccessList(this.state.mp_id);

            })
            .catch(error => {
                if (error.response.data.code === 400) {
                    Swal.fire({
                        icon: 'error',
                        title: 'Lender already exists',
                        text: '',
                        timer: 2000
                    })
                }
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

    }

    handleType = (event) => {
        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleVisibility = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        })

        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;

        const data = {
            "visibility": event.target.value
        }

        Axios.put(apiBaseUrl + '/mp/visibility/' + mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    showModal: true,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: 'Opportunity Visibility Changed',
                        text: '',
                        timer: 2000
                    })
                }
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    handleChangeDA(i, e) {
        const { name, value } = e.target;
        let invites = [...this.state.invites];
        invites[i] = { ...invites[i], [name]: emptyLeadingSpaceSanitizer(value) };
        const isBlocking = true
        this.setState({ invites, isBlocking, disableSubmitExclusive: false });
    }

    handleChangeSponsor(i, e) {
        const { name, value } = e.target;
        let sponsors = [...this.state.sponsors];
        sponsors[i] = { ...sponsors[i], [name]: emptyLeadingSpaceSanitizer(value) };
        const isBlocking = true
        this.setState({ sponsors, isBlocking, disableSubmitExclusive: false });
    }

    handleNumberSponsor(i, e) {
        const { name, value } = e.target;
        let sponsors = [...this.state.sponsors];
        sponsors[i] = { ...sponsors[i], [name]: numberFormatSanitizerInt(value) };
        const isBlocking = true
        this.setState({ sponsors, isBlocking, disableSubmitExclusive: false });
    }

    addClick() {
        this.setState((prevState) => ({
            invites: [
                ...prevState.invites,
                { company_name: "", email: "", name: "", role: "lender", type: "read", "add_connection": false },
            ],
            isBlocking: true
        }));
    }

    addSponsorClick() {
        this.setState((prevState) => ({
            sponsors: [
                ...prevState.sponsors,
                { name: "", annual_income: 0, experience: 0, net_worth: 0 },
            ],
            isBlocking: true
        }));
    }

    removeClick(i) {
        let sponsors = [...this.state.sponsors];
        sponsors.splice(i, 1);
        this.setState({ sponsors, isBlocking: true });
    }

    handleSave = (e) => {

        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;

        const data = {
            "name": this.state.opportunityName,
            "borrower_name": this.state.borrower_name,
            "amount_requested": this.state.amount_requested,
            "term": this.state.loan_term,
            "proposed_fee": this.state.proposed_fee,
            "closing_at": moment(this.state.closing_at).format("YYYY-MM-DD"),
            "use_of_funds": this.state.use_of_funds,
            "summary": this.state.summary,
            "sponsors": this.state.sponsors,
        }

        Axios.put(apiBaseUrl + '/mp/summary_gap/' + mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    success: true
                });

                if (this.state.success) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'GAP Opportunity changes saved',
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

                this.getGapSummary(mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }

                Swal.fire({
                    position: 'top-end',
                    icon: 'error',
                    title: 'Error',
                    showConfirmButton: false,
                    timer: 2000
                });
            });

    }

    handleSaveBorrowerProfile = (e) => {

        const token = localStorage.getItem('token');
        const external_id = this.state.borrower_id;
        const data = {
            "name": this.state.borrowerName,
            "personal_identification": this.state.personal_identification,
            "type": this.state.borrower_type,
        }

        Axios.put(apiBaseUrl + '/mp/borrower_gap/' + external_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    success: true
                });

                if (this.state.success) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Borrower Profile changes saved',
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

                this.getBorrowerProfile(this.state.mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

    }

    compareSponsorArrays = (new_sponsors, prev_sponsors) => {
        return JSON.stringify(new_sponsors) === JSON.stringify(prev_sponsors);
    }

    closeOpportunityModal = () => {
        this.setState({
            closeOpportunityModal: true
        })
    }

    closeOpportunity = () => {

        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;

        const data = {
            "reason": this.state.reason
        }

        Axios.put(apiBaseUrl + '/mp/close_gap/' + mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                this.setState({
                    closeOpportunityModal: false,
                    success: true
                });

                if (this.state.success) {
                    Swal.fire({
                        icon: 'success',
                        title: '',
                        text: 'Opportunity Successfully Closed',
                        timer: 2000
                    })
                }

                this.getGapSummary(mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    submitDeleteMember = (external_id) => {
        revokeAccessApi(external_id).then(response => {
            this.setState({
                success2: true,
                showDeleteMembers: false,
            });
            if (this.state.success2) {
                Swal.fire({
                    icon: 'success',
                    title: 'Opportunity Member Removed',
                    text: '',
                    timer: 2000
                })
            }
            this.getAccessList(this.state.mp_id);
        })
            .catch(err => {
                if (err.response !== undefined) {
                    if (err.response.data.code === 401) {
                        refreshToken();
                    }
                }
            })
    }

    render() {

        const sectionIds = new Set(this.state.attachments.map((attachment) => attachment.section));

        return (
            <div>
                <Header />
                <AdminSidePanel />
                {this.state.publish_status === "published" && this.state.admin_status === "approved" && (!this.state.opportunityInfoPending && !this.state.borrowerProfilePending) ? (
                    <PublishedOppAdmin />
                ) : this.state.publish_status === "published" && this.state.admin_status === "pending" ? (
                    <PendingOppAdmin />
                ) : this.state.opportunityInfoPending || this.state.borrowerProfilePending ? (
                    <PendingGapChangesAdmin />
                ) : this.state.publish_status === "closed" && (
                    <ClosedGapOppAdmin />
                )}

                <div className="container">
                    <div className="row">
                        <div className="col pl-0 mb-4">
                            <div className="pagetitle steppertitle float-left">
                                <h1>
                                    <Link to={{ pathname: `/opportunities-gap` }} ><i className="fa fa-arrow-left iconcolor" aria-hidden="true"></i></Link>
                                    GAP Opportunity Listings
                                </h1>
                            </div>
                            <div className="float-right">
                                {this.state.publish_status === "published" && this.state.admin_status === "pending" && (
                                    <>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.approveClick()
                                            }
                                            className="btn primarybtn samewidth mr-3"
                                        >
                                            Approve
                                        </button>
                                        <button
                                            type="button"
                                            onClick={() =>
                                                this.rejectClick()
                                            }
                                            className="btn borderbtn samewidth mr-3"
                                        >
                                            Reject
                                        </button>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="row"></div>
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <form className="user" id="Form" onSubmit={null}>
                                <CreateGAPCard
                                    title="Opportunity Information"
                                    createComponent={
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="title5"> Opportunity Status </h2>
                                            </div>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Publish Status</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="publish_status"
                                                    name="publish_status"
                                                    value={capitalizeText(this.state.publish_status)}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    disabled
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Admin Status</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="admin_status"
                                                    name="admin_status"
                                                    value={capitalizeText(this.state.admin_status)}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    disabled
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Pending Changes</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="store_status"
                                                    name="store_status"
                                                    value={this.state.opportunityInfoPending || this.state.borrowerProfilePending ? "Changes Pending" : "No Changes"}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    disabled
                                                />
                                            </Form.Group>
                                            {this.state.publish_status === "closed" && (
                                                <Form.Group className="form-group col-md-6">
                                                    <Form.Label>Reason for Closing</Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control form-control-user"
                                                        id="reason"
                                                        name="reason"
                                                        value={capitalizeText(this.state.reason)}
                                                        maxLength="25"
                                                        disabled
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Visibility</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="visibility"
                                                    name="visibility"
                                                    value={capitalizeText(this.state.visibility)}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    disabled
                                                />
                                            </Form.Group>
                                            <br />
                                            <div className="col-12 mt-5">
                                                <h2 className="title5">Assigned Broker</h2>
                                            </div>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Assign Opportunity to Broker</Form.Label>
                                                <select
                                                    name="assignedBroker"
                                                    id="assignedBroker"
                                                    value={this.state.assignedBroker || ""}
                                                    onChange={(e) => this.handleAssignBroker(e)}
                                                    className="selectdropdown"
                                                    required
                                                >
                                                    <option>Select One</option>
                                                    {this.state.brokerList.map((data) => (
                                                        <>
                                                            <option value={data.external_id}>{data.profile.name} | {data.profile.company_name === "" ? "No Company" : data.profile.company_name} | {data.email}</option>
                                                        </>
                                                    ))}
                                                </select>
                                            </Form.Group>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Assigned Broker</Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="borrower_name"
                                                    name="borrower_name"
                                                    value={this.state.owner_name === (this.state.borrowerName || this.state.borrower_name) ? "No Broker Assigned" : this.state.owner_name}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    disabled
                                                />
                                            </Form.Group>
                                            <div className="col-12 mt-5"></div>
                                            <div className="col-12">
                                                <div className="rec-child"><h2 className="title5">GAP Opportunity Details</h2></div>
                                                {this.state.opportunityInfoPending && (
                                                    <>
                                                        <div className="rec-child float-right">
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    this.approveSummaryPendingClick()
                                                                }
                                                                className="btn pending-approvebtn samewidth mr-3"
                                                            >
                                                                Approve Changes
                                                            </button>
                                                            <button
                                                                type="button"
                                                                onClick={() =>
                                                                    this.rejectSummaryPendingClick()
                                                                }
                                                                className="btn pending-rejectbtn samewidth mr-3"
                                                            >
                                                                Reject Changes
                                                            </button>
                                                        </div>
                                                    </>
                                                )}
                                            </div>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Opportunity Name <i className="fa fa-asterisk"></i></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="opportunityName"
                                                    name="opportunityName"
                                                    value={this.state.opportunityName}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                />
                                            </Form.Group>
                                            {/* <Form.Group className="form-group col-md-6">
                                                <Form.Label>Borrower Name <i className="fa fa-asterisk"></i></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="borrower_name"
                                                    name="borrower_name"
                                                    value={this.state.borrower_name}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                />
                                            </Form.Group> */}
                                            {this.state.opportunityInfoPending && this.state.amount_requested !== this.state.amount_new ? (
                                                <>
                                                    <div className="col-12">
                                                    </div>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingUpdateValue">Amount Requested - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <span class="pendingUpdateValue">
                                                            <CostNumberFormat
                                                                name={"amount_requested"}
                                                                id={"amount_requested"}
                                                                classname="form-control form-control-user"
                                                                value={this.state.amount_new}
                                                                pre="$"
                                                                updateField={(e) => this.handleNumber(e)}
                                                                disabled
                                                            />
                                                        </span>
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingPreviousValue">Amount Requested  - Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <CostNumberFormat
                                                            name={"amount_requested"}
                                                            id={"amount_requested"}
                                                            classname="form-control form-control-user"
                                                            value={this.state.amount_requested}
                                                            pre="$"
                                                            updateField={(e) => this.handleNumber(e)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </>
                                            ) : (
                                                <Form.Group className="form-group col-md-6">
                                                    <Form.Label>Amount Requested <i className="fa fa-asterisk"></i></Form.Label>
                                                    <CostNumberFormat
                                                        name={"amount_requested"}
                                                        id={"amount_requested"}
                                                        classname="form-control form-control-user"
                                                        value={this.state.amount_requested}
                                                        pre="$"
                                                        updateField={(e) => this.handleNumber(e)}
                                                    />
                                                </Form.Group>
                                            )}

                                            {this.state.opportunityInfoPending && this.state.loan_term !== this.state.loan_term_new ? (
                                                <>
                                                    <div className="col-12">
                                                    </div>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingUpdateValue">Loan Term - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="loan_term"
                                                            name="loan_term"
                                                            value={this.state.loan_term_new}
                                                            onChange={(e) => this.handleNumber(e)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingPreviousValue">Loan Term - Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="loan_term_prev"
                                                            name="loan_term_prev"
                                                            value={this.state.loan_term}
                                                            onChange={(e) => this.handleNumber(e)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </>
                                            ) : (
                                                <Form.Group className="form-group col-md-6">
                                                    <Form.Label>Loan Term <i className="fa fa-asterisk"></i></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control form-control-user"
                                                        id="loan_term"
                                                        name="loan_term"
                                                        value={this.state.loan_term}
                                                        onChange={(e) => this.handleNumber(e)}
                                                    />
                                                </Form.Group>
                                            )}

                                            {this.state.opportunityInfoPending && this.state.proposed_fee !== this.state.proposed_fee_new ? (
                                                <>
                                                    <div className="col-12">
                                                    </div>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingUpdateValue">Proposed Fee - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <CostNumberFormat
                                                            name={"proposed_fee"}
                                                            id={"proposed_fee"}
                                                            classname="form-control form-control-user"
                                                            value={this.state.proposed_fee_new}
                                                            pre="$"
                                                            updateField={(e) => this.handleNumber(e)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label><span class="pendingPreviousValue">Proposed Fee - Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <CostNumberFormat
                                                            name={"proposed_fee"}
                                                            id={"proposed_fee"}
                                                            classname="form-control form-control-user"
                                                            value={this.state.proposed_fee}
                                                            pre="$"
                                                            updateField={(e) => this.handleNumber(e)}
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                </>
                                            ) : (
                                                <Form.Group className="form-group col-md-6">
                                                    <Form.Label>Proposed Fee <i className="fa fa-asterisk"></i></Form.Label>
                                                    <CostNumberFormat
                                                        name={"proposed_fee"}
                                                        id={"proposed_fee"}
                                                        classname="form-control form-control-user"
                                                        value={this.state.proposed_fee}
                                                        pre="$"
                                                        updateField={(e) => this.handleNumber(e)}
                                                    />
                                                </Form.Group>
                                            )}
                                            <Form.Group className="col-md-6">
                                                <Form.Label>Financing Deadline <i className="fa fa-asterisk"></i></Form.Label>
                                                <br />
                                                <ReactDatePicker
                                                    name="closing_at"
                                                    id="closing_at"
                                                    value={this.state.closing_at || ""}
                                                    handleDateChange={(e) => this.handleDateChange(e)}
                                                    displayText={"Type or select the date when opportunity expires"}
                                                    maxDate={moment("9999-12-31").toDate()}
                                                    minDate={new Date()}
                                                />
                                            </Form.Group>

                                            {this.state.opportunityInfoPending && this.state.use_of_funds !== this.state.use_of_funds_new ? (
                                                <>
                                                    <div className="col-12">
                                                    </div>
                                                    <Form.Group className="form-group col-12">
                                                        <Form.Label><span class="pendingUpdateValue">Proposed Use of Funds Description - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="form-control textbox"
                                                            placeholder="Write a description about the use of funds"
                                                            id="use_of_funds"
                                                            name="use_of_funds"
                                                            maxLength="1000"
                                                            value={this.state.use_of_funds_new}
                                                            onChange={(e) => this.updateField(e)}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-12">
                                                        <Form.Label><span class="pendingPreviousValue">Proposed Use of Funds Description- Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                        <Form.Control
                                                            as="textarea"
                                                            className="form-control textbox"
                                                            placeholder="Write a description about the use of funds"
                                                            id="use_of_funds"
                                                            name="use_of_funds"
                                                            maxLength="1000"
                                                            value={this.state.use_of_funds}
                                                            onChange={(e) => this.updateField(e)}
                                                        ></Form.Control>
                                                    </Form.Group>
                                                </>
                                            ) : (
                                                <Form.Group className="form-group col-12">
                                                    <Form.Label>Proposed Use of Funds Description <i className="fa fa-asterisk"></i></Form.Label>
                                                    <Form.Control
                                                        as="textarea"
                                                        className="form-control textbox"
                                                        placeholder="Write a description about the use of funds"
                                                        id="use_of_funds"
                                                        name="use_of_funds"
                                                        maxLength="1000"
                                                        value={this.state.use_of_funds}
                                                        onChange={(e) => this.updateField(e)}
                                                    ></Form.Control>
                                                </Form.Group>
                                            )}
                                            <Form.Group className="form-group col-12">
                                                <Form.Label>Proposed Repayment Description</Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    className="form-control textbox"
                                                    placeholder="Write a description about the repayment"
                                                    id="summary"
                                                    name="summary"
                                                    maxLength="1000"
                                                    value={this.state.summary}
                                                    onChange={(e) => this.updateField(e)}
                                                ></Form.Control>
                                            </Form.Group>

                                            <div className="col-12">
                                                <hr />
                                            </div>

                                            <div className="col-12">
                                                <h2 className="title5">Additional Sponsors</h2>
                                            </div>

                                            {this.state.opportunityInfoPending && !this.compareSponsorArrays(this.state.sponsors, this.state.sponsors_new) ? (
                                                <>
                                                    {this.state.sponsors_new.length === 0 ? (
                                                        <>
                                                            <div className="col-12">
                                                                <h6><span class="pendingUpdateValue">Sponsors - New Values</span></h6>
                                                                <br />
                                                                <h5>No Sponsors</h5>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12">
                                                                <h6><span class="pendingUpdateValue">Sponsors - New Values</span></h6>
                                                                <br />
                                                            </div>

                                                            <div className="col-12 add-new-form">
                                                                {this.state.sponsors_new.map((data, i) => (
                                                                    <div key={"Sponsor" + i}>
                                                                        <AdditionalSponsors
                                                                            data={data}
                                                                            index={i}
                                                                            removeClick={this.removeClick.bind(this, i)}
                                                                            handleChangeDA={this.handleChangeSponsor.bind(this, i)}
                                                                            handleNumberDA={this.handleNumberSponsor.bind(this, i)}
                                                                            admin_status={this.state.admin_status}
                                                                            publish_status={this.state.publish_status}
                                                                        />
                                                                        <div className="col-12">
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                    {this.state.sponsors.length === 0 ? (
                                                        <>
                                                            <div className="col-12 mt-5">
                                                                <h6><span class="pendingPreviousValue">Sponsors - Original Values</span></h6>
                                                                <br />
                                                                <h5>No Sponsors</h5>
                                                            </div>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <div className="col-12 mt-5">
                                                                <h6><span class="pendingPreviousValue">Sponsors - Original Values</span></h6>
                                                                <br />
                                                            </div>

                                                            <div className="col-12 add-new-form">
                                                                {this.state.sponsors.map((data, i) => (
                                                                    <div key={"Sponsor" + i}>
                                                                        <AdditionalSponsors
                                                                            data={data}
                                                                            index={i}
                                                                            removeClick={this.removeClick.bind(this, i)}
                                                                            handleChangeDA={this.handleChangeSponsor.bind(this, i)}
                                                                            handleNumberDA={this.handleNumberSponsor.bind(this, i)}
                                                                            admin_status={this.state.admin_status}
                                                                            publish_status={this.state.publish_status}
                                                                        />
                                                                        <div className="col-12">
                                                                            <hr />
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            ) : (
                                                <>
                                                    {(this.state.sponsors.length === 0 && this.state.publish_status !== "closed") ?
                                                        <div className="col-12">
                                                            <AddButton btnLabel="Add Sponsors"
                                                                classname={"paddingSetting"}
                                                                addMoreHandler={this.addSponsorClick.bind(this)}
                                                                htmlElem={
                                                                    <p className="buttonBelowText mt-4">Add Additional Sponsors for your Opportunity</p>
                                                                }
                                                                disabled={this.state.opportunityInfoPending}
                                                            />
                                                        </div>
                                                        : ''}
                                                    <div className="col-12 mt-4 add-new-form">
                                                        {this.state.sponsors.map((data, i) => (
                                                            <>
                                                                <div key={"Sponsor" + i}>
                                                                    <AdditionalSponsors
                                                                        data={data}
                                                                        index={i}
                                                                        removeClick={this.removeClick.bind(this, i)}
                                                                        handleChangeDA={this.handleChangeSponsor.bind(this, i)}
                                                                        handleNumberDA={this.handleNumberSponsor.bind(this, i)}
                                                                        admin_status={this.state.admin_status}
                                                                        publish_status={this.state.publish_status}
                                                                    />
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                </div>
                                                            </>
                                                        ))}
                                                        {(this.state.sponsors.length > 0 && this.state.publish_status !== "closed" && (
                                                            <AddButtonSmall btnLabel="+ Add More Sponsors" addMoreHandler={this.addSponsorClick.bind(this)} />
                                                        ))}
                                                    </div>
                                                </>
                                            )}

                                            {(this.state.publish_status !== "closed" && !this.state.opportunityInfoPending) && (
                                                <div className="col-12 text-right mt-2">
                                                    <button
                                                        type="button"
                                                        disabled={this.state.disabledSubmit}
                                                        className="solidbtn"
                                                        onClick={(e) => this.handleSave(e)}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    }
                                />
                                <CreateGAPCard
                                    title="Borrower Profile"
                                    createComponent={
                                        <>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="rec-child"><h2 className="title5">Borrower Information</h2></div>
                                                    {this.state.borrowerProfilePending && (
                                                        <>
                                                            <div className="rec-child float-right">
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        this.approveBorrowerPendingClick()
                                                                    }
                                                                    className="btn pending-approvebtn samewidth mr-3"
                                                                >
                                                                    Approve Changes
                                                                </button>
                                                                <button
                                                                    type="button"
                                                                    onClick={() =>
                                                                        this.rejectBorrowerPendingClick()
                                                                    }
                                                                    className="btn pending-rejectbtn samewidth mr-3"
                                                                >
                                                                    Reject Changes
                                                                </button>
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                                {this.state.borrowerProfilePending && this.state.borrowerName !== this.state.borrower_name_new ? (
                                                    <>
                                                        <div className="col-12">
                                                        </div>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span class="pendingUpdateValue">Name - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control form-control-user"
                                                                id="borrowerName"
                                                                name="borrowerName"
                                                                value={this.state.borrower_name_new}
                                                                onChange={(e) => this.handleChange(e)}
                                                                maxLength="25"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span class="pendingPreviousValue">Name - Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control form-control-user"
                                                                id="borrowerName"
                                                                name="borrowerName"
                                                                value={this.state.borrowerName}
                                                                onChange={(e) => this.handleChange(e)}
                                                                maxLength="25"
                                                                disabled
                                                            />
                                                        </Form.Group>
                                                    </>
                                                ) : (
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Name <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="borrowerName"
                                                            name="borrowerName"
                                                            value={this.state.borrowerName}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            disabled={this.state.role === "borrower"}
                                                        />
                                                    </Form.Group>
                                                )}

                                                {this.state.borrowerProfilePending && this.state.borrower_type !== this.state.borrower_type_new ? (
                                                    <>
                                                        <div className="col-12">
                                                        </div>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingUpdateValue">Individual / Corporate - New Value<i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <select
                                                                className="selectdropdown"
                                                                name="borrower_type"
                                                                id="borrower_type"
                                                                value={this.state.borrower_type_new}
                                                                onChange={this.handleType}
                                                                disabled
                                                            >
                                                                <option value="" >Select One</option>
                                                                <option value="individual" >Individual</option>
                                                                <option value="corporation" >Corporate</option>
                                                            </select>
                                                        </Form.Group>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingPreviousValue">Individual / Corporate - Original Value<i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <select
                                                                className="selectdropdown"
                                                                name="borrower_type"
                                                                id="borrower_type"
                                                                value={this.state.borrower_type}
                                                                onChange={this.handleType}
                                                                disabled
                                                            >
                                                                <option value="" >Select One</option>
                                                                <option value="individual" >Individual</option>
                                                                <option value="corporation" >Corporate</option>
                                                            </select>
                                                        </Form.Group>
                                                    </>
                                                ) : (
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Individual / Corporate <i className="fa fa-asterisk"></i></Form.Label>
                                                        <select
                                                            className="selectdropdown"
                                                            name="borrower_type"
                                                            id="borrower_type"
                                                            value={this.state.borrower_type}
                                                            onChange={this.handleType}
                                                        >
                                                            <option value="" >Select One</option>
                                                            <option value="individual" >Individual</option>
                                                            <option value="corporation" >Corporate</option>
                                                        </select>
                                                    </Form.Group>
                                                )}

                                                {this.state.borrowerProfilePending && this.state.email !== this.state.borrower_email_new ? (
                                                    <>
                                                        <div className="col-12">
                                                        </div>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingUpdateValue">Email - New Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control form-control-user"
                                                                id="email"
                                                                name="email"
                                                                value={this.state.borrower_email_new}
                                                                onChange={(e) => this.handleChange(e)}
                                                            />
                                                        </Form.Group>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingPreviousValue">Email - Original Value <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <Form.Control
                                                                type="text"
                                                                className="form-control form-control-user"
                                                                id="email"
                                                                name="email"
                                                                value={this.state.email}
                                                                onChange={(e) => this.handleChange(e)}
                                                            />
                                                        </Form.Group>
                                                    </>
                                                ) : (
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Email <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={(e) => this.handleChange(e)}
                                                        />
                                                    </Form.Group>
                                                )}
                                                {this.state.borrowerProfilePending && this.state.phone !== this.state.borrower_phone_new ? (
                                                    <>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingUpdateValue">Phone Number <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <NumberFormat
                                                                format="###-###-####"
                                                                mask="_"
                                                                className="form-control form-control-user"
                                                                value={this.state.borrower_phone_new}
                                                                name="phone"
                                                                id="phone"
                                                                onChange={(e) => this.handleChange(e)} />
                                                        </Form.Group>
                                                        <Form.Group className="form-group col-md-6">
                                                            <Form.Label><span className="pendingPreviousValue">Phone Number <i className="fa fa-asterisk"></i></span></Form.Label>
                                                            <NumberFormat
                                                                format="###-###-####"
                                                                mask="_"
                                                                className="form-control form-control-user"
                                                                value={this.state.phone}
                                                                name="phone"
                                                                id="phone"
                                                                onChange={(e) => this.handleChange(e)} />
                                                        </Form.Group>
                                                    </>
                                                ) : (
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Phone Number <i className="fa fa-asterisk"></i></Form.Label>
                                                        <NumberFormat
                                                            format="###-###-####"
                                                            mask="_"
                                                            className="form-control form-control-user"
                                                            value={this.state.phone}
                                                            name="phone"
                                                            id="phone"
                                                            onChange={(e) => this.handleChange(e)} />
                                                    </Form.Group>
                                                )}
                                                {(this.state.publish_status !== "closed" && !this.state.borrowerProfilePending) && (
                                                    <div className="col-12 text-right mt-2">
                                                        <button
                                                            type="button"
                                                            disabled={this.state.disabledSubmit}
                                                            className="solidbtn"
                                                            onClick={(e) => this.handleSaveBorrowerProfile(e)}
                                                        >
                                                            Save Changes
                                                        </button>
                                                    </div>
                                                )}
                                                <br /> <br /> <br />
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <div className="rec-child"><h2 className="title5">Borrower Documents</h2></div>
                                                </div>
                                                <br /> <br /> <br />
                                                <div className="borrowerProfileDocumentCard">

                                                    {this.state.attachments.length === 0 ? (
                                                        <div className="row">
                                                            <div className="col-12 text-center">
                                                                <h5>No Documents Uploaded</h5>
                                                            </div>
                                                        </div>
                                                    ) : (
                                                        <>
                                                            {this.state.borrower_type === "corporation" && (
                                                                <>
                                                                    {sectionIds.has("org_chart") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-heading">
                                                                                    <p className="p-3">Organization Chart</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"org_chart"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Organization Chart Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                    {sectionIds.has("incorporation_docs") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-heading">
                                                                                    <p className="p-3">Incorporation Documents</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"incorporation_docs"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Incorporation Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}


                                                            {sectionIds.has("t1") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">T1 Form</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"t1"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No T1 Form Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("assessment_notice") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Notice of Assessment</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"assessment_notice"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Notice of Assessment Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}

                                                            {(sectionIds.has("cash.statement") || sectionIds.has("securities.statement")) && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                    {this.state.borrower_type === "individual" ? (
                                                                        <>
                                                                            <div className="col-12 mt-4">
                                                                                <h3 className="title5 mb-0">Personal Net Worth</h3>
                                                                            </div>
                                                                            <br /><br />
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <div className="col-12 mt-4">
                                                                                <h3 className="title5 mb-0">Year End Financial Statements</h3>
                                                                            </div>
                                                                            <br /><br />
                                                                        </>
                                                                    )
                                                                    }
                                                                </>
                                                            )}

                                                            {sectionIds.has("cash.statement") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Cash Balances</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"cash.statement"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Cash Balance Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("securities.statement") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Stocks & Securities Accounts</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"securities.statement"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Stocks & Securities Accounts Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}
                                                            {this.hasRealEstateSection(this.state.borrower_type, sectionIds) && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Completed Real Estate</h3>
                                                                    </div>
                                                                    <br /><br />
                                                                </>
                                                            )}
                                                            {sectionIds.has("real_estate.land_title") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Land Title</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.land_title"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Land Title Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("real_estate.value") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Market Value Appraisal / Self Declared Value</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.value"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Market Value Appraisal / Self Declared Value Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("real_estate.mortgage_docs") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Mortgage Documents</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.mortgage_docs"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Mortgage Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("real_estate.leasing_agreement") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Leasing Agreement</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.leasing_agreement"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Leasing Agreement Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}


                                                            {sectionIds.has("real_estate.tax_assessment") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Property Tax Assessment</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.tax_assessment"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Property Tax Assessment Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}

                                                            {this.state.borrower_type === "individual" && (
                                                                <>
                                                                    {sectionIds.has("real_estate.insurance") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-heading">
                                                                                    <p className="p-3">Property Insurance Documents</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"real_estate.insurance"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Property Insurance Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                    {sectionIds.has("real_estate.strata") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <div className="form-heading">
                                                                                    <p className="p-3">Strata Status Certificate</p>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"real_estate.strata"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Strata Status Certificate Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}

                                                                    {sectionIds.has("life_insurance") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <hr />
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <h3 className="title5 mb-0">Life Insurance Policy</h3>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"life_insurance"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Life Insurance Policy Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}

                                                                    {sectionIds.has("automobile_loans") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <hr />
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <h3 className="title5 mb-0">Automobile Loan / Lease Documents</h3>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"automobile_loans"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Automobile Loan / Lease Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}

                                                            {this.state.borrower_type === "corporation" && (
                                                                <>
                                                                    {sectionIds.has("current_projects") ? (
                                                                        <>
                                                                            <div className="col-12">
                                                                                <hr />
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <h3 className="title5 mb-0">Current Projects</h3>
                                                                            </div>
                                                                            <div className="col-12 mt-4">
                                                                                <MyBorrowerDocumentTableComponent
                                                                                    apiData={this.state.attachments}
                                                                                    section={"current_projects"}
                                                                                    multiple={false}
                                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                    deleteAttachment={null}
                                                                                    disableEdit
                                                                                />
                                                                            </div>
                                                                        </>
                                                                    ) : (
                                                                        <div className="col-12 text-center">
                                                                            <h5>No Current Project Documents Uploaded</h5>
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </>
                                                            )}


                                                            {sectionIds.has("other") ? (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>

                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Other Documents</h3>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"other"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            ) : (
                                                                <div className="col-12 text-center">
                                                                    <h5>No Other Documents Uploaded</h5>
                                                                    <br />
                                                                </div>
                                                            )}
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }

                                />
                                <CreateGAPCard
                                    title="Access Control"
                                    createComponent={
                                        <>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Visibility <i className="fa fa-asterisk"></i></Form.Label>
                                                <select
                                                    className="selectdropdown"
                                                    name="visibility"
                                                    id="visibility"
                                                    value={this.state.visibility}
                                                    onChange={this.handleVisibility}
                                                >
                                                    <option value="" >Select One</option>
                                                    <option value="public" >Public</option>
                                                    <option value="private" >Private - Invitations Only</option>
                                                </select>
                                            </Form.Group>


                                            <div className="row">
                                                <div className="col-12 mt-4">
                                                    <h2 className="title5"> Invite a Lender </h2>
                                                    {(this.state.publish_status === "draft" || this.state.admin_status === "pending") && (
                                                        <p>* Invitations will be sent once opportunity is approved. You can start adding Lenders in the meantime.</p>
                                                    )}
                                                </div>
                                            </div>
                                            {this.state.publish_status !== "draft" ? (
                                                <Form.Group className="form-group col-md-6">
                                                    <select
                                                        name="selectedLender"
                                                        id="selectedLender"
                                                        value={this.state.selectedLender || ""}
                                                        onChange={(e) => this.handleInviteLender(e)}
                                                        className="selectdropdown"
                                                        required
                                                    >
                                                        <option>Select One</option>
                                                        {this.state.lenderList.map((data) => (
                                                            <>
                                                                <option value={data.external_id}>{data.profile.name} | {data.profile.company_name === "" ? "No Company" : data.profile.company_name} | {data.email}</option>
                                                            </>
                                                        ))}
                                                    </select>
                                                </Form.Group>
                                            ) : (
                                                <div className="col-12 text-center">
                                                    <h5>Opportunity must be published first</h5>
                                                </div>
                                            )}

                                            {/* <!-- Tab panes -->
                                                    <div className="tab-content">
                                                        <div className="tab-pane active" id="tabs-1" role="tabpanel">
                                                            {(this.state.invites.length === 0) ?
                                                                <div className="col-12">
                                                                    <AddButton btnLabel="Invite Lenders" classname={"paddingSetting"} addMoreHandler={this.addClick.bind(this)} htmlElem={
                                                                        <p className="buttonBelowText mt-4">Lenders will be sent an email invite to view all of the information for this opportunity</p>
                                                                    } />
                                                                </div>
                                                                : ''}
                                                            <div className="col-12 mt-4 add-new-form">
                                                                {this.state.invites.map((data, i) => (
                                                                    <>
                                                                        <div key={"Exclusive" + i}>
                                                                            <GAPLenderInviteList
                                                                                data={data}
                                                                                index={i}
                                                                                removeClick={this.removeClick.bind(this, i)}
                                                                                handleChangeDA={this.handleChangeDA.bind(this, i)}
                                                                                lenderList={this.state.lenderList}
                                                                            />
                                                                            <div className="col-12">
                                                                                <hr />
                                                                            </div>
                                                                        </div>
                                                                    </>
                                                                ))}
                                                                {(this.state.invites.length > 0) ?
                                                                    <AddButtonSmall btnLabel="+ Add More Exclusive Members" addMoreHandler={this.addClick.bind(this)} />
                                                                    : ''
                                                                }
                                                            </div>
                                                            <div className="col-12 text-right mt-4">
                                                                <button type="button" className="solidbtn" onClick={this.addExclusiveMembers} disabled={this.state.invites.length === 0}>Add Members</button>
                                                            </div>
                                                        </div>
                                                    </div> */}


                                            <div className="row">
                                                <div className="col-12 mt-4">
                                                    <h2 className="title5"> Opportunity Members </h2>
                                                    {this.state.access.length > 0 ? (
                                                        <>
                                                            <GAPExclusiveMembers
                                                                accessData={this.state.access}
                                                                submitDeleteMember={this.submitDeleteMember}
                                                            />
                                                        </>
                                                    ) : (
                                                        <div className="col-12 text-center">
                                                            <h5>No Opportunity Members</h5>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </>
                                    }
                                />

                                {this.state.publish_status === "published" && this.state.admin_status === "approved" && (
                                    <div className="col-12 text-right mt-2 mb-4">
                                        <button
                                            type="button"
                                            disabled={this.state.disabledSubmit}
                                            className="solidbtn"
                                            onClick={(e) => this.closeOpportunityModal(e)}
                                        >
                                            Close Opportunity
                                        </button>
                                    </div>
                                )}
                            </form>
                        </div>
                    </div>
                </div>

                <ReusableModal
                    show={this.state.showApproveModal}
                    close={(e) => this.close(e)}
                    hideCloseButton={true}
                    id={"createConfirmation"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={"text-center popuptxt"}
                    body={
                        <div>
                            <h4>GAP Opportunity Profile is now Approved</h4>
                            <br />
                            <button type="button" className="solidbtnxs btn" onClick={this.close}>Close</button>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.showRejectModal}
                    close={(e) => this.close(e)}
                    hideCloseButton={true}
                    id={"createConfirmation"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={"text-center popuptxt"}
                    body={
                        <div>
                            <h4>GAP Opportunity Profile has been Rejected</h4>
                            <br />
                            <button type="button" className="solidbtnxs btn" onClick={this.close}>Close</button>
                        </div>
                    }
                />
                <ReusableModal
                    show={this.state.openConfirmation}
                    close={(e) => this.close(e)}
                    id={"confirmBroker"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Assign Opportunity to Broker?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.assignBroker}
                                    className="btn primarybtn samewidth"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openApproveConfirmation}
                    close={(e) => this.close(e)}
                    id={"confirmBroker"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Approve this Opportunity?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleApproveClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openRejectConfirmation}
                    close={(e) => this.close(e)}
                    id={"confirmBroker"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Reject this Opportunity?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleRejectClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openSummaryPendingApprove}
                    close={(e) => this.close(e)}
                    id={"confirmPendingApprove"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">Approve Opportunity Changes?</h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleSummaryApprovePendingClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openSummaryPendingReject}
                    close={(e) => this.close(e)}
                    id={"confirmPendingReject"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Reject Opportunity Changes?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleSummaryRejectPendingClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openBorrowerProfilePendingApprove}
                    close={(e) => this.close(e)}
                    id={"confirmPendingApprove"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Approve Borrower Profile Changes?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleBorrowerProfileApprovePendingClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Approve
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.openBorrowerProfilePendingReject}
                    close={(e) => this.close(e)}
                    id={"confirmPendingApprove"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h1 className="modal-title">
                                    Reject Borrower Profile Changes?
                                </h1>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.handleBorrowerProfileRejectPendingClick}
                                    className="btn primarybtn samewidth"
                                >
                                    Reject
                                </button>
                            </div>
                        </div>
                    }
                />


                <ReusableModal
                    show={this.state.openConfirmInvite}
                    close={(e) => this.close(e)}
                    id={"confirmInvite"}
                    size={"md"}
                    modalHeaderContent={
                        <div className="col text-center">
                            <h1 className="modal-title">
                                Invite Lender
                            </h1>
                        </div>
                    }
                    bodyClass={""}
                    body={
                        <div className="row">
                            <div className="col-12 text-center">
                                <h5>Invite Lender to Opportunity?</h5>
                            </div>
                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.sendInvitation}
                                    className="btn primarybtn samewidth"
                                >
                                    Send Invite
                                </button>
                            </div>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.closeOpportunityModal}
                    close={(e) => this.close(e)}
                    id={"closeOpportunity"}
                    size={"md"}
                    modalHeaderContent={
                        <div className="col text-center">
                            <h1 className="modal-title">
                                Close Opportunity
                            </h1>
                        </div>
                    }
                    bodyClass={""}
                    body={
                        <div className="row">


                            <Form.Group className="form-group col-8 offset-2">
                                <Form.Label>Select Reason for Closing</Form.Label>
                                <Form.Control
                                    as="select"
                                    className="selectdropdown"
                                    id="reason"
                                    name="reason"
                                    value={this.state.reason}
                                    onChange={(e) => this.handleChange(e)}
                                    required
                                >
                                    <option value="">Select Reason</option>
                                    <option value="funded_fundever">Funded on Fundever</option>
                                    <option value="cancelled">Client Cancellation</option>
                                </Form.Control>
                            </Form.Group>


                            <div className={"mt-5 mb-4 col-12 text-center"}>
                                <button
                                    type="button"
                                    onClick={this.close}
                                    className="btn borderbtn samewidth mr-5"
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    onClick={this.closeOpportunity}
                                    className="btn primarybtn samewidth"
                                >
                                    Confirm
                                </button>
                            </div>
                        </div>
                    }
                />

                <Footer />

            </div >
        );
    }
}
export default GAPOpportunitySummary;

