import React from 'react';
import { ReusableModal } from '../../../../Components/ReusableModal/ReusableModal';
import { FileUpload } from '../../../../Components/FileUpload/FileUpload';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { apiBaseUrl, mapKey } from '../../../../../configs';
import refreshToken from '../../../../../RefreshToken';
import NumberFormat from 'react-number-format';
import { AutoCompleteAddress } from '../../../../Components/AutoCompleteAddress/AutoCompleteAddress';
import { GoogleApiWrapper } from 'google-maps-react';
import { Loader } from '../../../../Components/Loader/Loader';
import { ProfileAvatar } from '../../../../Components/Avatar/ProfileAvatar';
import { validate } from '../../../../Utils/validation';
import { lenderProfileValidation } from './LenderProfileValidationSchema';
import { emptyLeadingSpaceSanitizer } from '../../../../Utils/sanitizeNumber';
import { logger } from '../../../../../logger';

const log = logger('BasicInfo');

class BasicInfo extends React.Component {

    constructor(props) {
        super(props)
        this.state = {

            profileName: "",
            profileEmail: '',
            company_name: '',
            position: '',
            phone_number: '',
            address: "",
            city: "",
            province: "",
            postalCode: "",
            country: "",
            lender_type: "",
            success: false,
            success2: false,
            error: false,
            showModal: false,
            fileNames: [{ url: '' }],
            fileNamesFromApi: [{ url: '' }],
            showError: "",
            img_url: [],
            networkError: false,
            showSaveConfirmation: false,
            profile_status: localStorage.getItem('profile_status'),
            disableSubmit: true,
            avoidReviewModal: true
        }

        this.handleChange = this.handleChange.bind(this);
        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.handleDrop = this.handleDrop.bind(this);
        this.removePreview = this.removePreview.bind(this);
    }

    componentDidMount() {
        const token = localStorage.getItem('token');
        axios.get(apiBaseUrl + '/my_profile', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState(state => ({
                    success2: true,
                    success: true,
                    company_name: response.data.account.profile.company_name,
                    position: response.data.account.profile.position,
                    profileName: response.data.account.profile.name,
                    profileEmail: response.data.account.profile.email,
                    phone_number: response.data.account.profile.phone,
                    address: response.data.account.profile.address.address,
                    city: response.data.account.profile.address.city,
                    province: response.data.account.profile.address.province,
                    postalCode: response.data.account.profile.address.post_code,
                    country: response.data.account.profile.address.country,
                    license: response.data.account.profile.licence_no,
                    completed: response.data.account.profile.completed,
                    lender_type: response.data.account.profile.lender_type,
                    profile_status: response.data.account.status,
                    img_url: [...this.state.img_url, response.data.account.image_url]
                }), () => {
                    let fileNames = this.state.fileNames;
                    this.state.img_url.map((data, i) => {
                        fileNames[i].url = data;
                        return this.setState({
                            fileNames, fileNamesFromApi: fileNames,
                        })
                    })
                });

            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }
    handleChange = event => {
        const name = event.target.name;
        log.info('namei', name);
        if (name === 'lender_type' || name === "phone_number" || name === "position" || name === "address") {
            this.setState({
                avoidReviewModal: false
            })
        }
        this.setState({
            [name]: emptyLeadingSpaceSanitizer(event.target.value)
        }, () => {
            this.setState({
                disableSubmit: false
            })
            if (this.state.address === "") {
                this.setState({
                    city: "",
                    province: "",
                    postalCode: "",
                    country: "",
                })
            }
        });

    }

    // google api
    handlePlaceChanged(place) {
        const address = place.formatted_address;
        const addressLevel1 = address.substr(0, address.lastIndexOf(","));
        const addressLevel2 = addressLevel1.substr(0, addressLevel1.lastIndexOf(","));
        const addressLevel3 = addressLevel2.substr(0, addressLevel2.lastIndexOf(","));
        this.setState(
            {
                // address: place.formatted_address,
                address: addressLevel3,
                place: place,
                validationError: false
            },
            () => {
                this.handleAddressData();
            }
        );
    }



    handleSubmit = (event, value) => {
        event.preventDefault()
        if (validate(lenderProfileValidation)) {
            const data = {
                "address": {
                    "address": this.state.address,
                    "city": this.state.city,
                    "country": this.state.country,
                    "post_code": this.state.postalCode,
                    "province": this.state.province
                },
                "company_name": this.state.company_name,
                "email": this.state.profileEmail,
                "lender_type": this.state.lender_type,
                "name": this.state.profileName,
                "phone": this.state.phone_number,
                "position": this.state.position,
            }
            const token = localStorage.getItem('token');
            axios.put(apiBaseUrl + '/my_profile', data, {
                headers: {
                    Authorization: token
                }
            })
                .then(response => {
                    if (this.state.profile_status === 'pending') {
                        this.props.openAccordian(value);
                    }
                    if (this.state.profile_status === 'approved') {
                        if (this.state.avoidReviewModal) {
                            this.setState({
                                showSaveConfirmation: true,
                                disableSubmit: true

                            });
                        } else {
                            this.setState({
                                avoidReviewModal: true,
                                disableSubmit: true
                            })
                        }

                    }

                })
                .catch(error => {
                    this.setState({
                        error: true
                    });
                    if (error.response !== undefined) {
                        if (error.response.data.code === 401) {
                            refreshToken();
                        }
                    }
                });
        }
    }



    imageSubmit = () => {
        const token = localStorage.getItem('token');

        if (this.state.fileNames.length !== 0) {
            this.setState({
                fileNamesFromApi: this.state.fileNames,
            })
            this.state.fileNames.map((fileName, i) => {
                const data = new FormData();
                data.append('file', fileName)
                axios.post(apiBaseUrl + '/my_profile/image', data, {
                    headers: {
                        Authorization: token,
                        "Content-Type": "multipart/form-data"
                    }
                })
                    .then(response => {
                        this.setState({
                            success: true,

                        })
                    })
                    .catch(error => {
                        this.setState({
                            error: true
                        });
                        if (error.response !== undefined) {
                            if (error.response.data.code === 401) {
                                refreshToken();
                            }
                        }
                    });
                return fileName;
            })
        }
    }


    close = () => {
        this.setState({ showModal: false });
    }

    open = () => {
        this.setState({ showModal: true });
    }
    handleDrop = (acceptedFiles) => {
        const imgObjectUrl = acceptedFiles;
        const url = imgObjectUrl.map((file) => {
            return URL.createObjectURL(file)
        })

        this.setState({
            fileNames: acceptedFiles,
            img_url: url
        }, () => {
            let fileNames = this.state.fileNames;
            imgObjectUrl.map((file, i) => {
                fileNames[i].url = URL.createObjectURL(file)
                this.setState({
                    fileNames
                }, () => {
                    this.imageSubmit();
                });
                return file;
            })
        })

    };
    removePreview = (e) => {
        let array = [...this.state.fileNames]; // make a separate copy of the array
        let index = e;
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ fileNames: array, showError: false });
        }
    };

    handleOnclick(value) {
        this.openSaveConfirmation();
    }

    closeSaveConfirmation = () => {
        this.setState({ showSaveConfirmation: false });
    }

    openSaveConfirmation = () => {
        this.setState({ showSaveConfirmation: true });
    }
    handleNext(value) {
        this.props.openAccordian(value);
    }

    handleAddressData = () => {
        (this.state.place.address_components || []).map((currentValue, index) => {
            currentValue.types.filter((types) => {
                if (types === "postal_code") {
                    if (this.state.address === "") {
                        this.setState({
                            postalCode: ""
                        })
                    } else {
                        this.setState({
                            postalCode: currentValue.long_name,
                        });
                    }

                }
                if (types === "country") {
                    if (this.state.address === "") {
                        this.setState({
                            country: "",
                        })
                    } else {
                        this.setState({
                            country: currentValue.long_name,
                        });
                    }
                }
                if (types === "administrative_area_level_1") {
                    if (this.state.address === "") {
                        this.setState({
                            province: "",
                            provinceAbbreviation: ""
                        })
                    } else {
                        this.setState({
                            province: currentValue.long_name,
                            provinceAbbreviation: currentValue.short_name,
                        });
                    }
                }
                if (types === "locality" || types === "administrative_area_level_3" || types === "sublocality") {
                    if (this.state.address === "") {
                        this.setState({
                            city: "",
                        })
                    } else {
                        this.setState({
                            city: currentValue.long_name,
                        });
                    }
                }
                return types;
            });
            return currentValue;
        });
    };

    handleConfirm = () => {
        this.imageSubmit();
        this.setState({ showModal: false });
    }

    removePreview = (e) => {
        let array = [...this.state.fileNames]; // make a separate copy of the array
        let index = e;
        if (index !== -1) {
            array.splice(index, 1);
            this.setState({ fileNames: array, showError: false });
        }
    };

    render() {
        return (
            <div >
                <div className="container pl-5 pr-5">
                    <div className="text-center">
                        {this.state.profile_status === 'pending' &&
                            <h1 className="subtitle">Complete Your Profile</h1>}
                        {this.state.profile_status === 'approved' &&
                            <h1 className="subtitle">My Profile</h1>}

                    </div>

                    {/* <!--form--> */}
                    <form className="user" id="Form" onSubmit={(event) => this.handleSubmit(event, { param: true })} encType="multipart/form-data">
                        <div className="col-12 text-center">
                            {/* <div className="profile-img">

                                {this.state.fileNames.map((fileName, i) => {
                                    return <>{fileName.url === '' ? <img src={require('../../../../../assets/img/profileimg.png')} alt="" /> :
                                        <img style={{ textIndent: "-9999px" }} src={fileName.url} alt={"" + i} key={i} />}</>
                                })}

                                <a href="#" className="editbtn" onClick={this.open}>Edit</a>
                            </div> */}
                            <ProfileAvatar
                                fileNames={this.state.fileNames}
                                fileNamesFromApi={this.state.fileNamesFromApi}
                                enableEdit={true}
                                handleDrop={this.handleDrop}
                                handleConfirm={this.handleConfirm}
                                handleCancel={this.handleCancel}
                                removePreview={this.removePreview}
                            />

                        </div>
                        {this.state.profile_status === 'approved' ? <>
                            <div className="form-group">
                                <label>Full Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    name="profileName"
                                    id="profileName"
                                    placeholder=""
                                    value={this.state.profileName}
                                    onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Phone Number</label>
                                <NumberFormat
                                    format="###-###-####"
                                    mask="_"
                                    className="form-control form-control-user"
                                    placeholder="Enter phone number"
                                    value={this.state.phone_number}
                                    name="phone_number"
                                    id="phone_number"
                                    onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Email</label>
                                <input
                                    // type="email"
                                    className="form-control form-control-user"
                                    name="profileEmail"
                                    id="profileEmail"
                                    placeholder="" value={this.state.profileEmail} onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Company Name</label>
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    name="company_name"
                                    id="company_name"
                                    placeholder=""
                                    value={this.state.company_name}
                                    onChange={this.handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Title</label>
                                <input
                                    type="text"
                                    className="form-control form-control-user"
                                    name="position"
                                    id="position"
                                    placeholder=""
                                    value={this.state.position}
                                    onChange={this.handleChange}
                                />
                            </div>

                            {this.role === 'lender' && (
                                <div className="form-group">
                                    <label>Lender Type</label>
                                    <select
                                        name="lender_type"
                                        id="lender_type"
                                        value={this.state.lender_type}
                                        onChange={this.handleChange}
                                        className="selectdropdown"
                                    >
                                        <option>Select Lender Type</option>
                                        <option value="bank">Bank</option>
                                        <option value="non-bank">Non-Bank</option>
                                        <option value="credit-union">Credit Union</option>
                                        <option value="mic">MIC</option>
                                    </select>
                                </div>
                            )}
                            <div className="form-group">
                                {/* <label>Business Address</label> */}
                                <AutoCompleteAddress
                                    addressName={"Business "}
                                    gridClass="form-group"
                                    state={this.state}
                                    updateField={(data) => this.handleChange(data)}
                                    handlePlaceChanged={(data) => this.handlePlaceChanged(data)}
                                />

                            </div>
                        </> :
                            <>
                                <div className="form-group">
                                    <label>Company Name</label>
                                    <input
                                        type="text"
                                        className="form-control form-control-user"
                                        name="company_name"
                                        id="company_name"
                                        placeholder=""
                                        value={this.state.company_name}
                                        onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Your position in the company</label>
                                    <input type="text" className="form-control form-control-user" name="position" placeholder="" value={this.state.position} onChange={this.handleChange} />
                                </div>
                                <div className="form-group">
                                    <label>Phone Number</label>
                                    <NumberFormat
                                        format="###-###-####"
                                        mask="_"
                                        className="form-control form-control-user"
                                        name="phone_number"
                                        placeholder="Enter phone number"
                                        value={this.state.phone_number}
                                        id="phone_number"
                                        onChange={this.handleChange}
                                    />

                                </div>
                                <div className="form-group">
                                    <AutoCompleteAddress
                                        addressName={"Business "}
                                        gridClass="form-group"
                                        state={this.state}
                                        updateField={(data) => this.handleChange(data)}
                                        handlePlaceChanged={(data) => this.handlePlaceChanged(data)}
                                    />
                                </div>
                                {this.state.role === "lender" && (
                                    <div className="form-group">
                                        <label>Lender Type</label>
                                        <select
                                            name="lender_type"
                                            id="lender_type"
                                            value={this.state.lender_type}
                                            onChange={this.handleChange}
                                            className="selectdropdown"
                                        >
                                            <option>Select Lender Type</option>
                                            <option value="bank">Bank</option>
                                            <option value="non-bank">Non-Bank</option>
                                            <option value="credit-union">Credit Union</option>
                                            <option value="mic">MIC</option>
                                        </select>
                                    </div>
                                )}
                                <div className="text-center">
                                    <span>1/2</span>
                                </div>
                            </>}



                        <button type="submit" disabled={this.state.disableSubmit} className="btn btn-primary btn-user btn-block mt-5 mb-3" >Save Changes</button>
                        <Link to="/password-reset" className="skip-button mb-5 nounderline">Change Password</Link>

                    </form>
                    {/* <!--end form--> */}


                </div>

                {/* <!-- edit-profile--> */}
                <ReusableModal
                    show={this.state.showModal}
                    close={(e) => this.close(e)}
                    id={"edit-profile"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={""}
                    body={
                        <React.Fragment>
                            <div className="uploadOuter">
                                <FileUpload
                                    handleDrop={(e) => this.handleDrop(e)}
                                    fileType={"image/*"}
                                    maxFileUpload={1}
                                    multipleFiles={false}
                                    html_view={
                                        <React.Fragment>
                                            <div>
                                                <i className="fa fa-cloud-upload dragimgicon" aria-hidden="true"></i>
                                            </div>
                                            drag and drop IMAGE here Or
                                            <div>
                                                <button type="button" className="btn btn-link">Browse File</button>
                                            </div>
                                        </React.Fragment>
                                    }
                                />
                                <React.Fragment>
                                    <ul className="preview-sec thumbsContainer">
                                        {this.state.fileNames.map((fileName, i) => {
                                            return <>{
                                                fileName.url !== '' &&
                                                <li className="previewList" key={fileName.name + i}>
                                                    <h5>{fileName.name}</h5>
                                                    <div className="thumb">
                                                        <div className="thumbInner">
                                                            <img width="100" height="100" src={fileName.url} alt={fileName.name + i} />
                                                        </div>
                                                    </div>
                                                    <button type="button" className="btn btn-danger" onClick={() => this.removePreview(i)}>X</button>
                                                </li>

                                            }
                                            </>
                                        }
                                        )}
                                    </ul>

                                </React.Fragment>
                            </div>
                        </React.Fragment>
                    }
                />


                <ReusableModal
                    show={this.state.showSaveConfirmation}
                    close={this.closeSaveConfirmation}
                    id={"saveConfirmation"}
                    bodyClass={"text-center popuptxt"}
                    size={"md"}
                    modalHeaderContent={""}
                    body={
                        <div>
                            <div className="col-12 text-center mb-3">
                                <h1 className="modal-title1 mb-3">Your new saved profile info has <br /> been sent for review</h1>
                                <p className="colorblack m-0 extraWidth" >It will be processed within 1 business day.<br /> In the mean-time, your previosly approved information<br />will remain <br /></p>

                            </div>
                            <div className="col-12 text-center mt-4 mb-5">
                                <button type="button" className="btn primarybtn samewidth" onClick={() => this.closeSaveConfirmation(this)}>Yes, Continue</button>
                            </div>
                        </div>
                    }
                />
            </div >
        );
    }
}
export default withRouter(GoogleApiWrapper({
    apiKey: (mapKey),
    LoadingContainer: Loader
})(BasicInfo))

