import axios from "axios";
import { apiBaseUrl } from "./configs";
import history from './history';
export const refreshToken = () => {
    const refreshToken = localStorage.getItem('refreshToken');

    const token = localStorage.getItem('token');

    axios.get(apiBaseUrl + '/authenticate/refresh/' + refreshToken, {
        headers: {
            Authorization: token
        }
    })
        .then(response => {
            localStorage.setItem('token', "bearer " + response.data.access_token);
            window.location.reload();
        })
        .catch(error => {
            localStorage.removeItem('token');
            localStorage.clear();
            history.push("/login");
            history.go(0)
        });

}
export default refreshToken;