import * as actionCreators from '../actionTypes';

const initialState = {
  list:{},
  isLoading: false,
  summaryData: {}
};

const opportunityListReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case actionCreators.OPPORTUNITY_LIST_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.OPPORTUNITY_LIST_SUCCESS: {
      return {
        ...state,
        list: action.payload,
        isLoading: false
      }
    }
    case actionCreators.OPPORTUNITY_LIST_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    // summary reducers
    case actionCreators.SUMMARY_DATA_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.SUMMARY_DATA_SUCCESS: {
      return {
        ...state,
        summaryData: action.payload,
        isLoading: false
      }
    }
    case actionCreators.SUMMARY_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return { ...state };
  }
};
export default opportunityListReducer;