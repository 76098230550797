export const createOppvalidation = {
    address: [
        { rule: "required", message: "Address is required" }
    ],
    city: [
        { rule: "required", message: "City is required" }
    ],
    province: [
        { rule: "required", message: "State/Province is required" }
    ],
    country: [
        { rule: "required", message: "Country is required" }
    ],
    postalCode: [
        { rule: "required", message: "Postal code is required" }
    ],
    amountRequested: [
        { rule: "required", message: "Amount requested is required" }
    ],
    closingDate: [
        { rule: "required", message: "Closing date is required" }
    ],
    typeOfFinancing: [
        { rule: "required", message: "Type of financing is required" }
    ],
    propertyType: [
        { rule: "required", message: "Property Type is required" }
    ],
    visibility: [
        { rule: "required", message: "Visibility is required" }
    ],
    opportunityName: [
        { rule: "required", message: "Opportunity name is required" }
    ]
}

export const createGapValidation = {
    opportunityName: [
        { rule: "required", message: "Opportunity Name is required" }
    ],
    borrower_name: [
        { rule: "required", message: "Borrower Name is required" }
    ],
    amount_requested: [
        { rule: "required", message: "Amount Requested is required" }
    ],
    loan_term: [
        { rule: "required", message: "Term is required" }
    ],
    proposed_fee: [
        { rule: "required", message: "Proposed Fee is required" }
    ],
    closingDate: [
        { rule: "required", message: "Financing Deadline is required" }
    ],
    use_of_funds: [
        { rule: "required", message: "Use of Funds is required" }
    ],
}