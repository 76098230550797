import React from "react";
import NumberFormat from "react-number-format";

export const CostNumberFormat = (props) => {
  const { format, name, id, value, onBlur, updateField, placeholder, classname, pre, su, disabled, required, decimalScale, decimalSeparator } = props

  return (
    <NumberFormat
      format={format}
      thousandSeparator={true}
      prefix={pre}
      suffix={su}
      allowNegative={false}
      allowEmptyFormatting={false}
      allowLeadingZeros={false}
      value={value}
      onChange={updateField}
      onBlur={onBlur}
      className={classname}
      placeholder={placeholder}
      id={id}
      name={name}
      disabled={disabled}
      required={required}
      decimalScale={decimalScale || false}
      decimalSeparator={decimalSeparator || false}
      isAllowed={(value) => {
        const { formattedValue, floatValue } = value;
        return formattedValue === '' || (floatValue > 0 && floatValue <= 99999999999);
      }}
    />
  );
}