export const lenderProfileValidation = {
    profileName: [
        { rule: "required", message: "Full name is required" }
    ],
    phone_number: [
        { rule: "required", message: "Phone number is required" }
    ],
    profileEmail: [
        { rule: "required", message: "Email is required" },
        { rule: "email", message: "Email is invalid" },
    ],
    company_name: [
        { rule: "required", message: "Company name is required" }
    ],
    position: [
        { rule: "required", message: "Title is required" },
    ],
    address: [
        { rule: "required", message: "Address is required" }
    ],
    city: [
        { rule: "required", message: "City is required" }
    ],
    province: [
        { rule: "required", message: "State/Province is required" }
    ],
    country: [
        { rule: "required", message: "Country is required" }
    ],
    postalCode: [
        { rule: "required", message: "Postal code is required" }
    ]
}