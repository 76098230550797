import Axios from "axios";
import { apiBaseUrl } from "../../configs";
import refreshToken from "../../RefreshToken";

export const fileAttachmentUrl = (external_id, name) => {
    const token = localStorage.getItem('token');
    Axios.get(apiBaseUrl + '/mp/attachment_url/' + external_id, {
        headers: {
            Authorization: token
        }
    })
        .then(response => {
            window.open(response.data.attachment_url);

            // const ext = name.length > 0 ? name.split(".").pop() : "";
            // const url = response.data.attachment_url;
            // if (ext === "jpg" || ext === "png" || ext === "jpeg" || ext === "pdf") {

            //     const url = window.URL.createObjectURL(new Blob([response.data.attachment_url]));

            //     const link = document.createElement('a');
            //     link.href = url;
            //     link.setAttribute('download', name);
            //     document.body.appendChild(link);
            //     link.click();
            // } else {
            //     window.open(response.data.attachment_url);
            // }

        })
        .catch(error => {
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        });

}