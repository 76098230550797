
const config = {

    apiBaseUrl: 'https://fundever.com/api', //Api base url
    //siteKey: '6LfgY9kZAAAAAEcX7oad5frTCuJ-f8zyZcdF_Icv', //local site key
    siteKey: '6LdrDMEUAAAAANM7Rj59znBWm9A4WxGUi5EhRE0e',
    mapKey: 'AIzaSyBHlLlRjhvHln8rVUitDNb2Hd_KPObID-U',

};

module.exports = config;
