import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import LPHeader from '../../../Website/LPHeader/LPHeader';
import LPFooter from '../../../Website/Footer/Footer';
import { apiBaseUrl } from '../../../../configs'
import axios from 'axios';
import Swal from 'sweetalert2';
import "../SignUp/SignUpForm/signUp.css";
import { PasswordMeter } from '../../../Components/PasswordMeter/PasswordMeter';



export default class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = {

            password: '',
            repeat_password: '',
            success: false,
            error: false,
            emailError: '',
            passwordError: '',
            hidden: true,
            hidden_R: true,
            passwordRequirement: false,
            showProgressBar: false,
            otpError: false,
            passwordWeak: false
        }
        this.toggleShowPassword = this.toggleShowPassword.bind(this);
        this.toggleShowRepeatPassword = this.toggleShowRepeatPassword.bind(this);
    }

    handlePasswordChange = event => {
        this.setState({
            password: event.target.value
        }, () => {
            if (this.state.password !== "") {
                this.setState({
                    showProgressBar: true
                })
            } else {
                this.setState({
                    showProgressBar: false
                })
            }
            if (this.state.password.length < 1) {
                this.setState({
                    passwordError: "Password is required"
                })
                return false
                // } else if (this.state.password.length < 8) {
                //     this.setState({
                //         passwordError: "Minimum 8 characters"
                //     })
                //     return false
                // } else if (this.state.password.search(/[A-Z]/) < 0) {
                //     this.setState({
                //         passwordError: 'Must contain one uppercase character'
                //     })
                //     return false
                // } else if (this.state.password.search(/[0-9]/) < 0) {
                //     this.setState({
                //         passwordError: 'Must contain atleast one number'
                //     })
                //     return false
            } else {
                this.setState({
                    passwordError: ""
                });
            }
        })
    }
    handleRepeatPasswordChange = event => {
        this.setState({
            repeat_password: event.target.value
        }, () => {
            if (this.state.repeat_password.length < 1) {
                this.setState({
                    repeatPasswordError: "Repeat password is required"
                });
                return false
            } else {
                if (this.state.password !== this.state.repeat_password) {
                    this.setState({
                        repeatPasswordError: "Repeat password does not match"
                    });
                    return false
                } else {
                    this.setState({
                        repeatPasswordError: ""
                    });
                }
            }
        })
    }

    toggleShowPassword() {
        this.setState({ hidden: !this.state.hidden });
    }

    toggleShowRepeatPassword() {
        this.setState({ hidden_R: !this.state.hidden_R });
    }

    valid() {
        if (this.state.password.length < 1) {
            this.setState({
                passwordError: "Password is required"
            })
            return false
            // } else if (this.state.password.length < 8) {
            //     this.setState({
            //         passwordError: "Minimum 8 characters"
            //     })
            //     return false
            // } else if (this.state.password.search(/[A-Z]/) < 0) {
            //     this.setState({
            //         passwordError: 'Must contain one uppercase character'
            //     })
            //     return false
            // } else if (this.state.password.search(/[0-9]/) < 0) {
            //     this.setState({
            //         passwordError: 'Must contain atleast one number'
            //     })
            //     return false
        } else {
            this.setState({
                passwordError: ""
            });
        }

        if (this.state.repeat_password.length < 1) {
            this.setState({
                repeatPasswordError: "Repeat password is required"
            });
            return false
        } else {
            if (this.state.password !== this.state.repeat_password) {
                this.setState({
                    repeatPasswordError: "Repeat password does not match"
                });
                return false
            } else {
                this.setState({
                    repeatPasswordError: ""
                });
            }
        }

        if (this.state.password.length > 1 && this.state.repeat_password.length > 1 && (this.state.password === this.state.repeat_password)) {
            this.setState({
                passwordError: "",
                repeatPasswordError: ''
            });
            return true;
        }


    }



    handleSubmit = (event) => {
        event.preventDefault()
        if (this.valid()) {
            const data = {
                "password_new": this.state.password,
                "password_new_re": this.state.repeat_password
            }
            const resetToken = localStorage.getItem('resetToken');

            axios.put(apiBaseUrl + '/account/reset/' + resetToken, data)
                .then(response => {
                    this.setState({
                        success: true
                    })

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Password Changed',
                        showConfirmButton: false,
                        timer: 2000
                    });
                    localStorage.clear();
                })
                .catch(error => {
                    if (error.response.data.code === 404) {
                        this.setState({
                            otpError: true,
                            passwordWeak: false
                        })
                    } else if ((error.response.data.message === "PasswordPolicyCheck - score is too low. Got: 0, required: 2") || (error.response.data.message === "PasswordPolicyCheck - score is too low. Got: 1, required: 2")) {
                        this.setState({
                            passwordWeak: true,
                            otpError: false,

                        })
                    }
                    else {
                        this.setState({
                            error: true
                        });
                    }
                }

                );
        }

    }



    render() {


        return (
            <div >
                <LPHeader />
                <div className="container min-page-height">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className="p-5">
                                                {
                                                    this.state.error ? <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                        <strong>Error</strong> Something went wrong. Please try again.
                                                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div> : null
                                                }
                                                {
                                                    this.state.otpError ? <div className="alert alert-warning alert-dismissible fade show" role="alert">
                                                        <strong>Error</strong> OTP has expired. Please go back and click to resend OTP.
                                                          <button type="button" className="close" data-dismiss="alert" aria-label="Close">
                                                            <span aria-hidden="true">&times;</span>
                                                        </button>
                                                    </div> : null
                                                }
                                                {
                                                    this.state.passwordWeak ? <div className="alert alert-danger" role="alert">
                                                        Password is too weak. Please use a stronger password.
                                                  </div> : null
                                                }
                                                {
                                                    this.state.success ? <Redirect to={{ pathname: '/login' }} /> : null
                                                }
                                                <div className="text-center">
                                                    <h1 className="subtitle">Reset Password</h1>
                                                    {/* <span>We will send a One Time Password (OTP) to the registered email address.</span> */}
                                                </div>
                                                {/* form */}
                                                <form className="user" onSubmit={this.handleSubmit}>
                                                    <div className="form-group">
                                                        <label>New Password</label>
                                                        <input type={this.state.hidden ? "password" : "text"} className="form-control form-control-user" id="password-field" name="password"
                                                            value={this.state.password} placeholder="Input password" onChange={this.handlePasswordChange} />
                                                        {this.state.passwordError ? <p style={{ color: 'red' }}>{this.state.passwordError}</p> : <p className="minPasswordValid"></p>}
                                                    </div>
                                                    {this.state.showProgressBar === true ?
                                                        <PasswordMeter field={this.state.password} /> : ""}
                                                    <div className="form-group">
                                                        <label>Repeat Password</label>
                                                        <input type={this.state.hidden_R ? "password" : "text"} className="form-control form-control-user" id="password-field2" placeholder="Input password" name="repeat_password"
                                                            value={this.state.repeat_password} onChange={this.handleRepeatPasswordChange} />
                                                        <span toggle="#password-field2" className="field-icon2password toggle-password" onClick={this.toggleShowRepeatPassword}><img className="eye" src={require('../../../../assets/img/eye.svg')} alt="" /></span>
                                                        <p style={{ color: 'red' }}>{this.state.repeatPasswordError}</p>
                                                        {/* <Link to="/reset_password" className="float-right bold forgettxt">Forgot Password?</Link> */}
                                                    </div>
                                                    <button type="submit" className="btn btn-primary btn-user btn-block mt-5" >
                                                        Confirm New Password
                                                    </button>
                                                    <div className="mt-3 text-center">
                                                        <Link className="bold mt-2" to="/login">Login</Link>
                                                    </div>
                                                </form>
                                                {/* end form */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <LPFooter />
            </div >
        );
    }
}