import moment from "moment";

export const dateSanitizer = (date) => {
    if (date === null || date === "Invalid Date") {
        return '';
    } else {
        return date;
    }
}

export const dateFormat = (date) => {
    if (date === "") {
        return date;
    } else {
        const formattedDate = moment(date).format("YYYY-MM-DD");
        return formattedDate;
    }
}