import * as actionCreators from '../actionTypes';

const initialState = {
  unreadMessageData: [],
    isLoading: false,
    notificationData:[],
    isLoadingNotification:false
};

const dashboardReducer = (state = initialState, action) => {
    switch (action.type) {
    //   Messages
    case actionCreators.UNREAD_MESSAGES_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.UNREAD_MESSAGES_SUCCESS: {
      return {
        ...state,
        unreadMessageData: action.payload,
        isLoading: false
      }
    }
    case actionCreators.UNREAD_MESSAGES_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
      }
          
// notification  
      case actionCreators.NOTIFICATION_DATA_CHECK:
        return {
          ...state,
          isLoadingNotification: true,
        };
      case actionCreators.NOTIFICATION_DATA_SUCCESS: {
        return {
          ...state,
          notificationData: action.payload,
          isLoadingNotification: false
        }
      }
      case actionCreators.NOTIFICATION_DATA_ERROR: {
        return {
          ...state,
          isLoadingNotification: false,
        }
      }
    default:
      return { ...state };
  }
};
export default dashboardReducer;