import React, { Suspense, lazy } from "react";
import Header from "../../../../Static/Header/Header";
import Footer from "../../../../Static/Footer/Footer";
import SidePanel from "../../../../Static/SidePanel/SidePanel";
import NameBar from "../../../../Static/NameBar/NameBar";
// import OpportunityManagementList from "./OpportunityManagementList";
import OpportunityFilter from "../../../../../Components/OpportunityInfoListing/OpportunityFilter";
import OpportunitySort from "../../../../../Components/OpportunityInfoListing/OpportunitySort";
// import { SetUpProfile, CreateGAPOpportunityLink } from "../../../../Components/OpportunityInfoListing/SetUpProfile";
import { SetUpProfile } from "../../../../../Components/OpportunityInfoListing/SetUpProfile";
import { CreateGAPOpportunityLink } from "../../../../../Components/OpportunityInfoListing/SetUpProfile";
import refreshToken from "../../../../../../RefreshToken";
import Axios from "axios";
import { apiBaseUrl } from "../../../../../../configs";
import "../../../OpportunityManagement/OpportunityManagementList/OpportunityManagement.css"
import { OpportunityCardLoader } from "../../../../../Components/ContentLoader/OpportunityCardLoader";
import { logger } from "../../../../../../logger";
import GAPOpportunityManagement from "../GAPOpportunityManagement";

const OpportunityManagementListGAP = lazy(() => import('./OpportunityManagementListGAP'));

const log = logger("All");

class AllGAP extends React.Component {
    state = {
        isLoadingState: true,
        all: [],
        opportunityDetails: [],
        searchValue: "",
        networkError: false,
        visibility: "",
        profile_status: localStorage.getItem('profile_status'),
        role: localStorage.getItem('role'),
    }
    componentDidMount = () => {
        this.setState({
            path: this.props.match.path
        });
        this.getAllGAP();
    };

    getAllGAP = () => {
        const token = localStorage.getItem("token");

        Axios.get(apiBaseUrl + "/mp/list_gap", {
            headers: {
                Authorization: token,
            },
        })
            .then((response) => {
                this.setState(
                    {
                        success: true,
                        opportunityDetails: response.data.profiles || [],
                        all: response.data.profiles || [],
                        isLoadingState: false
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    isLoadingState: false
                });
                if (error.message === 'Network Error') {
                    this.setState({
                        networkError: true
                    })
                }
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    filteredData = (data) => {
        log.info("data", data);
        this.setState({
            all: data
        })
    }
    sortData = (data) => {
        this.setState({
            all: data
        })
    }
    render() {

        return (
            <React.Fragment>
                <Header />

                <div id="wrapper">
                    <SidePanel />

                    <div id="content-wrapper" className="d-flex flex-column bg-white">
                        <div className="pagetitle-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <NameBar pageTitle="GAP Opportunity Management" />
                                </div>
                            </div>
                        </div>

                        <div id="content" className="graybg">
                            <div className="container-fluid tabs-bg p-0">
                                <div className="row">
                                    <GAPOpportunityManagement path={this.state.path} />
                                    <div className="col-md-4">
                                        <OpportunityFilter
                                            opportunityDetails={this.state.opportunityDetails}
                                            filteredData={(data) => this.filteredData(data)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tab-content">
                                            <div className="tab-pane active pad-all-20" id="all" role="tabpanel" >
                                                <div className="row opportunity-margin">
                                                    <div className="col-4">
                                                        <h5 className="gray-title"> You have {this.state.all && this.state.all.length} opportunities</h5>
                                                    </div>
                                                    <CreateGAPOpportunityLink />
                                                    <OpportunitySort
                                                        opportunityDetails={this.state.all && this.state.all}
                                                        sortData={(data) => this.sortData(data)}
                                                    />
                                                </div>
                                                <div className="row deals no-background scrolling-section">
                                                    <div className="col-12">
                                                        {this.state.isLoadingState === true ? (
                                                            <OpportunityCardLoader loop={2} />
                                                        ) : (
                                                            <div>
                                                                {this.state.all.map((details, i) => {
                                                                    return (
                                                                        <div key={i}>
                                                                            <Suspense fallback={<OpportunityCardLoader />}>
                                                                                <OpportunityManagementListGAP
                                                                                    index={i}
                                                                                    amount={details.amount_requested}
                                                                                    datePosted={details.created_at}
                                                                                    id={details.mp_id}
                                                                                    name={details.name}
                                                                                    closingDate={details.closing_at}
                                                                                    visibility={details.visibility}
                                                                                    publish_status={details.publish_status}
                                                                                    admin_status={details.admin_status}
                                                                                    image_urls={details.image_urls}
                                                                                    activeTabName="all"
                                                                                    term={details.loan_term}
                                                                                    proposed_fee={details.proposed_fee}
                                                                                    getAllResponse={(data) => this.getAllGAP(data)}
                                                                                    opportunity={details}
                                                                                />
                                                                            </Suspense>
                                                                        </div>
                                                                    );
                                                                })}
                                                                {(this.state.all && this.state.all.length === 0) && this.state.profile_status === "pending" ?
                                                                    <SetUpProfile
                                                                        display="Thank you for completing your profile. Your information is now under review by the Fundever team for Approval"
                                                                    />
                                                                    : ""}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment >
        );
    }
}
export default AllGAP;
