import React from "react";
import { Form } from "react-bootstrap";
import { logger } from '../../../logger';

const log = logger("OpportunitySearch");

class OpportunityFilter extends React.Component {
    state = {
        searchValue: "",
        opportunityDetails: ""
    }
    componentDidMount = () => {
        this.setState({
            opportunityDetails: this.props.opportunityDetails,
            searchValue: localStorage.getItem("searchFilter") === null ? "" : localStorage.getItem("searchFilter")
        }, () => {
            if (this.state.searchValue !== "" && this.state.searchValue !== null) {
                this.executeFilter(this.state.searchValue)
            }

        })
    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.opportunityDetails !== this.props.opportunityDetails) {
            this.setState({
                opportunityDetails: this.props.opportunityDetails,
                searchValue: localStorage.getItem("searchFilter") === null ? "" : localStorage.getItem("searchFilter")
            }, () => {
                if (this.state.searchValue !== "" && this.state.searchValue !== null) {
                    this.executeFilter(this.state.searchValue)
                }
            })
        }
    }
    // search start for all tabs
    filterList = (event) => {
        //Ref: https://www.cluemediator.com/search-filter-for-multiple-object-in-reactjs
        // exclude column list from filter
        this.setState({
            searchValue: event.target.value
        }, () => {
            localStorage.setItem("searchFilter", this.state.searchValue);
            this.executeFilter(this.state.searchValue);
        })
    };

    executeFilter = (value) => {
        log.info("In execute", value)
        const excludeColumns = [
            "external_id",
            "lived_at",
            "visibility",
            "image_urls",
            "summary",
            "conclusion",
            "access",
            "address2",
            "country",
            "post_code",
            "region"
        ];
        var updatedList = this.state.opportunityDetails;

        const lowercasedFilter = value.toLowerCase().trim();
        const filteredData = updatedList.filter((item) => {
            return Object.keys(item).some((key) =>
                excludeColumns.includes(key)
                    ? false
                    : (key === "property_type" || key === "financing_type" ? item[key].toString().toLowerCase().indexOf(lowercasedFilter) > -1 : (item[key].toString().toLowerCase().indexOf(lowercasedFilter) === 0 || (item.address.city.toString().toLowerCase().indexOf(lowercasedFilter) === 0)))
            );
        });
        log.info("filteredData", filteredData);
        this.props.filteredData(filteredData);
    }
    render() {

        return (
            <div className="searchbargray mr-3 pull-right">
                <span>
                    <i className="fa fa-search" aria-hidden="true"></i>
                </span>
                <Form.Control value={this.state.searchValue} placeholder={this.props.placeholder ? this.props.placeholder : "Search"} onChange={(e) => this.filterList(e)} />
            </div>
        );
    }
}

export default OpportunityFilter;
