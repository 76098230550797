import React from "react"
import { Form } from "react-bootstrap";
import { CostNumberFormat } from "../../../Components/NumberFormat/CostNumberFormat";

export const AdditionalSponsors = (props) => {
    const { data, index, removeClick, handleChangeDA, handleNumberDA, disabled, role, publish_status } = props
    return (

        <React.Fragment key={"exclusiveMembersList-" + index}>
            <div className="row">
                <div className="col-md-2">
                    <h1 className="title5">
                        Sponsor {index + 1}
                    </h1>
                </div>

                {role !== "lender" && publish_status !== "closed" && (
                    < div className="col-md-12 text-right" >
                        <span className="underline graycolor font-13 cursor-pointer" onClick={removeClick} >Remove </span>
                    </div >
                )}

            </div>

            {role === "lender" ? (

                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Name </label>
                        <input name="name"
                            id={"name-" + index}
                            type="text"
                            value={data.name || ""}
                            className="form-control form-control-user"
                            disabled={disabled}
                        />

                    </div>
                    <Form.Group className="form-group col-md-6">
                        <Form.Label>Annual Income</Form.Label>
                        <CostNumberFormat
                            name={"annual_income"}
                            id={"annual_income-" + index}
                            classname="form-control form-control-user"
                            value={data.annual_income || ""}
                            pre="$"
                            disabled={disabled}
                        />
                    </Form.Group>
                    <div className="form-group col-md-6">
                        <label>Years of Experience</label>
                        <input type="number"
                            name="experience"
                            id={"experience-" + index}
                            value={data.experience || ""}
                            className="form-control form-control-user"
                            disabled={disabled}
                            min="0"
                            max="100"
                        />

                    </div>
                    <Form.Group className="form-group col-md-6">
                        <Form.Label>Net Worth</Form.Label>
                        <CostNumberFormat
                            name={"net_worth"}
                            id={"net_worth-" + index}
                            classname="form-control form-control-user"
                            value={data.net_worth || ""}
                            pre="$"
                            disabled={disabled}
                        />
                    </Form.Group>
                </div>

            ) : (

                <div className="row">
                    <div className="form-group col-md-6">
                        <label>Name <i className="fa fa-asterisk"></i></label>
                        <input name="name"
                            id={"name-" + index}
                            type="text"
                            value={data.name || ""}
                            onChange={handleChangeDA}
                            className="form-control form-control-user"
                            placeholder="Enter Name"
                            required
                            disabled={disabled}
                        />

                    </div>
                    <Form.Group className="form-group col-md-6">
                        <Form.Label>Annual Income <i className="fa fa-asterisk"></i></Form.Label>
                        <CostNumberFormat
                            name={"annual_income"}
                            id={"annual_income-" + index}
                            classname="form-control form-control-user"
                            value={data.annual_income || ""}
                            pre="$"
                            updateField={handleNumberDA}
                            placeholder="Enter $ Annual Income"
                            disabled={disabled}
                            required
                        />
                    </Form.Group>
                    <div className="form-group col-md-6">
                        <label>Years of Experience</label>
                        <input type="number"
                            name="experience"
                            id={"experience-" + index}
                            value={data.experience || ""}
                            onChange={handleNumberDA}
                            className="form-control form-control-user"
                            placeholder="Enter Years of Experience"
                            required
                            disabled={disabled}
                            min="0"
                            max="100"
                        />

                    </div>
                    <Form.Group className="form-group col-md-6">
                        <Form.Label>Net Worth <i className="fa fa-asterisk"></i></Form.Label>
                        <CostNumberFormat
                            name={"net_worth"}
                            id={"net_worth-" + index}
                            classname="form-control form-control-user"
                            value={data.net_worth || ""}
                            pre="$"
                            updateField={handleNumberDA}
                            placeholder="Enter $ Net Worth"
                            disabled={disabled}
                            required
                        />
                    </Form.Group>
                </div>
            )}

        </React.Fragment>
    );
}

export default AdditionalSponsors;