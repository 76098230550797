import React from "react";
import Avatar from "../../../Components/Avatar/Avatar";

export const GAPExclusiveMembers = (props) => {
  return (
    <div className="row">
      <div className="col-md-12 collasible-table">
        {
          props.accessData.map((data, index) => {
            return (
              <div className="table-row row mb-2" key={index}>
                <div className="col-4 table-col">
                  <div className="namedetail">
                    <Avatar src={data.image_url} alt={data.name} size={39} className={"mr-2"} />  {data.name}
                  </div>
                </div>
                <div className="col-5 table-col pt-3">{data.company_name}</div>
                <div className="col-3 table-col">
                  <button type="button" className="approvebtn message" onClick={(e) => props.submitDeleteMember(data.external_id)}
                  >Remove</button>
                </div>
              </div>
            )
          })
        }
      </div>
    </div>
  )
}
