import React from 'react';
import { logger } from '../../../logger';

const log = logger("OpportunityStatusBanner");
export const CloseOppBanner = (props) => {
    const { opportunitySummary, role } = props
    return (
        <>
            {role === 'lender' ? <>
                {props.title}
                <div className={(opportunitySummary.conclusion.reason.indexOf("funded_") > -1) ? "alert alert-danger closedDealFunded extraBannerPadding" : "alert alert-danger closedDeal extraBannerPadding"} role="alert">
                    {opportunitySummary.conclusion.reason === "funded_fundever" || opportunitySummary.conclusion.reason === "funded_elsewhere" ? (
                        <span className="closingBlock">This Opportunity has been funded</span>
                    ) : (
                        <span className="closingBlock">This Opportunity has been closed</span>
                    )}
                </div>
            </> :
                <>
                    {props.title}
                    <div className={(opportunitySummary.conclusion.reason.indexOf("funded_") > -1) ? "alert alert-danger closedDealFunded" : "alert alert-danger closedDeal"} role="alert">
                        <span className="closingBlock">Opportunity is now closed</span>
                        <React.Fragment>
                            {opportunitySummary.conclusion.reason === "funded_fundever" ? <p className="mt-2 text-reason">Funded on Fundever</p> : ""}
                            {opportunitySummary.conclusion.reason === "funded_elsewhere" ? <p className="mt-2 text-reason">Funded Elsewhere</p> : ""}
                            {opportunitySummary.conclusion.reason === "cancelled" ? <p className="mt-2 text-reason">Cancelled</p> : ""}
                            {opportunitySummary.conclusion.reason === "expired" ? <p className="mt-2 text-reason">Mandate Expired</p> : ""}
                            {opportunitySummary.conclusion.reason === "no_interest" ? <p className="mt-2 text-reason">No Lender Interest</p> : ""}
                        </React.Fragment>
                    </div>
                </>
            }
        </>
    )
}
export const ReOpen = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger closedDealReopened" role="alert">
                <span className="closingBlock">This opportunity is being reviewed for re-opening</span>
                <p className="mt-2 text-reason">Please wait 3-5 business days</p>
            </div>
        </>
    )
}

export const Pending = (props) => {
    const { title } = props
    return (
        <>
            {props.title}
            <div className="alert alert-danger closedDealReopened padding" role="alert">
                <span className="closingBlock">{title}</span>
            </div>
        </>
    )
}

export const PendingOpp = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger closedDealReopened padding" role="alert">
                <span className="closingBlock">Your Opportunity is now under review by the Fundever team for publishing. <br /> We will notify you once it is completed.<br /> Thank you for your patience.</span>
            </div>
        </>
    )
}


export const ClosedGapOpp = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger closedGapDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child">Opportunity Closed<br />Please contact Fundever Support to reopen</div>
                </div>
            </div>
        </>
    )
}

export const ClosedGapOppAdmin = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger closedGapDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child">Opportunity Closed</div>
                </div>
            </div>
        </>
    )
}

export const PendingGapOpp = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child mr-2"><div className="pending-circle"></div></div>
                    <div className="rec-child">Your Opportunity is now under review by the Fundever team for publishing. We will notify you once it is completed.</div>
                    <br/>Thank you for your patience.
                </div>
            </div>
        </>
    )
}

export const PendingGAPChanges = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal" role="alert">
                <div className="container">
                    <div className="rec-child mr-2"><div className="pending-circle"></div></div>
                    <div className="rec-child">Your changes have been submitted and will be reviewed by the Fundever team. We will notify you once it is completed.</div>
                    <br />
                    Your opportunity information will remain live until the changes are approved.
                    <br />
                    Thank you for your patience.
                </div>
            </div>
        </>
    )
}

export const PublishedOpp = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child mr-3"><div className="live-circle"></div></div>
                    <div className="rec-child"> Opportunity is live - Changes to the opportunity will require Fundever approval before being published</div>
                </div>
            </div>
        </>
    )
}

export const PublishedOppAdmin = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child mr-3"><div className="live-circle"></div></div>
                    <div className="rec-child"> Opportunity is LIVE</div>
                </div>
            </div>
        </>
    )
}

export const PendingOppAdmin = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child mr-3"><div className="pending-circle"></div></div>
                    <div className="rec-child"> Opportunity waiting for Approval</div>
                </div>
            </div>
        </>
    )
}

export const PendingGapChangesAdmin = (props) => {
    return (
        <>
            {props.title}
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child mr-3"><div className="pending-circle"></div></div>
                    <div className="rec-child"> Opportunity Changes waiting for Approval</div>
                </div>
            </div>
        </>
    )
}

export const LenderCloseBanner = (props) => {
    return (
        <>
            {props.title}
            {props.portfolioType === "completed" && <div className="alert alert-danger closedDealFunded" role="alert">
                <span className="closingBlock">This Opportunity Has Been Completed</span>
                <p className="mt-2 text-reason">Funded on Fundever</p>
            </div>}

            {props.portfolioType === "cancelled" && <div className="alert alert-danger closedDeal" role="alert">
                <span className="closingBlock">This Opportunity Has Been Cancelled</span>
            </div>}
        </>
    )
}

export const LenderGAPContact = (props) => {
    return (
        <>
            <div className="alert alert-danger liveDeal padding" role="alert">
                <div className="container">
                    <div className="rec-child">Please Contact Fundever if you are interested in this Opportunity </div>
                    <div className="rec-child ml-3">
                        <button
                            type="button"
                            className="contactbtn"
                        >
                            <a href={`mailto:gap@fundever.com?subject=${props.subject}`}>
                                I'm Interested
                            </a>
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}


export const OpportunityStatusBanner = (props) => {
    const { interest, summary, role } = props
    log.info("intrest", interest);
    log.info("summary", summary);
    return (
        <>
            {(summary && summary.conclusion !== null) &&
                <>
                    {
                        (interest === null || interest === undefined) ?
                            <>
                                {((summary && summary.conclusion !== null) && (summary && summary.publish_status === "closed")) ?
                                    <CloseOppBanner opportunitySummary={summary} title={props.title} role={role} />
                                    : ""
                                }
                                {(summary && summary.conclusion !== null) && (summary && summary.publish_status === "published") && (summary && summary.admin_status === "pending") ?
                                    <ReOpen title={props.title} />
                                    : ""}
                            </> :
                            <>
                                {(interest.type !== "watching" || interest.type !== "portfolio" || interest.type !== "") ?
                                    <>
                                        {((summary && summary.conclusion !== null) && (summary && summary.publish_status === "closed")) ?
                                            <CloseOppBanner title={props.title} opportunitySummary={summary} role={role} />
                                            : ""
                                        }
                                        {(summary && summary.admin_status === "pending") && (summary && summary.publish_status === "published") ?
                                            <ReOpen title={props.title} />
                                            : ""}
                                    </> :
                                    ""
                                }
                            </>
                    }
                </>}
            <>
                {interest !== null && <>
                    {((interest !== null || interest !== undefined) && (interest.type === "completed" || interest.type === "cancelled")) &&
                        <LenderCloseBanner title={props.title} portfolioType={interest && interest.type} />
                    }

                </>}
                {(summary && summary.conclusion === null) && (summary && summary.publish_status === "published") && (summary && summary.admin_status === "pending") && <PendingOpp title={props.title} />}
            </>


        </>
    )
}