import React from 'react';
import { Link } from 'react-router-dom';



class LPFooter extends React.Component {
    render() {


        return (
            <div >
                <footer>
                    <div className="container-fluid" id="footer">
                        <div className="row mobileGridFooter">
                            <div className="col-md-2 col-sm-12">
                                <img src={require('../../../assets/img/white-logo.svg')} alt="" className="footerlogo" />
                            </div>
                            <div className="col-md-8 col-sm-12 text-center">
                                <ul className="links">
                                    <li><Link to="/borrower">Borrowers</Link></li>
                                    <li><Link to="/broker">Brokers</Link></li>
                                    <li><Link to="/lender">Lenders</Link></li>
                                    <li><Link to="/brokerage">Brokerage</Link></li>
                                    <li><Link to="/gap">GAP</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                    <li><Link to="/faq">FAQ</Link></li>
                                </ul>
                                <br />
                                <div className="col-md-12">
                                    <ul className="social-icon mobileShow">
                                        <li><a href="https://www.linkedin.com/company/fundever"><img className="pr-2 img-responsive" src={require('../../../assets/img/linkedin.svg')} alt="" /></a>
                                            <a href="https://www.linkedin.com/company/fundever">Fundever Linkedin</a></li>

                                    </ul>
                                </div>
                                <ul className="copyright">
                                    <li><Link to="/">© 2022 Fundever All rights reserved.</Link></li>
                                    <li><Link to="/privacy">Privacy Policy</Link></li>
                                    <li><Link to="/terms">Terms of Service</Link></li>
                                </ul>
                            </div>
                            <div className="col-md-2 mobileHide">
                                <ul className="social-icon">
                                    <li><a href="https://www.linkedin.com/company/fundever"><img src={require('../../../assets/img/linkedin.svg')} alt="" /></a></li>
                                    <li ><a href="https://www.linkedin.com/company/fundever">Fundever Linkedin</a></li>

                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

            </div >
        );
    }
}

export default LPFooter;