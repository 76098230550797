import { combineReducers } from "redux";
//All reducers
import requestReducer from "./Request/RequestReducer";
import propertyDetailsReducer from "./PropertyDetails/PropertyDetailsReducers";
import equityStructureReducer from "./EquityStructure/EquityStructureReducer";
import opportunityListReducer from "./OpportunityList/OpportunityListReducer";
import dashboardReducer from "./DashBoard/DashboardReducer";
import messageChatReducer from "./MessageChat/MessageChatReducer";

const rootReducer =(combineReducers({
  request: requestReducer,
  property: propertyDetailsReducer,
  equity: equityStructureReducer,
  opportunityList: opportunityListReducer,
  dashboard: dashboardReducer,
  messages: messageChatReducer
}));
export default rootReducer;
