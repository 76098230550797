import React from "react";

export const CreateGAPCard = (props) => {
    const {title, createComponent} = props

    return (
        <div className="card border-0 border-rad-0 mb-4 shadow-lg">
            <div className="card-header">
                <h4 className="profile-heading pl-4 mb-0">{title}</h4>
            </div>
            <div className="card-body p-0">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="p-5 pt-2 pb-2">
                                {createComponent}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}