import React from "react";
import { NavLink } from "react-router-dom";

class AdminSidePanel extends React.Component {
  state = {
    minimize: localStorage.getItem("minimizeSidebar"),
  };
  componentDidMount = () => {
    this.setState({
      minimize: localStorage.getItem("minimizeSidebar"),
    });
    // localStorage.setItem("minimizeSidebar",false)
  };
  minimize = () => {
    localStorage.setItem("minimizeSidebar", true);
    this.setState(
      {
        minimize: true,
      },
      () => {
        localStorage.setItem("minimizeSidebar", true);
      }
    );
  };
  maximize = () => {
    localStorage.setItem("minimizeSidebar", false);
    this.setState(
      {
        minimize: false,
      },
      () => {
        localStorage.setItem("minimizeSidebar", false);
      }
    );
  };

  formatRole = (role) => {

    if (role !== null && role !== undefined && role.startsWith("admin")) {
      if (role === "admin") {
        return "Administrator";
      } else if (role === "admin_super") {
        return "Super Administrator";
      } else if (role === "admin_bizdev") {
        return "Business Development";
      }
    } else {
      return role;
    }
  }

  render() {
    const role = localStorage.getItem("role");
    const formattedRole = this.formatRole(role);
    const CapitalRole = formattedRole.toUpperCase();
    const profileName = localStorage.getItem("profileName");
    const CapitalProfileName = profileName.charAt(0).toUpperCase() + profileName.slice(1);
    return (
      <React.Fragment>
        {this.state.minimize === true || this.state.minimize === "true" ? (
          <div className="sidebar minimized">
            <ul className="navbar-nav  sidebar-dark " id="accordionSidebar">
              <NavLink
                className="nav-item "
                activeClassName="active"
                to="/dashboard"
              >
                <div className="nav-link">
                  <span className="file-folder">
                    <img
                      src={require("../../../../assets/img/icons/sidebar/dashboard-icon.svg")}
                      alt="Dashboard"
                      width={24}
                    />
                  </span>
                </div>
              </NavLink>
              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/users"
              >
                <div className="nav-link">
                  <span>
                    <img
                      src={require("../../../../assets/img/icons/sidebar/users-icon.svg")}
                      alt="Users"
                      width={24}
                    />
                  </span>
                </div>
              </NavLink>

              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/opportunities"
              >
                <div className="nav-link">
                  <span>
                    <img
                      src={require("../../../../assets/img/icons/sidebar/opt-icon.svg")}
                      alt="Opportunities"
                      width={24}
                    />
                  </span>
                </div>
              </NavLink>
              {role === "admin_super" && (
                <NavLink
                  className="nav-item"
                  activeClassName="active"
                  to="/admin-management"
                >
                  <div className="nav-link">
                    <span>
                      <img
                        src={require("../../../../assets/img/icons/sidebar/manage-admin-icon.svg")}
                        alt="Opportunities"
                        width={24}
                      />
                    </span>
                  </div>
                </NavLink>
              )}
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                ></button>
              </div>
            </ul>
            <button
              className="minimize-btn collapsed"
              onClick={() => this.maximize()}
            >
              <i className="fa fa-expand" aria-hidden="true"></i>
            </button>
          </div>
        ) : (
          <div className="sidebar">
            <div className="loginuser-outer">
              <div className="loginuser">
                <h4>{CapitalProfileName}</h4>
                <p>{CapitalRole}</p>
              </div>
            </div>
            <ul
              className="navbar-nav  sidebar-dark accordion"
              id="accordionSidebar"
            >
              <NavLink
                className="nav-item "
                activeClassName="active"
                to="/dashboard"
              >
                <div className="nav-link">
                  <span>Dashboard</span>
                </div>
              </NavLink>
              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/users"
              >
                <div className="nav-link">
                  <span>Users</span>
                </div>
              </NavLink>

              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/opportunities"
              >
                <div className="nav-link">
                  <span>Opportunities</span>
                </div>
              </NavLink>

              <NavLink
                className="nav-item"
                activeClassName="active"
                to="/opportunities-gap"
              >
                <div className="nav-link">
                  <span>GAP Opportunities</span>
                </div>
              </NavLink>
          
              {role === "admin_super" && (
                <NavLink
                  className="nav-item"
                  activeClassName="active"
                  to="/admin-management"
                >
                  <div className="nav-link">
                    <span>Admin Management</span>
                  </div>
                </NavLink>

              )}
              <div className="text-center d-none d-md-inline">
                <button
                  className="rounded-circle border-0"
                  id="sidebarToggle"
                ></button>
              </div>
            </ul>
            <button className="minimize-btn" onClick={() => this.minimize()}>
              <i className="fa fa-compress mr-2" aria-hidden="true"></i>{" "}
              Minimize Side Bar
            </button>
          </div>
        )}
      </React.Fragment>
    );
  }
}

export default AdminSidePanel;
