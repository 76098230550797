import React from "react";
import { bytesToSize } from "../../Utils/formatFileZise";

export const FileDisplay = (props) => {
  const { fileName, fileNote, index, catIndex, removeFile, handleChange, handleSaveNote } = props

  return (
    <div className="outer-container">
      <div className="drop-box-outer">
        {/* <img className="drop-box-img" src={require('../../../assets/img/uploaded.svg')} alt="uploaded" /> */}
        <FileIcon name={fileName} />
        <span className="drop-box-uploading">{fileName} </span>
        {removeFile && <img className="drop-box-close" src={require('../../../assets/img/DropClose.svg')} alt="closeimg" onClick={() => removeFile(this)} />}
      </div>
      <input
        type="text"
        className="form-control form-control-user mt-2"
        placeholder="Add Notes"
        id={"fileNote" + catIndex + '-' + index}
        name="fileNote"
        value={fileNote}
        onChange={(e) => handleChange(e)}
        maxLength="150"
      />
      <img className='cursor-pointer file-note-check'
        src={require('../../../assets/img/checked-green.svg')}
        onClick={(e) => handleSaveNote(e)} width="30" alt="Edit" />
    </div>
  );
}

export const FilePreview = (props) => {
  const { fileName, size, maxLength = 20, theme = "light" } = props;
  return (
    <>
      <FileIcon name={fileName} imgClass={"msg-drop-box-name"} />
      <div className={`msg-drop-box-name ${theme}`} title={fileName}>{fileName.length > maxLength ? fileName.substring(0, maxLength) + '...' : fileName} {size && <span className="size"> <br />{bytesToSize(size)}</span>} </div>
    </>
  );
}

export const FileIcon = (props) => {
  const { name, imgClass } = props;

  const ext = name.length > 0 ? name.split(".").pop() : "";
  let fileIcon = [];
  fileIcon["docx"] = "file-doc-icon.svg";
  fileIcon["doc"] = "file-doc-icon.svg";
  fileIcon["txt"] = "file-txt-icon.svg";
  fileIcon["xlsx"] = "file-xls-icon.svg";
  fileIcon["xls"] = "file-xls-icon.svg";
  fileIcon["pdf"] = "file-pdf-icon.svg";
  fileIcon["png"] = "file-png-icon.svg";
  fileIcon["jpg"] = "file-jpg-icon.svg";
  fileIcon["jpeg"] = "file-jpg-icon.svg";
  fileIcon["JPG"] = "file-jpg-icon.svg";
  fileIcon["zip"] = "icons/chat/icon-zip.svg";
  const FileTypeIcon = fileIcon[ext] !== undefined ? fileIcon[ext] : 'uploading.svg';
  return (
    ext !== "" && <img className={imgClass ? imgClass : "drop-box-img"} src={require('../../../assets/img/' + FileTypeIcon)} alt="uploaded" />
  )
}

export const FileIconDoc = (props) => {
  const { name } = props;

  const ext = name.length > 0 ? name.split(".").pop() : "";
  let fileIcon = [];
  fileIcon["docx"] = "docx.svg";
  fileIcon["doc"] = "docx.svg";
  fileIcon["txt"] = "txt.svg";
  fileIcon["xlsx"] = "xls.svg";
  fileIcon["xls"] = "xls.svg";
  fileIcon["pdf"] = "pdfIcon.svg";
  fileIcon["png"] = "file-view-png.svg";
  fileIcon["jpg"] = "file-view-jpg.svg";
  fileIcon["jpeg"] = "file-view-jpg.svg";
  fileIcon["JPG"] = "file-view-jpg.svg";
  const FileTypeIcon = fileIcon[ext] !== undefined ? fileIcon[ext] : 'uploading.svg';
  return (
    ext !== "" && <img src={require('../../../assets/img/' + FileTypeIcon)} alt="fileTypeDisplay" />
  )
}