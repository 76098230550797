import React from 'react';
import { NavLink } from 'react-router-dom';
import AdminSidePanel from './AdminSidePanel';

class SidePanel extends React.Component {
	state = {
		showSidebar: true
	}
	componentDidMount = () => {
		if (this.isMobile() === true) {
			this.setState({
				showSidebar: false
			})
		}
	}
	toggleSideBar = () => {
		this.setState({
			showSidebar: !this.state.showSidebar
		})
	}
	isMobile = () => {
		return window.screen.width < 480
	};

	render() {
		const role = localStorage.getItem('role');
		const profileName = localStorage.getItem('profileName');
		const allowed_mp_types = localStorage.getItem('allowed_mp_types');

		return (

			role === 'admin' ? <AdminSidePanel /> :
				<>
					<button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3 whitebtn" onClick={() => this.toggleSideBar()}>
						<i className="fa fa-bars"></i>
					</button>
					{this.state.showSidebar === true && <div className="sidebar">
						<div className="loginuser-outer">
							<div className="loginuser">
								<h4>{profileName}</h4>
								<p>{role}</p>
							</div>
						</div>

						<ul className="navbar-nav sidebar-dark accordion" id="accordionSidebar">
							<NavLink className="nav-item " activeClassName="active" to="/dashboard" isActive={(match, location) => {
								return location.pathname === '/' || location.pathname === '/dashboard';
							}}>
								<div className="nav-link" >
									<span>Dashboard</span>
								</div>
							</NavLink>
							{(role === 'broker') ? <NavLink className="nav-item" activeClassName="active" to="/opportunity">
								<div className="nav-link">
									<span>OPPORTUNITY</span>
								</div>
							</NavLink> : null}
							{((role === 'broker' || role === "borrower") && allowed_mp_types !== null && allowed_mp_types.includes("gap")) ? <NavLink className="nav-item" activeClassName="active" to="/gap-management">
								<div className="nav-link">
									<span>GAP OPPORTUNITY</span>
								</div>
							</NavLink> : null}
							{role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes("standard") ? <NavLink className="nav-item" activeClassName="active" to="/my-opportunities">
								<div className="nav-link" >
									<span>My Opportunities</span>
								</div>
							</NavLink> : null}

							{role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes("standard") ? <NavLink className="nav-item" activeClassName="active" to="/market-opportunities">
								<div className="nav-link" >
									<span>Market Opportunities</span>
								</div>
							</NavLink> : null}

							{role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes("gap") ? <NavLink className="nav-item" activeClassName="active" to="/gap-opportunities">
								<div className="nav-link" >
									<span>GAP Opportunities</span>
								</div>
							</NavLink> : null}

							{(role === 'broker' || role === 'lender') && (
								<NavLink className="nav-item" activeClassName="active" to="/network">
									<div className="nav-link" >
										<span>Network</span>
									</div>
								</NavLink>
							)}

							<div className="text-center d-none d-md-inline">
								<button className="rounded-circle border-0" id="sidebarToggle"></button>
							</div>
						</ul>
					</div >}
				</>
		);
	}
}

export default SidePanel;