import React, { useState, useEffect } from "react";
import Axios from 'axios';
import { apiBaseUrl } from '../../../configs';
import refreshToken from '../../../RefreshToken'
import { Link } from "react-router-dom";
import { Greeting } from "../Greetings/Greeting";

export const SetUpProfileButton = (props) => {
    const { content, children, message } = props;
    const [profile, setProfile] = useState(null);

    const getProfile = () => {
        const token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + '/my_profile', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                setProfile(response.data);
                localStorage.setItem('profile_status', response.data.account.status);
                localStorage.setItem('completed_status', response.data.account.profile.completed);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    useEffect(() => {
        getProfile()
    }, []);

    return (
        profile !== null && profile.account.profile.completed === true && profile.account.status !== 'unverified' ?
            <>
                <div className={"pt-3"}><button to="/create-profile" className="btn solidbtn" disabled={true}>Profile under review</button></div>
                <p className={"account-under-review setup-profile-txt"}>{message ? message : "Thank you for completing your profile. It is currently under review by team Fundever"}</p>

            </>
            :
            <>{content && content}<Link to="/my-profile" className="btn solidbtn">Set Up Profile</Link> {children}</>
    );

}

export const SetUpProfile = (props) => {
    const profileName = localStorage.getItem('profileName');
    const CapitalProfileName = profileName.charAt(0).toUpperCase() + profileName.slice(1);
    const name = CapitalProfileName.split(" ");
    const firstName = name[0];
    return (
        <div className="card-description mt-5">
            <Greeting name={firstName} />
            <SetUpProfileButton
                content={<p className="p-0">It looks like you have yet to create opportunities</p>}
                message={props.display}
            >
                <p className="pt-2">Nearly there, please complete your profile information to publish your opportunities</p>

            </SetUpProfileButton>
        </div>
    );
}

export const CreateOpportunityLink = (props) => {
    return (
        <div className="col-3 offset-md-2 text-right mb-3">
            <Link to="/create-opportunity">
                <button className="primarybtn create">
                    <img src={require('../../../assets/img/createNew.svg')} alt="createNewIcon" />&nbsp;
                    Create New Opportunity
                </button>
            </Link>
        </div>
    );
}

export const CreateGAPOpportunityLink = () => {
    return (
        <div className="col-3 offset-md-2 text-right mb-3">
            <Link to="/gap-management/create-gap">
                <button className="primarybtn create">
                    <img src={require('../../../assets/img/createNew.svg')} alt="createNewIcon" />&nbsp;
                    Create GAP Opportunity
                </button>
            </Link>
        </div>
    );
}