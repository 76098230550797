import BaseApi from "../BaseApi";

export async function getAllAttachmentsApi(mp_id) {
    const res = await BaseApi.get(`/mp/attachments/${mp_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function deleteAttachmentApi(external_id) {
    const res = await BaseApi.delete(`/mp/attachment/${external_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}