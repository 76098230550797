import React from "react";
import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
export const ReactDatePicker = (props) => {
  const { handleDateChange, value, name, id, minDate, maxDate, displayText, required, format, disableValue } = props;
  return (
    <div className="rdt reactdatepicker">
      <DatePicker
        selected={value}
        onChange={handleDateChange}
        name={name}
        id={id}
        className="form-control cursor-pointer"
        maxDate={maxDate}
        placeholderText={displayText}
        minDate={minDate}
        required={required}
        dateFormat={(format) ? format : 'yyyy-MM-dd'}
        popperPlacement="bottom-end"
        autoComplete="off"
        popperModifiers={{
          flip: {
            behavior: ["bottom"]
          },
          preventOverflow: {
            enabled: false
          },
          hide: {
            enabled: false
          }
        }}
        disabled={disableValue}
      /></div>
  );
}