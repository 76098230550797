import React from 'react';
import { Route, Redirect } from 'react-router-dom';


export default class ProtectedRoute extends React.Component {


    isAuthenticated() {
        const token = localStorage.getItem('token');
        if (token && token.length > 10) {
            return true;
        }
    }


    render() {

        const isAlreadyAuthenticated = this.isAuthenticated();


        const { component: Component, ...props } = this.props

        return (
            <Route
                {...props}
                render={props => (
                    isAlreadyAuthenticated ?
                        <Component {...props} /> :
                        <Redirect to='/login' />
                )}
            />
        )
    }
}