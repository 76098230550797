import React from "react";

class OpportunitySort extends React.Component {
    state = {
        opportunityDetails: ""
    }
    componentDidMount = () => {
        this.setState({
            opportunityDetails: this.props.opportunityDetails
        }, () => {
            this.sortAmountDes();
        })

    }
    componentDidUpdate = (prevProps, prevState) => {
        if (prevProps.opportunityDetails !== this.props.opportunityDetails) {
            this.setState({
                opportunityDetails: this.props.opportunityDetails
            }, () => {
                this.sortAmountDes();
            })
        }
    }
    sortChange = (event) => {
        if (event.target.value === "2") {
            this.sortAmountDes();
        } else if (event.target.value === "1") {
            this.sortAmountAsc();
        } else if (event.target.value === "3") {
            this.sortDateDes();
        } else if (event.target.value === "4") {
            this.sortDateAsc();
        } else if (event.target.value === "5") {
            this.sortClosingDate();
        }
    };
    sortAmountAsc = () => {
        const { opportunityDetails } = this.state;
        opportunityDetails.sort((a, b) => a.amount_requested - b.amount_requested);
        this.props.sortData(opportunityDetails)
    };

    sortAmountDes = () => {
        const { opportunityDetails } = this.state;
        opportunityDetails.sort((a, b) => b.amount_requested - a.amount_requested);
        this.props.sortData(opportunityDetails)
    };

    sortDateAsc = () => {
        const { opportunityDetails } = this.state;
        opportunityDetails.sort(
            (a, b) =>
                new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        );
        this.props.sortData(opportunityDetails)
    };

    sortDateDes = () => {
        const { opportunityDetails } = this.state;
        opportunityDetails.sort(
            (a, b) =>
                new Date(b.created_at).getTime() - new Date(a.created_at).getTime()
        );
        this.props.sortData(opportunityDetails)
    };
    sortClosingDate = () => {
        const { opportunityDetails } = this.state;
        opportunityDetails.sort(
            (a, b) =>
                Math.abs(Date.now() - new Date(a.closing_at)) -
                Math.abs(Date.now() - new Date(b.closing_at))
        );
        this.props.sortData(opportunityDetails)
    };
    render() {

        return (
            <div className="col-3 text-right">
                <select className="selectdropdown-shadow width" onChange={this.sortChange} >
                    <option value="2">Sort by: Amount requested high to low</option>
                    <option value="1">Sort by: Amount requested low to high</option>
                    <option value="3">Sort by: Date posted new to old</option>
                    <option value="4">Sort by: Date posted old to new</option>
                    <option value="5">Sort by: Closing date nearest to current date</option>
                </select>
            </div>
        );
    }
}

export default OpportunitySort;
