import React from "react";
import { Form } from "react-bootstrap";
import Header from "../../Static/Header/Header";
import Footer from "../../Static/Footer/Footer";
import moment from "moment";
import Axios from "axios";
import { apiBaseUrl } from "../../../../configs";
import { CostNumberFormat } from "../../../Components/NumberFormat/CostNumberFormat";
import { ReusableModal } from "../../../Components/ReusableModal/ReusableModal";
import Swal from 'sweetalert2';
import refreshToken from "../../../../RefreshToken";
import { PromptModal } from "../../../Components/PromptModal/PromptModal";
import { ReactDatePicker } from "../../../Components/DateFormat/ReactDatePicker";
import { CreateGAPCard } from "./CreateGAPCard";
import { dateSanitizer } from "../../../Utils/dateSanitizer";
import { numberFormatSanitizerInt, emptyLeadingSpaceSanitizer } from "../../../Utils/sanitizeNumber";
import { validate } from "../../../Utils/validation";
import { SubmitButton } from "../../../Components/Button/SubmitButton";
import { logger } from "../../../../logger";
import { createGapValidation } from "../CreateOpportunity/CreateOppValidationSchema";
import { createGapOpportunityApi } from "../../../../Store/Api/OpportunitiesApi/CreateOpportunity";
import AddButton from "../../../Components/AddButton/AddButton";
import AddButtonSmall from "../../../Components/AddButtonSmall/AddButtonSmall";
import { AdditionalSponsors } from "./AdditionalSponsors";
import { ClosedGapOpp, PendingGAPChanges, PendingGapOpp, PublishedOpp } from "../../../Components/CloseOpportunityBanner/CloseOpportynityBanners";
import { Link } from "react-router-dom";
import SidePanel from "../../Static/SidePanel/SidePanel";

const log = logger("CreateGAPOpportunity");

class CreateGAPOpportunity extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount_requested: "",
            borrower_name: "",
            closingDate: "",
            loan_term: "",
            opportunityName: "",
            proposed_fee: "",
            sponsors: [],
            repayment_description: "",
            use_of_funds: "",
            mp_id: "",
            visibility: "public",
            publish_status: "",
            admin_status: "",
            store_status: "",
            confirmDelete: false,
            showingInfoWindow: false,
            submitCalled: false,
            showModal: false,
            closeModal: false,
            isBlocking: false,
            showError: false,
            errorMsg: false,
            validationError: false,
            openPrompt: false,
            nextLocation: null,
            profile_status: localStorage.getItem("profile_status"),
            dateValidationError: false,
            token: localStorage.getItem('token'),
            showPending: false,
            card_title: "",
            op_card_title: "",
        };
    }

    componentDidMount = () => {
        window.scrollTo(0, 0);

        this.setState({
            mp_id: this.props.match.params.mp_id,
        }, () => {
            if (this.state.mp_id !== undefined && this.state.mp_id !== null) {
                this.getGapSummary(this.state.mp_id);
            }
        })
    }

    getGapSummary = (mp_id) => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/summary_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    amount_requested: response.data.summary.amount_requested,
                    borrower_name: response.data.summary.borrower_name,
                    closingDate: response.data.summary.closing_at === "" ? response.data.summary.closing_at : moment(response.data.summary.closing_at).toDate(),
                    loan_term: response.data.summary.loan_term,
                    opportunityName: response.data.summary.name,
                    proposed_fee: response.data.summary.proposed_fee,
                    sponsors: response.data.summary.sponsors,
                    repayment_description: response.data.summary.repayment_description,
                    use_of_funds: response.data.summary.use_of_funds,
                    publish_status: response.data.summary.publish_status,
                    admin_status: response.data.summary.admin_status,
                    store_status: response.data.summary.store_status,
                })

            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    closePrompt = () => {
        this.setState({
            openPrompt: false,
            nextLocation: null,
        })
    }
    onConfirm = () => {
        this.navigateToNextLocation();
    }

    navigateToNextLocation = () => {
        this.props.history.push(this.state.nextLocation.pathname);
    }

    handleDateChange = (date) => {
        const dateValid = dateSanitizer(date);
        this.setState({
            isBlocking: true,
            closingDate: dateValid,
            dateValidationError: false
        });
    };

    handleChange = (event) => {

        log.info("event", event);
        this.setState({
            [event.target.name]: emptyLeadingSpaceSanitizer(event.target.value),
        });
    }

    handleNumber = (event) => {
        this.setState({
            [event.target.name]: numberFormatSanitizerInt(event.target.value)
        });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState(() => ({
            isBlocking: false,
        }))
        if (validate(createGapValidation)) {
            const data = {
                "amount_requested": this.state.amount_requested,
                "borrower_name": this.state.borrower_name,
                "closing_at": moment(this.state.closingDate).format("YYYY-MM-DD"),
                "loan_term": this.state.loan_term,
                "name": this.state.opportunityName,
                "proposed_fee": this.state.proposed_fee,
                "sponsors": this.state.sponsors,
                "repayment_description": this.state.repayment_description,
                "use_of_funds": this.state.use_of_funds,
                "visibility": this.state.visibility
            }
            // api call
            createGapOpportunityApi(data).then(response => {
                this.setState({
                    success: true,
                    status: response.status,
                    mp_id: response.data.mp_id,
                }, () => {

                });
                this.open();
            })
                .catch(() => {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',
                        timer: 2000
                    })
                    this.setState({
                        error: true,
                    }, () => {
                        if (this.state.error) {
                            this.close();
                        }
                    });
                });
        }
    };

    updateField = (e) => {
        e.preventDefault();
        const isBlocking = true;
        this.setState({
            [e.target.name]: emptyLeadingSpaceSanitizer(e.target.value),
            isBlocking
        }, () => {

        });
    };

    close = () => {
        this.setState({
            showModal: false,
            closeModal: false
        });
    };

    open = () => {
        if (this.state.status === 200) {
            this.setState({ showModal: true });
        }
    };
    routeChange = () => {
        this.setState({
            showModal: false
        })
        this.props.history.push({ pathname: `/gap-management` });
    }
    routeChangeContinue = () => {
        this.setState({
            showModal: false
        })
        this.props.history.push({ pathname: "mp/summary_gap/" + this.state.mp_id });
    }

    addSponsor() {
        this.setState((prevState) => ({
            sponsors: [
                ...prevState.sponsors,
                { name: "", years: "", income: "", netWorth: "" },
            ],
            disableSubmit: false,
            isBlocking: true
        }));
    }

    removeSponsor(e) {
        this.setState({
            isBlocking: false,
            disableSubmit: false
        })
        if (this.state.sponsors[e].external_id !== "") {

            let arr = [...this.state.sponsors]; // make a separate copy of the array
            let index = e;
            if (index !== -1) {
                arr.splice(index, 1);
                this.setState({
                    sponsors: arr,
                    success2: true
                });
            }

        } else {
            let arr = [...this.state.sponsors]; // make a separate copy of the array
            let index = e;
            if (index !== -1) {
                arr.splice(index, 1);
                this.setState({ sponsors: arr });
            }
        }

    }

    cancel = () => {
        this.props.history.push("/gap-management");
    }

    getBlockStatus = (value, disable) => {
        this.setState({
            isBlocking: value,
            disabled: disable
        }, () => {
            //this.props.blockStatus(this.state.isBlocking);
            if (this.state.activeTab === "primary") {
                this.setState({
                    disablePrimary: this.state.disabled
                })
            }
            if (this.state.activeTab === "partner") {
                this.setState({
                    disablePartner: this.state.disabled
                })
            }
        })
    }

    handleSave = () => {

        var token = localStorage.getItem('token');
        const data = {
            "amount_requested": this.state.amount_requested,
            "borrower_name": this.state.borrower_name,
            "closing_at": moment(this.state.closingDate).format("YYYY-MM-DD"),
            "loan_term": this.state.loan_term,
            "name": this.state.opportunityName,
            "proposed_fee": this.state.proposed_fee,
            "sponsors": this.state.sponsors,
            "repayment_description": this.state.repayment_description,
            "use_of_funds": this.state.use_of_funds,
        }
        Axios.put(apiBaseUrl + "/mp/summary_gap/" + this.state.mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    success: true
                })

                if (this.state.success) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Opportunity Information Updated',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
                this.getGapSummary(this.state.mp_id);
            })
            .catch(error => {
                this.setState({
                    error: true
                });

                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });


    }

    handleNextStep = () => {
        this.props.history.push("/gap-management/gap-borrower-profile/" + this.state.mp_id);
    }

    addClick() {
        this.setState((prevState) => ({
            sponsors: [
                ...prevState.sponsors,
                { name: "", annual_income: 0, experience: 0, net_worth: 0 },
            ],
            isBlocking: true
        }));
    }


    removeClick(i) {
        let sponsors = [...this.state.sponsors];
        sponsors.splice(i, 1);
        this.setState({ sponsors, isBlocking: true });
    }

    handleChangeDA(i, e) {
        const { name, value } = e.target;
        let sponsors = [...this.state.sponsors];
        sponsors[i] = { ...sponsors[i], [name]: emptyLeadingSpaceSanitizer(value) };
        const isBlocking = true
        this.setState({ sponsors, isBlocking, disableSubmitExclusive: false });
    }

    handleNumberDA(i, e) {
        const { name, value } = e.target;
        let sponsors = [...this.state.sponsors];
        sponsors[i] = { ...sponsors[i], [name]: numberFormatSanitizerInt(value) };
        const isBlocking = true
        this.setState({ sponsors, isBlocking, disableSubmitExclusive: false });
    }

    deleteOpportunityModal = () => {
        this.setState({ confirmDelete: true });
    }

    closeOpportunityModal = () => {
        this.setState({ closeModal: true })
    }

    // Close gap opportunity - reason will be "Cancelled"
    closeOpportunity = () => {

        var token = localStorage.getItem('token');

        const data = {
            "reason": "cancelled"
        }

        Axios.put(apiBaseUrl + "/mp/close_gap/" + this.state.mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    closeModal: false,
                    success: true
                })

                if (this.state.success) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Opportunity Closed',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
                this.getGapSummary(this.state.mp_id);
            })
            .catch(error => {
                this.setState({
                    error: true
                });

                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

    }

    deleteOpportunitySubmit = () => {
        const token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;

        Axios.delete(apiBaseUrl + '/mp/' + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(() => {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Your Opportunity has been Deleted',
                    showConfirmButton: false,
                    timer: 2000
                });
                this.setState({
                    showModalcloseOpportunity: false
                }, () => {
                    this.props.history.push('/gap-opportunity');
                });

            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    closeDeleteOpportunityModal = () => {
        this.setState({ confirmDelete: false });
    }

    render() {

        return (

            <div>
                <Header />
                <SidePanel />
                {this.state.publish_status === "published" && this.state.admin_status === "approved" && (this.state.store_status !== "pending" && !this.state.borrowerProfilePending) ? (
                    <PublishedOpp />
                ) : this.state.store_status === "pending" ? (
                    <PendingGAPChanges />
                ) : this.state.publish_status === "published" && this.state.admin_status === "pending" ? (
                    <PendingGapOpp />
                ) : this.state.publish_status === "closed" && (
                    <ClosedGapOpp />
                )}

                <div className="container">
                    <div className="col pl-0 pt-3">
                        <div className="pagetitle steppertitle">
                            <h1>
                                <Link to={{ pathname: `/gap-management` }} ><i className="fa fa-arrow-left iconcolor" aria-hidden="true"></i></Link>
                                GAP Opportunity Management
                            </h1>
                        </div>
                    </div>
                    <br />
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <form className="user" id="Form" onSubmit={this.onSubmit}>

                                <CreateGAPCard
                                    title={this.state.publish_status === "published" || this.state.publish_status === "closed" ? "GAP Opportunity Summary" : "Create GAP Opportunity"}
                                    status={"draft"}
                                    createComponent={
                                        <div className="row">
                                            <div className="col-12">
                                                <h2 className="title5"> Opportunity Information </h2>
                                            </div>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Opportunity Name <i className="fa fa-asterisk"></i></Form.Label>
                                                <Form.Control
                                                    type="text"
                                                    className="form-control form-control-user"
                                                    id="opportunityName"
                                                    name="opportunityName"
                                                    value={this.state.opportunityName}
                                                    onChange={(e) => this.handleChange(e)}
                                                    maxLength="25"
                                                    placeholder={"Enter Opportunity Name"}
                                                />
                                            </Form.Group>
                                            {/* {this.state.mp_id !== undefined && (
                                                <Form.Group className="form-group col-md-6">
                                                    <Form.Label>Borrower Name <i className="fa fa-asterisk"></i></Form.Label>
                                                    <Form.Control
                                                        type="text"
                                                        className="form-control form-control-user"
                                                        id="borrower_name"
                                                        name="borrower_name"
                                                        value={this.state.borrower_name}
                                                        onChange={(e) => this.handleChange(e)}
                                                        maxLength="25"
                                                        placeholder={"Enter Borrower Name"}
                                                        disabled
                                                    />
                                                </Form.Group>
                                            )} */}
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Amount Requested <i className="fa fa-asterisk"></i></Form.Label>
                                                <CostNumberFormat
                                                    name={"amount_requested"}
                                                    id={"amount_requested"}
                                                    classname="form-control form-control-user"
                                                    value={this.state.amount_requested}
                                                    pre="$"
                                                    updateField={(e) => this.handleNumber(e)}
                                                    placeholder={"Enter $ Amount Requested"}
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Loan Term <i className="fa fa-asterisk"></i></Form.Label>
                                                <CostNumberFormat
                                                    name="loan_term"
                                                    id="loan_term"
                                                    value={this.state.loan_term === 0 ? "" : this.state.loan_term}
                                                    updateField={(e) => this.handleNumber(e)}
                                                    placeholder={"Please indicate how many months"}
                                                    classname="form-control form-control-user"
                                                    pre=""
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group col-md-6">
                                                <Form.Label>Proposed Fee <i className="fa fa-asterisk"></i></Form.Label>
                                                <CostNumberFormat
                                                    name={"proposed_fee"}
                                                    id={"proposed_fee"}
                                                    classname="form-control form-control-user"
                                                    value={this.state.proposed_fee}
                                                    pre="$"
                                                    updateField={(e) => this.handleNumber(e)}
                                                    placeholder={"Enter $ Amount Fee"}
                                                />
                                            </Form.Group>
                                            <Form.Group className="col-md-6">
                                                <Form.Label>Financing Deadline <i className="fa fa-asterisk"></i></Form.Label>
                                                <br />
                                                <ReactDatePicker
                                                    name="closingDate"
                                                    id="closingDate"
                                                    value={this.state.closingDate || ""}
                                                    handleDateChange={(e) => this.handleDateChange(e)}
                                                    displayText={"Type or select the date when opportunity expires"}
                                                    maxDate={moment("9999-12-31").toDate()}
                                                    minDate={new Date()}
                                                />
                                            </Form.Group>
                                            <Form.Group className="form-group col-12">
                                                <Form.Label>Proposed Use of Funds Description <i className="fa fa-asterisk"></i></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    className="form-control textbox"
                                                    placeholder="Write a description about the use of funds"
                                                    id="use_of_funds"
                                                    name="use_of_funds"
                                                    maxLength="1000"
                                                    value={this.state.use_of_funds}
                                                    onChange={(e) => this.updateField(e)}
                                                ></Form.Control>
                                                <span className="float-right">{this.state.use_of_funds.length}/1000 characters</span>
                                            </Form.Group>
                                            <Form.Group className="form-group col-12">
                                                <Form.Label>Proposed Repayment Description <i className="fa fa-asterisk"></i></Form.Label>
                                                <Form.Control
                                                    as="textarea"
                                                    className="form-control textbox"
                                                    placeholder="Write a description about repayment"
                                                    id="repayment_description"
                                                    name="repayment_description"
                                                    maxLength="1000"
                                                    value={this.state.repayment_description}
                                                    onChange={(e) => this.updateField(e)}
                                                ></Form.Control>
                                                <span className="float-right">{this.state.repayment_description.length}/1000 characters</span>
                                            </Form.Group>

                                            <div className="col-12">
                                                <hr />
                                            </div>
                                            <div className="col-12">
                                                <h2 className="title5">Additional Sponsors</h2>
                                            </div>

                                            {(this.state.sponsors.length === 0 && this.state.publish_status !== "closed") ?
                                                <div className="col-12">
                                                    <AddButton btnLabel="Add Sponsors"
                                                        classname={"paddingSetting"}
                                                        addMoreHandler={this.addClick.bind(this)}
                                                        htmlElem={
                                                            <p className="buttonBelowText mt-4">Add Additional Sponsors for your Opportunity</p>
                                                        }
                                                    />
                                                </div>
                                                : ''}
                                            <div className="col-12 mt-4 add-new-form">
                                                {this.state.sponsors.map((data, i) => (
                                                    <>
                                                        <div key={"Sponsor" + i}>
                                                            <AdditionalSponsors
                                                                data={data}
                                                                index={i}
                                                                removeClick={this.removeClick.bind(this, i)}
                                                                handleChangeDA={this.handleChangeDA.bind(this, i)}
                                                                handleNumberDA={this.handleNumberDA.bind(this, i)}
                                                                admin_status={this.state.admin_status}
                                                                publish_status={this.state.publish_status}
                                                            />
                                                            <div className="col-12">
                                                                <hr />
                                                            </div>
                                                        </div>
                                                    </>
                                                ))}
                                                {(this.state.sponsors.length > 0 && this.state.publish_status !== "closed" && (
                                                    <AddButtonSmall btnLabel="+ Add More Sponsors" addMoreHandler={this.addClick.bind(this)} />
                                                ))}
                                                {/* {(this.state.sponsors.length > 0 && this.state.admin_status !== "pending" && this.state.publish_status !== "closed") ?
                                                    <AddButtonSmall btnLabel="+ Add More Sponsors" addMoreHandler={this.addClick.bind(this)} />
                                                    : ''
                                                } */}
                                            </div>

                                            {this.state.mp_id !== undefined && this.state.admin_status !== "pending" && this.state.publish_status !== "closed" && (
                                                <div className="col-12 text-right mt-2">
                                                    {log.info(
                                                        "disableSubmit",
                                                        this.state.disabledSubmit
                                                    )}
                                                    <button
                                                        type="button"
                                                        disabled={this.state.disabledSubmit}
                                                        className="solidbtn"
                                                        onClick={() => this.handleSave()}
                                                    >
                                                        Save Changes
                                                    </button>
                                                </div>
                                            )}
                                        </div>
                                    }
                                />

                                <div className="col-12 text-right mt-5 create-bottom">

                                    {this.state.publish_status === "draft" && (
                                        <button
                                            type="button"
                                            disabled={this.state.disabledSubmit}
                                            className="deletebtn mr-5 float-left"
                                            onClick={() => this.deleteOpportunityModal()}
                                            style={{ width: "20%" }}
                                        >
                                            Delete Opportunity
                                        </button>
                                    )}


                                    {this.state.publish_status === "published" && (
                                        <button
                                            type="button"
                                            disabled={this.state.disabledSubmit}
                                            className="primarybtn mr-5 float-left"
                                            onClick={() => this.closeOpportunityModal()}
                                            style={{ width: "20%" }}
                                        >
                                            Close Opportunity
                                        </button>
                                    )}

                                    {this.state.mp_id === undefined ? (
                                        <button type="button" className="cancelbtn mr-3 text-center" onClick={() => this.cancel()} style={{ width: "22%" }} >
                                            Cancel
                                        </button>
                                    ) : (
                                        <button type="button" className="cancelbtn mr-3 text-center" onClick={() => this.cancel()} style={{ width: "22%" }} >
                                            Back
                                        </button>
                                    )}

                                    {(this.state.mp_id === undefined && this.state.publish_status !== "published" && this.state.publish_status !== "closed" && this.state.admin_status !== "approved") && (
                                        <SubmitButton
                                            saveLoading={this.state.saveLoading}
                                            btnClass="primarybtn"
                                            title="Create Opportunity Profile"
                                        />
                                    )}

                                    {this.state.mp_id !== undefined && this.state.mp_id !== null && (
                                        <>
                                            <button
                                                type="button"
                                                disabled={this.state.disabledSubmit}
                                                className="primarybtn"
                                                onClick={() => this.handleNextStep()}
                                                style={{ width: "15%" }}
                                            >
                                                Next
                                            </button>
                                        </>
                                    )}
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <ReusableModal
                    show={this.state.confirmDelete}
                    close={(e) => this.close(e)}
                    hideCloseButton={true}
                    id={"confirmDelete"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={"text-center popuptxt"}
                    body={
                        <div>
                            <h4>Delete GAP Opportunity</h4>
                            <p className="centertxtbold">Do you want to delete this GAP Opportunity? </p>
                            <button type="button" className="solidbtnxs btn" onClick={this.deleteOpportunitySubmit}> Yes, Continue </button>
                            <span onClick={this.closeDeleteOpportunityModal.bind(this)} className="anchorbtn cursor-pointer link">Cancel</span>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.closeModal}
                    close={(e) => this.close(e)}
                    hideCloseButton={true}
                    id={"confirmDelete"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={"text-center popuptxt"}
                    body={
                        <div>
                            <h4>Close this Opportunity?</h4>
                            <p className="centertxtbold">Do you want to close this GAP Opportunity? <br /><br />Lenders will no longer be able to view your opportunity once it is closed</p>
                            <button type="button" className="solidbtnxs btn" onClick={this.closeOpportunity}> Yes, Continue </button>
                            <span onClick={this.close} className="anchorbtn cursor-pointer link">Cancel</span>
                        </div>
                    }
                />

                <ReusableModal
                    show={this.state.showModal}
                    close={(e) => this.close(e)}
                    hideCloseButton={true}
                    id={"createConfirmation"}
                    size={"md"}
                    modalHeaderContent={""}
                    bodyClass={"text-center popuptxt"}
                    body={
                        <div>
                            <h4>Opportunity Profile Created</h4>
                            <p className="centertxtbold">Do you want to continue and provide the required details for
                                this opportunity? </p>
                            <button type="button" className="solidbtnxs btn" onClick={this.handleNextStep}> Yes, Continue </button>
                            <span onClick={this.routeChange.bind(this)} className="anchorbtn cursor-pointer link">Save as draft for now</span>
                        </div>
                    }
                />

                <Footer />

                <PromptModal
                    openPrompt={this.state.openPrompt}
                    closePrompt={(data) => this.closePrompt(data)}
                    onConfirm={(data) => this.onConfirm(data)}
                />
            </div>
        );
    }
}
export default CreateGAPOpportunity;

