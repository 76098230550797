import React from 'react';
import { Link } from 'react-router-dom';

const NameBar = (props) => {
    const { backUrl, pageTitle, subTitle } = props;
    return (
        <React.Fragment >
            <div className="col-md-6 col-12">
                <div className="pagetitle mt-2 mb-2">
                    {backUrl && backUrl !== undefined ? <Link to={backUrl} className={"color-black"}><h1 className="font22"><i className="fa fa-angle-left"></i> {pageTitle}</h1></Link> : <h1 className="font22">{pageTitle}</h1>}
                    {subTitle !== "" && <p className="pageSubtitle mt-1">{subTitle}</p>}
                </div>
            </div>
        </React.Fragment>
    );
}

export default NameBar;