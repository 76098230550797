import React from 'react';
import ReactDOM from 'react-dom';
import Router from './routes';
import * as serviceWorker from './serviceWorker';
import './assets/css/sb-admin-2-min.css';
import './index.css';
import './assets/vendor/font-awesome/css/font-awesome.min.css';
import "react-datepicker/dist/react-datepicker.css";
import './assets/css/style.css';
import './app/Admin/Users/MergeAccount.css';
import JavascriptTimeAgo from 'javascript-time-ago'
import en from 'javascript-time-ago/locale/en'
import { Provider } from 'react-redux';
import { store } from './Store/store'
import ReactGA from 'react-ga';

// Sentry.init({
//   release: "fundever@2.2-production",
//   dsn: "https://419306c94345437685256633e1006252@o451609.ingest.sentry.io/5437745",
//   integrations: [
//     new Integrations.BrowserTracing(),
//   ],
//   tracesSampleRate: 1.0,
// });

// Google Analytics
ReactGA.initialize("UA-83427905-1");

JavascriptTimeAgo.addLocale(en)

class MainNode extends React.Component {

  render() {
    return (
      <div className="dekstop-top-65">

        {/* <React.StrictMode> */}
        <Router />
        {/* </React.StrictMode> */}

      </div>
    );
  }


}

// const MainNode = () => {

//   return (
//     <div className="dekstop-top-65">
//       {/* <React.StrictMode> */}
//       <Router />
//       {/* </React.StrictMode> */}

//     </div>
//   )
// }




ReactDOM.render(
  <Provider store={store}>
    <MainNode />
  </Provider>,
  document.getElementById('root')
);


serviceWorker.unregister();
