import React from 'react';
import { Link } from 'react-router-dom';




class Footer extends React.Component {
    render() {


        return (
            <div id="footer">
                <footer className="dashfooter">
                    <div className="container-fluid my-auto">
                        <div className="row">
                            <div className="col-md-4">
                                <div className="copyright text-center my-auto">
                                    <span>© 2021 Fundever. All rights reserved.</span>
                                </div>
                            </div>
                            <div className="col-md-8 text-right">
                                <ul className="links">
                                    <li><Link to="/faq">FAQ</Link></li>
                                    <li><Link to="/contact-us">Contact Us</Link></li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </footer>

            </div >
            // <div >
            //     <footer>
            //         <div className="container-fluid">
            //             <div className="row">
            //                 <div className="col-md-2">
            //                     <img src={require('../../../../assets/img/white-logo.svg')} alt="" className="footerlogo" />
            //                 </div>
            //                 <div className="col-md-8 text-center">
            //                     <ul className="links">
            //                         <li><Link to="/">Borrowers</Link></li>
            //                         <li><Link to="/">Brokers</Link></li>
            //                         <li><Link to="/">Lenders</Link></li>
            //                         <li><Link to="/contact-us">Contact Us</Link></li>
            //                         <li><Link to="/faq">FAQ</Link></li>
            //                     </ul>
            //                     <br />
            //                     <ul className="copyright">
            //                         <li><Link to="/">© 2020 Fundever All right reserved.</Link></li>
            //                         <li><Link to="/terms">Privacy Policy</Link></li>
            //                         <li><Link to="/terms">Terms of Service</Link></li>
            //                     </ul>
            //                 </div>
            //                 <div className="col-md-2">
            //                     <ul className="social-icon">
            //                         <li><a href="#"><i className="fa fa-instagram" aria-hidden="true"></i></a></li>
            //                         <li><a href="#"><i className="fa fa-facebook-square" aria-hidden="true"></i></a></li>
            //                         <li><a href="#"><i className="fa fa-twitter-square" aria-hidden="true"></i></a></li>
            //                         <li><a href="#"><i className="fa fa-pinterest" aria-hidden="true"></i></a></li>
            //                     </ul>
            //                 </div>
            //             </div>
            //         </div>
            //     </footer>

            // </div >
        );
    }
}

export default Footer;