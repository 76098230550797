import React from "react";
import Dropzone from "react-dropzone";

export const FileUpload = (props) => {
  const { handleDrop, fileType, html_view, multipleFiles, disable, width } = props

  return (
    <Dropzone
      onDrop={handleDrop}
      accept={fileType}
      minSize={1}
      maxSize={23072000}
      maxFiles={20}
      multiple={multipleFiles}
      disabled={disable}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject }) => {
        const additionalClass = isDragAccept
          ? "accept"
          : isDragReject
            ? "reject-file"
            : "";
        const widthClass = width && width === "auto" ? "" : "width-100";
        return (<div
          {...getRootProps({
            className: `dragBox ${widthClass} cursor-pointer ${additionalClass}`
          })}
        >
          <input {...getInputProps()} />
          {html_view}
        </div>
        )
      }}

    </Dropzone>
  );
}