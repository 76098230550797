import React from 'react';
import Header from '../../Static/Header/Header'
import Footer from '../../Static/Footer/Footer';
import BasicInfo from '../../LenderDashboard/MyProfile/LendersProfileTabsEdit/BasicInfo';
import MyBorrowerDocuments from './MyBorrowerDocuments';

class MyBorrowerProfile extends React.Component {
  
    render() {
        return (
            <div >
                <Header />
                <div className="container lender-profile-creation">
                    <div className="row justify-content-center">
                        <div className="col-xl-8 col-lg-10 col-md-9">
                            <div className="card o-hidden border-0 shadow-lg my-5">
                                <div className="card-body p-0">
                                    <div className="row">
                                        <div className="col-lg-12" >
                                            <div className="container-fluid tabs-bg p-0">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <ul className="nav nav-tabs lender-profile" role="tablist" >
                                                            <li className="nav-item">
                                                                <a className="nav-link pt-3 pb-3 active" data-toggle="tab" href="#basicInfo" role="tab" >
                                                                    Basic Info </a>
                                                            </li>
                                                            <li className="nav-item">
                                                                <a className="nav-link pt-3 pb-3" data-toggle="tab" ref={a => this.lendingPrefrences = a} href="#lendingPrefrences" role="tab" >
                                                                    Documents </a>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="container-fluid p-0">
                                                <div className="row">
                                                    <div className="col-md-12">
                                                        <div className="tab-content">
                                                            {/* ===========basicInfo tab============== */}
                                                            <div className="tab-pane active pad-all-20" id="basicInfo" role="tabpanel" >

                                                                <div className="row deals no-background">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <BasicInfo
                                                                                openAccordian={this.openAccordian}
                                                                            />


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {/* ===========lendingPrefrences tab============== */}
                                                            <div className="tab-pane pad-all-20" id="lendingPrefrences" role="tabpanel" >
                                                                <div className="row deals no-background">
                                                                    <div className="col-12">
                                                                        <div>
                                                                            <MyBorrowerDocuments props={this.props}/>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}

export default MyBorrowerProfile;