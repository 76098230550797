import React from 'react';
import { Link } from 'react-router-dom';
import { FileUpload } from '../../../Components/FileUpload/FileUpload';
import { apiBaseUrl } from '../../../../configs';
import axios from 'axios';
import refreshToken from '../../../../RefreshToken';
import { fileAttachmentUrl } from '../../../Utils/FileAttachmentUrl';
import { deleteAttachmentApi } from '../../../../Store/Api/OpportunitiesApi/Attachments';
import { MyBorrowerDocumentTableComponent } from './MyBorrowerDocumentTableComponent';
import Swal from 'sweetalert2';

class MyBorrowerDocuments extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            success: false,
            attachments: [],
            type: "",
            personal_identification: "",
        }
    }

    componentDidMount() {
        var token = localStorage.getItem('token');
        axios.get(apiBaseUrl + '/my_profile/borrower', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    success2: true,
                    success: true,
                    attachments: response.data.borrower_profile.attachments,
                    type: response.data.borrower_profile.type,
                    personal_identification: response.data.borrower_profile.personal_identification
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    handleDrop = (acceptedFiles, section) => {

        if (acceptedFiles.length !== 0) {
            acceptedFiles.map((fileName, i) => {
                const data = new FormData();
                data.append('file', fileName);
                this.uploadBorrowerAttachment(section, data);
                return fileName;
            });
        }
    }


    uploadBorrowerAttachment = (section_name, data) => {
        const token = localStorage.getItem('token');
        axios.post(apiBaseUrl + '/my_profile/borrower/attachment/' + section_name, data, {
            headers: { Authorization: token }
        }).then(response => {
            this.setState(state => ({
                success: true,
            }), () => {
                if (this.state.success) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Document successfully uploaded',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
                this.getData();
            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        })
    }

    updateBorrower = () => {
        const token = localStorage.getItem('token');
        const data = {
            "type": this.state.type,
            "personal_identification": this.state.personal_identification
        }
        axios.put(apiBaseUrl + '/my_profile/borrower', data, {
            headers: { Authorization: token }
        }).then(response => {
            this.setState(state => ({
                success2: true,
                success: true,
            }), () => {
                this.getData();
            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        })

        if (this.state.success || this.state.success2) {

            Swal.fire({
                position: 'top-end',
                icon: 'success',
                title: 'Your profile details has been saved',
                showConfirmButton: false,
                timer: 2000
            });

        } else {
            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',
                timer: 2000
            })
        }
    }

    getData = () => {
        let url = apiBaseUrl + '/my_profile/borrower';
        const token = localStorage.getItem('token');
        axios.get(url, {
            headers: {
                Authorization: token
            }
        }).then(response => {
            this.setState(state => ({
                success2: true,
                success: true,
                attachments: response.data.borrower_profile.attachments,
            }), () => {

            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        });
    }

    getAttachmentSectionCount = (attachments, section_name) => {

        var count = 0;

        for (var i = 0; i < attachments.length; i++) {
            if (attachments[i].section.includes(section_name)) {
                count++;
            }
        }
        return count;
    }

    downloadAttachment = (external_id, name) => {
        fileAttachmentUrl(external_id, name);
    }

    deleteAttachment = (external_id) => {
        deleteAttachmentApi(external_id).then(res => {
            this.setState({
                success: true,
            })

            if (this.state.success) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Document deleted successfully',
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            this.getData();
        });
    }

    handleChange = event => {

        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    handleSubmit = event => {
        this.updateBorrower();
    }

    editFileNote = (event, attachmentIndex) => {
        let attachment = this.state.attachments[attachmentIndex]
        attachment.comment = event.target.value
        this.setState({ attachment: this.state.attachments });
    };

    saveFileNote = (event, external_id, attachmentIndex) => {
        let attachment = this.state.attachments[attachmentIndex]
        let comment = attachment.comment

        const token = localStorage.getItem('token');
        const data = {
            "comment": comment,
        }
        axios.put(apiBaseUrl + '/mp/attachment/' + external_id, data, {
            headers: { Authorization: token }
        }).then(response => {
            this.setState(state => ({
                success: true,
            }), () => {
                if (this.state.success) {
                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Document note saved',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }
                this.getData();
            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        })

    };

    render() {

        const sectionIds = new Set(this.state.attachments.map((attachment) => attachment.section));

        return (
            <React.Fragment >
                <div >
                    <div className="container">
                        <form className="borrowerUser">
                            <div>
                                <div className="text-center">
                                    <h1 className="subtitle mt-3 mb-5">My Documents</h1>
                                </div>
                                <div className="row">

                                    <div className="col-12">
                                        <div className="form-heading">
                                            <p className="p-3">Individual / Corporate</p>
                                        </div>
                                        <select
                                            className="selectdropdown"
                                            name="type"
                                            id="type"
                                            value={this.state.type}
                                            onChange={this.handleChange}
                                        >
                                            <option value="individual" >Individual</option>
                                            <option value="corporation" >Corporation</option>
                                        </select>
                                        <br /><br />
                                        {this.state.type === "corporation" && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Organization Chart</p>
                                                </div>
                                                {!sectionIds.has("org_chart") ? (
                                                    <>
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "org_chart")}
                                                                fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                multipleFiles={false}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Organization Chart here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />

                                                    </>
                                                ) : (
                                                    <MyBorrowerDocumentTableComponent
                                                        apiData={this.state.attachments}
                                                        section={"org_chart"}
                                                        multiple={false}
                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                        deleteAttachment={(data) => this.deleteAttachment(data)}
                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                    />
                                                )}
                                                <div className="form-heading">
                                                    <p className="p-3">Incorporation Documents</p>
                                                </div>
                                                <br />
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "incorporation_docs")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Incorporation Documents here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"incorporation_docs"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                            </>
                                        )}
                                        <div className="form-heading">
                                            <p className="p-3">T1 Form</p>
                                        </div>
                                        <br />
                                        {!sectionIds.has("t1") ? (
                                            <>
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "t1")}
                                                        fileType={".pdf"}
                                                        multipleFiles={false}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop T1 form here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                            </>
                                        ) : (
                                            <MyBorrowerDocumentTableComponent
                                                apiData={this.state.attachments}
                                                section={"t1"}
                                                multiple={false}
                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                deleteAttachment={(data) => this.deleteAttachment(data)}
                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                            />
                                        )}
                                        <div className="form-heading">
                                            <p className="p-3">Notice of Assessment</p>
                                        </div>
                                        {!sectionIds.has("assessment_notice") ? (
                                            <>
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "assessment_notice")}
                                                        fileType={".pdf"}
                                                        multipleFiles={false}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Notice of Assessment file here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                            </>

                                        ) : (
                                            <MyBorrowerDocumentTableComponent
                                                apiData={this.state.attachments}
                                                section={"assessment_notice"}
                                                multiple={false}
                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                deleteAttachment={(data) => this.deleteAttachment(data)}
                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                            />
                                        )}

                                        <div className="col-12">
                                            <hr />
                                        </div>

                                        {this.state.type === "individual" ? (
                                            <div className="col-12 mt-4">
                                                <h3 className="title5 mb-0">Personal Net Worth</h3>
                                            </div>
                                        ) : (
                                            <div className="col-12 mt-4">
                                                <h3 className="title5 mb-0">Year End Financial Statements</h3>
                                            </div>
                                        )}

                                        <br />

                                        <div className="form-heading">
                                            <p className="p-3">Cash Balances</p>
                                        </div>
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "cash.statement")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Statement of account here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"cash.statement"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        <div className="form-heading">
                                            <p className="p-3">Stocks & Securities Accounts</p>
                                        </div>
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "securities.statement")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Statement of account here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"securities.statement"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        <div className="col-12 mt-4">
                                            <h5 className="title5 mb-0">
                                                Completed Real Estate
                                            </h5>
                                        </div>
                                        <br />
                                        {sectionIds.has("real_estate.land_title") && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Land Title</p>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "real_estate.land_title")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Land Title here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"real_estate.land_title"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        {sectionIds.has("real_estate.value") && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Market Value Appraisal / Self Declared Value</p>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "real_estate.value")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Market Value Appraisal / Self Declared Value here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"real_estate.value"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        {sectionIds.has("real_estate.mortgage_docs") && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Mortgage Documents</p>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "real_estate.mortgage_docs")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Mortgage Documents here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"real_estate.mortgage_docs"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        {sectionIds.has("real_estate.leasing_agreement") && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Leasing Agreement</p>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "real_estate.leasing_agreement")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Leasing Agreement here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"real_estate.leasing_agreement"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        {sectionIds.has("real_estate.tax_assessment") && (
                                            <>
                                                <div className="form-heading">
                                                    <p className="p-3">Property Tax Assessment</p>
                                                </div>
                                                <br />
                                            </>
                                        )}
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "real_estate.tax_assessment")}
                                                fileType={".pdf"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop Property Tax Assessment here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"real_estate.tax_assessment"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                        {this.state.type === "individual" && (
                                            <>
                                                {sectionIds.has("real_estate.insurance") && (
                                                    <>
                                                        <div className="form-heading">
                                                            <p className="p-3">Property Insurance Documents</p>
                                                        </div>
                                                        <br />
                                                    </>
                                                )}
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "real_estate.insurance")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Property Insurance Documents here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"real_estate.insurance"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                                <br />
                                                {sectionIds.has("real_estate.strata") && (
                                                    <>
                                                        <div className="form-heading">
                                                            <p className="p-3">Strata Status Certificate</p>
                                                        </div>
                                                        <br />
                                                    </>
                                                )}
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "real_estate.strata")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Strata Status Certificate here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"real_estate.strata"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                                <br />
                                                <div className="col-12 mt-4">
                                                    <h5 className="title5 mb-0">
                                                        Life Insurance Policy
                                                    </h5>
                                                </div>
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "life_insurance")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Life Insurance Policy here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"life_insurance"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                                <br />
                                                <div className="col-12 mt-4">
                                                    <h5 className="title5 mb-0">
                                                        Automobile Loan / Lease Documents
                                                    </h5>
                                                </div>
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "automobile_loans")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop Automobile Loan/Lease Documents here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"automobile_loans"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                            </>
                                        )}

                                        {this.state.type === "corporation" && (
                                            <>
                                                <div className="col-12 mt-4">
                                                    <h5 className="title5 mb-0">
                                                        Current Projects
                                                    </h5>
                                                </div>
                                                <div class="uploadOuter">
                                                    <FileUpload
                                                        handleDrop={(e) => this.handleDrop(e, "current_projects")}
                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                        multipleFiles={true}
                                                        disable={false}
                                                        html_view={
                                                            <>
                                                                <div>
                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                </div>
                                                                Drag and Drop current project files here OR
                                                                <div>
                                                                    <span className="browse">Browse File</span>
                                                                </div>
                                                            </>
                                                        }
                                                    />
                                                </div>
                                                <br />
                                                <MyBorrowerDocumentTableComponent
                                                    apiData={this.state.attachments}
                                                    section={"current_projects"}
                                                    multiple={true}
                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                />
                                            </>
                                        )}
                                        <br />
                                        <div className="col-12 mt-4">
                                            <h5 className="title5 mb-0">
                                                Other Documents
                                            </h5>
                                        </div>
                                        <div class="uploadOuter">
                                            <FileUpload
                                                handleDrop={(e) => this.handleDrop(e, "other")}
                                                fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                multipleFiles={true}
                                                disable={false}
                                                html_view={
                                                    <>
                                                        <div>
                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                        </div>
                                                        Drag and Drop any other applicable files here OR
                                                        <div>
                                                            <span className="browse">Browse File</span>
                                                        </div>
                                                    </>
                                                }
                                            />
                                        </div>
                                        <br />
                                        <MyBorrowerDocumentTableComponent
                                            apiData={this.state.attachments}
                                            section={"other"}
                                            multiple={true}
                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                        />
                                        <br />
                                    </div>
                                </div>
                            </div>

                            <button type="button" className="btn btn-primary btn-user btn-block mt-4 mb-4" onClick={this.handleSubmit}>Finish</button>

                            <Link to="/dashboard" className="skip-button mb-4">Skip for Later</Link>
                        </form>



                    </div>
                </div >
            </React.Fragment >
        );
    }
}

export default MyBorrowerDocuments;