// Action types for request data
export const REQUEST_DATA_CHECK = "REQUEST_DATA_CHECK";
export const REQUEST_DATA_ERROR = "REQUEST_DATA_ERROR";
export const REQUEST_DATA_SUCCESS = "REQUEST_DATA_SUCCESS";

// Action for property details data
export const PROPERTY_DETAILS_CHECK = "PROPERTY_DETAILS_CHECK";
export const PROPERTY_DETAILS_SUCCESS = "PROPERTY_DETAILS_SUCCESS";
export const PROPERTY_DETAILS_ERROR = "PROPERTY_DETAILS_ERROR";

// Action for Equity structure data
export const EQUITY_DATA_CHECK = "EQUITY_DATA_CHECK";
export const EQUITY_DATA_SUCCESS = "EQUITY_DATA_SUCCESS";
export const EQUITY_DATA_ERROR = "EQUITY_DATA_ERROR";

// Action for opportunity List
export const OPPORTUNITY_LIST_CHECK = "OPPORTUNITY_LIST_CHECK";
export const OPPORTUNITY_LIST_SUCCESS = "OPPORTUNITY_LIST_SUCCESS";
export const OPPORTUNITY_LIST_ERROR = "OPPORTUNITY_LIST_ERROR";

// Action for summary
export const SUMMARY_DATA_CHECK = "SUMMARY_DATA_CHECK";
export const SUMMARY_DATA_SUCCESS = "SUMMARY_DATA_SUCCESS";
export const SUMMARY_DATA_ERROR = "SUMMARY_DATA_ERROR";

// Action for Dashboard
export const UNREAD_MESSAGES_CHECK = "UNREAD_MESSAGES_CHECK";
export const UNREAD_MESSAGES_SUCCESS = "UNREAD_MESSAGES_SUCCESS";
export const UNREAD_MESSAGES_ERROR = "UNREAD_MESSAGES_ERROR";

export const NOTIFICATION_DATA_CHECK = "NOTIFICATION_DATA_CHECK";
export const NOTIFICATION_DATA_SUCCESS = "NOTIFICATION_DATA_SUCCESS";
export const NOTIFICATION_DATA_ERROR = "NOTIFICATION_DATA_ERROR";

// Action for message chat
export const CAHT_GROUP_CHECK = "CAHT_GROUP_CHECK";
export const CAHT_GROUP_SUCCESS = "CAHT_GROUP_SUCCESS";
export const CAHT_GROUP_ERROR = "CAHT_GROUP_ERROR";