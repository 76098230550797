import BaseApi from "../BaseApi";

export async function getMarketOpportunityApi() {
    const res = await BaseApi.get(`/mp/list`).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function getOppSummaryDataApi(mp_id) {
    const res = await BaseApi.get(`/mp/summary/${mp_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}

// Opportunity Members Management
export async function getAccessData(mp_id) {
    const res = await BaseApi.get(`/mp/access/${mp_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function getBanData(mp_id) {
    const res = await BaseApi.get(`/mp/bans/${mp_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function postInviteMembers(mp_id, data) {
    const res = await BaseApi.post(`/mp/access/${mp_id}/invite`, data).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function postBanMembers(mp_id, data) {
    const res = await BaseApi.post(`/mp/bans/${mp_id}`, data).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function deleteBanMembers(external_id) {
    const res = await BaseApi.delete(`/mp/ban/${external_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function moveToExclusionApi(external_id) {
    const res = await BaseApi.delete(`/mp/access/${external_id}/exclude`).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function approveAndDenyAccessApi(external_id, action, data) {
    const res = await BaseApi.put(`/mp/access/${external_id}/${action}`, data).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function revokeAccessApi(external_id) {
    const res = await BaseApi.delete(`/mp/access/${external_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}

// Reopen
export async function reopenApi(mp_id, data) {
    const res = await BaseApi.put(`/mp/reopen/${mp_id}`, data).then(response => { return response }).catch(error => { return error });
    return res;
}
// Delete opportunity
export async function deleteOppApi(mp_id) {
    const res = await BaseApi.delete(`/mp/${mp_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}
// Close opportunity
export async function closeOppApi(mp_id, data) {
    const res = await BaseApi.put(`/mp/close/${mp_id}`, data).then(response => { return response }).catch(error => { return error });
    return res;
}