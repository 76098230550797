import React, { useState } from 'react';
import { ProfileImageUpload } from '../ProfileImageUpload/ProfileImageUpload';
import profileimg from './profileimg.png';
import { logger } from '../../../logger';

const log = logger("ProfileAvatar");

export const ProfileAvatar = (props) => {
    const { fileNames, fileNamesFromApi, enableEdit, handleDrop, handleConfirm, removePreview, handleCancel } = props;
    const [openUploader, setOpenUploader] = useState(false);
    log.info("fileNamesFromApi", fileNamesFromApi);
    const handleUpload = () => {
        handleConfirm();
        setOpenUploader(!openUploader);
    }
    const openModal = () => {
        setOpenUploader(!openUploader);
    }
    const closeModal = () => {
        setOpenUploader(!openUploader);
        if (handleCancel) {
            setTimeout(() => { handleCancel() }, 200);
        }

    }
    return (
        <React.Fragment>
            <div className="profile-img">
                {fileNames.length === 0 ?
                    <img src={profileimg} alt="" /> :
                    <React.Fragment>
                        {fileNamesFromApi ? fileNamesFromApi.map(
                            (fileName, i) => (
                                fileName.url !== "" ? <img src={fileName.url} alt={"preview-image-" + i} key={i} /> : <img src={profileimg} alt="" key={i} />
                            )) : fileNames.map(
                                (fileName, i) => (
                                    fileName.url !== "" ? <img src={fileName.url} alt={"preview-image-" + i} key={i} /> : <img src={profileimg} alt="" key={i} />
                                ))}

                    </React.Fragment>
                }
                {enableEdit && <button type={"button"} className="btn btn-link editbtn" onClick={openModal}>Edit</button>}
            </div>
            <ProfileImageUpload
                showModal={openUploader}
                close={(e) => closeModal()}
                handleDrop={(e) => handleDrop(e)}
                handleConfirm={(e) => handleUpload(e)}
                fileNames={fileNames}
                removePreview={(i, external_id) => removePreview(i, external_id)}
            />
        </React.Fragment>
    )
}