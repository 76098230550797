import React from "react";
import Moment from 'moment';
import { Link } from "react-router-dom";
import NumberFormat from "react-number-format";

class OpportunityManagementListGAP extends React.Component {
    state = {
        province: this.props.province,
        token: localStorage.getItem('token'),
        printIsLoading: true,
        flowType: '',
        showModalConnection: false,
        fullProfile: [],
        opportunity: this.props.opportunity,
        loadingImage: false,
        existingFinancial: [],
        payoutCheck: []
    }

    componentDidMount = () => {
        this.setState({
            show: false,
        })
    }

    componentDidUpdate = () => {

    }

    closeConnection = () => {
        this.setState({
            showModalConnection: false,
            printIsLoading: true,
            show: true,

        });
    };
    render() {
        const { amount, term, proposed_fee, datePosted, id, name, route, closingDate, publish_status, admin_status, activeTabName } = this.props;
        localStorage.setItem("activeTabName", activeTabName);
        const opp_name = name.substring(0, 24);
        return (
            <React.Fragment>
                <div className="deals__card cursor-pointer" onClick={route}>
                    <div className="row">
                        <div className="deal__gap_avatar">
                            <Link to={{ pathname: `/gap-management/gap-opportunity/${id}/summary`, state: { id: id } }}>
                                <img src={require('../../../../../../assets/img/FundeverGAP.svg')} alt="logo" className="rounded-left" />
                            </Link>
                        </div>
                        <div className="col-md-9">
                            <div className="deal__description">
                                <div className="float-right">
                                    <div className="rounedtxt">
                                        <>
                                            {publish_status === "closed" ?
                                                "Closed"
                                                : publish_status === "draft" ?
                                                    "Saved"
                                                    : (publish_status === "published" && admin_status === "pending") ?
                                                       "Pending Review"

                                            : publish_status === "published" && admin_status === "approved" &&
                                            <>
                                                <div className="rec-child mr-1"><div className="live-circle"></div></div>
                                                <div className="rec-child">Published</div>
                                            </>
                                            }
                                        </>
                                    </div>
                                    <br />
                                    <Link to={{ pathname: `/gap-management/gap-opportunity/${id}/summary`, state: { id: id, activeTabName: activeTabName } }}>
                                        <span className="idtag mr-3">ID: {id}</span>
                                    </Link>

                                    <Link className="primaryanchor" to={{ pathname: `/gap-management/gap-opportunity/${id}/summary`, state: { id: id } }}>
                                        Edit
                                    </Link>
                                </div>
                                <p className='amount-title'>Amount Requested</p>
                                <Link to={{ pathname: `/gap-management/gap-opportunity/${id}/summary`, state: { id: id, activeTabName: activeTabName } }}>
                                    <h4 className="deals__primarytxt"><NumberFormat value={amount} displayType={'text'} thousandSeparator={true} prefix={'$'} /></h4>
                                </Link>

                                {/* <div className="primarytags mb-5">
                                    <div className="rounedtxt">
                                        <>
                                            {publish_status === "closed" ?
                                                "Closed"
                                                : publish_status === "draft" ?
                                                    "Saved"
                                                    : (publish_status === "published" && admin_status === "pending") ?
                                                        "Pending Review"
                                                        : publish_status === "published" && admin_status === "approved" &&
                                                        "Published"
                                            }
                                        </>
                                    </div> */}
                                {/* {publish_status === "closed" || publish_status === "funded" ?
                                        <Visibility
                                            mp_id={id}
                                            disabledVisibility={true}
                                            dataRecall={getAllResponse}
                                            fullAddress={address}
                                            visibility={visibility}
                                        />
                                        :
                                        <Visibility
                                            mp_id={id}
                                            disabledVisibility={false}
                                            dataRecall={getAllResponse}
                                            fullAddress={address}
                                            visibility={visibility}
                                        />
                                    } */}
                                {/* </div> */}
                                <hr />

                                <Link to={{ pathname: `/gap-management/gap-opportunity/${id}/summary`, state: { id: id, activeTabName: activeTabName } }}>
                                    <div className="row">
                                        <div className="col-md-2 col-6">
                                            <p className="heading">Opportunity Name</p>
                                            <span className="desc">{opp_name}</span>
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p className="heading">Term</p>
                                            <span className="desc">{term} months</span>
                                        </div>
                                        <div className="proposedFee col-md-2 col-6">
                                            <p className="heading">Proposed Fee</p>
                                            <NumberFormat value={proposed_fee} displayType={'text'} thousandSeparator={true} prefix={'$'} />
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p className="heading">Financing Deadline</p>
                                            <span className="desc">{Moment(closingDate).format('YYYY-MM-DD')}</span>
                                        </div>
                                        <div className="col-md-2 col-6">
                                            <p className="heading">Date Posted</p>
                                            <span className="desc">{Moment(datePosted).format('YYYY-MM-DD')}</span>
                                        </div>
                                    </div>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment >
        );
    }
}
export default OpportunityManagementListGAP;
