import React from "react";
import { NavLink } from "react-router-dom";

class GAPOpportunityManagement extends React.Component {
  state = {
    isActive: false
  }
  componentDidUpdate = (prevProps, prevState) => {
    if (prevProps.path !== this.props.path)
      this.setState({
        path: this.props.path
      }, () => {
        if (this.state.path === "/opportunity")
          this.setState({
            isActive: true
          })
      })
  }
  render() {
    return (
      <div className="col-md-8">
        <ul className="nav nav-tabs opportunity-mang">
          <NavLink to="/gap-management/all" className={this.state.isActive === true ? "nav-item nav-link active" : "nav-item nav-link"} href="#all" ref={all => this.all = all} activeClassName="active">
            All
          </NavLink>
          <NavLink to="/gap-management/published" className="nav-item nav-link" href="#published" ref={published => this.published = published} activeClassName="active">
            Published
          </NavLink>
          <NavLink to="/gap-management/funded" className="nav-item nav-link" href="#funded" ref={funded => this.funded = funded} activeClassName="active">
            Funded
          </NavLink>
          <NavLink to="/gap-management/cancelled" className="nav-item nav-link" href="#cancelled" ref={cancel => this.cancelled = cancel} activeClassName="active">
            Cancelled
          </NavLink>
          <NavLink to="/gap-management/saved" className="nav-item nav-link" href="#saved" ref={saved => this.savedTab = saved} activeClassName="active">
            Saved
          </NavLink>
        </ul>
      </div>

    );
  }
}

export default GAPOpportunityManagement;
