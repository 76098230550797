import * as actionCreators from '../actionTypes';

const initialState = {
  requestData: "",
  isLoading: false,
};

const requestReducer = (state = initialState, action) => {
  // event save 
  
  switch (action.type) {
    //entry list
    case actionCreators.REQUEST_DATA_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.REQUEST_DATA_SUCCESS: {
      return {
        ...state,
        requestData: action.payload,
        isLoading: false
      }
    }
    case actionCreators.REQUEST_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    //entry list end
    default:
      return { ...state };
  }
};
export default requestReducer;