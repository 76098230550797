import React from "react";
import { Form } from "react-bootstrap";
import Header from "../../Static/Header/Header";
import Footer from "../../Static/Footer/Footer";
import Axios from "axios";
import { apiBaseUrl } from "../../../../configs";
import refreshToken from "../../../../RefreshToken";
import { CreateGAPCard } from "../GAP/CreateGAPCard";
import { MyBorrowerDocumentTableComponent } from "../../Dashboard/BorrowerProfile/MyBorrowerDocumentTableComponent";
import { Link } from "react-router-dom";
import { LenderGAPContact } from "../../../Components/CloseOpportunityBanner/CloseOpportynityBanners";
import SidePanel from "../../Static/SidePanel/SidePanel";
import "../../../../app/Dashboard/Oppportunity/OpportunityDetailsLender/OpportunityGAPLender.css";

class LenderGAPOpportunityDetails extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            amount_requested: 0,
            borrower_name: "",
            closingDate: "",
            loan_term: 0,
            opportunityName: "",
            proposed_fee: 0,
            sponsors: [],
            attachments: [],
            summary: "",
            use_of_funds: "",
            mp_id: "",
            type: "",
            visibility: "public",
            publish_status: "",
            confirmDelete: false,
            showingInfoWindow: false,
            submitCalled: false,
            showModal: false,
            isBlocking: false,
            showError: false,
            errorMsg: false,
            validationError: false,
            openPrompt: false,
            nextLocation: null,
            profile_status: localStorage.getItem("profile_status"),
            dateValidationError: false,
            token: localStorage.getItem('token'),
            showPending: false,
            email_subject: ""
        };
        this.baseState = { ...this.state }
    }

    componentDidMount = (props) => {
        window.scrollTo(0, 0);
        this.setState({
            mp_id: this.props.match.params.mp_id,
        }, () => {
            if (this.state.mp_id !== undefined && this.state.mp_id !== null) {
                this.getGapSummary(this.state.mp_id);
                this.getBorrowerProfile(this.state.mp_id);

                this.setState({
                    email_subject: "GAP Opportunity - " + this.state.mp_id.toUpperCase()
                })
            }
        })
    }

    getGapSummary = (mp_id) => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/summary_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    amount_requested: response.data.summary.amount_requested,
                    borrower_name: response.data.summary.borrower_name,
                    closingDate: response.data.summary.closing_at,
                    loan_term: response.data.summary.loan_term,
                    opportunityName: response.data.summary.name,
                    proposed_fee: response.data.summary.proposed_fee,
                    sponsors: response.data.summary.sponsors,
                    summary: response.data.summary.summary,
                    use_of_funds: response.data.summary.use_of_funds,
                    publish_status: response.data.summary.publish_status,
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }


    getBorrowerProfile = (mp_id) => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/borrower_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                console.log("response", response);
                this.setState({
                    borrower_name: response.data.borrower_profile.name,
                    borrower_type: response.data.borrower_profile.type,
                    personal_identification: response.data.borrower_profile.personal_identification,
                    attachments: response.data.borrower_profile.attachments,
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    hasRealEstateSection = (borrowerType, sectionId) => {

        if (borrowerType === "individual") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment") || sectionId.has("real_estate.insurance") || sectionId.has("real_estate.strata")
        } else if (borrowerType === "corporation") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment")
        }
    }

    currencyFormat = (num) => {
        return '$' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
    }

    render() {

        const sectionIds = new Set(this.state.attachments.map((attachment) => attachment.section));
        
        return (
            <div>
                <Header />
                <SidePanel />
                <LenderGAPContact subject={this.state.email_subject} />
                <div className="container">
                    <div className="col pl-0 pt-3 mb-3">
                        <div className="pagetitle steppertitle">
                            <h1>
                                <Link to={{ pathname: `/gap-opportunities` }} ><i className="fa fa-arrow-left iconcolor" aria-hidden="true"></i></Link>
                                Market GAP Opportunities
                            </h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <CreateGAPCard
                                title={"GAP Opportunity Summary"}
                                createComponent={
                                    <div className="row">
                                        <div className="col-12">
                                            <h2 className="title5"> Opportunity Information </h2>
                                        </div>
                                        <div className="lender-gap col-md-6">
                                            <Form.Label>Opportunity Name</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control-gap"
                                                id="opportunityName"
                                                name="opportunityName"
                                                value={this.state.opportunityName}
                                                disabled
                                            />
                                        </ div>
                                        <div className="lender-gap col-md-6">
                                            <Form.Label>Amount Requested</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control-gap"
                                                id="amount_requested"
                                                name="amount_requested"
                                                value={this.currencyFormat(this.state.amount_requested)}
                                                disabled
                                            />
                                        </ div>
                                        <br />
                                        <div className="lender-gap col-md-6 mt-5">
                                            <Form.Label>Loan Term</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control-gap"
                                                id="loan_term"
                                                name="loan_term"
                                                value={this.state.loan_term + " months"}
                                                disabled
                                            />
                                        </ div>
                                        <div className="lender-gap col-md-6 mt-5">
                                            <Form.Label>Proposed Fee</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control-gap"
                                                id="proposed_fee"
                                                name="proposed_fee"
                                                value={this.currencyFormat(this.state.proposed_fee)}
                                                disabled
                                            />
                                        </ div>
                                        <div className="lender-gap col-md-6 mt-5">
                                            <Form.Label>Financing Deadline</Form.Label>
                                            <input
                                                type="text"
                                                className="form-control-gap"
                                                id="closing_at"
                                                name="closing_at"
                                                value={this.state.closingDate}
                                                disabled
                                            />
                                        </ div>
                                        <br />
                                        <div className="lender-gap col-12 mt-5">
                                            <Form.Label>Proposed Use of Funds Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                className="form-control textbox"
                                                id="use_of_funds"
                                                name="use_of_funds"
                                                maxLength="1000"
                                                value={this.state.use_of_funds}
                                                disabled
                                            ></Form.Control>
                                        </div>
                                        <div className="lender-gap col-12 mt-5">
                                            <Form.Label>Proposed Repayment Description</Form.Label>
                                            <Form.Control
                                                as="textarea"
                                                className="form-control textbox"
                                                id="summary"
                                                name="summary"
                                                maxLength="1000"
                                                value={this.state.summary}
                                                disabled
                                            ></Form.Control>
                                        </div>

                                        {this.state.sponsors.length > 0 && (
                                            <>
                                                <div className="col-12">
                                                    <hr />
                                                </div>
                                                <div className="col-12">
                                                    <h2 className="title5">Additional Sponsors</h2>
                                                </div>

                                                <div className="col-12 mt-4 add-new-form">
                                                    {this.state.sponsors.map((data, i) => (
                                                        <>
                                                            <div key={"Sponsor" + i}>
                                                                <h2 className="title5">Sponsor {i + 1}</h2>
                                                                <div className="row">
                                                                    <div className="lender-gap col-md-6">
                                                                        <label>Name </label>
                                                                        <input name="name"
                                                                            id={"name-" + i}
                                                                            type="text"
                                                                            value={data.name || ""}
                                                                            className="form-control-gap"
                                                                            disabled
                                                                        />

                                                                    </div>
                                                                    <div className="lender-gap col-md-6">
                                                                        <Form.Label>Annual Income</Form.Label>
                                                                        <input name="income"
                                                                            id={"income-" + i}
                                                                            type="text"
                                                                            value={this.currencyFormat(data.annual_income) || ""}
                                                                            className="form-control-gap"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="lender-gap col-md-6 mt-5">
                                                                        <Form.Label>Years of Experience</Form.Label>
                                                                        <input name="experience"
                                                                            id={"experience-" + i}
                                                                            type="text"
                                                                            value={data.experience || ""}
                                                                            className="form-control-gap"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                    <div className="lender-gap col-md-6 mt-5">
                                                                        <Form.Label>Net Worth</Form.Label>
                                                                        <input name="net_worth"
                                                                            id={"net_worth-" + i}
                                                                            type="text"
                                                                            value={this.currencyFormat(data.net_worth) || ""}
                                                                            className="form-control-gap"
                                                                            disabled
                                                                        />
                                                                    </div>
                                                                </div>
                                                                <br /><br />
                                                            </div>
                                                        </>
                                                    ))}
                                                </div>
                                            </>
                                        )}

                                        <div className="col-12">
                                            <hr />
                                        </div>
                                        <div className="col-12">
                                            <h2 className="title5">Borrower Profile</h2>
                                        </div>

                                        <div className="lender-gap col-md-6">
                                            <label>Borrower Name </label>
                                            <input name="borrower_name"
                                                id={"borrower_name"}
                                                type="text"
                                                value={this.state.borrower_name || ""}
                                                className="form-control-gap"
                                                disabled
                                            />

                                        </div>

                                        <div className="lender-gap col-md-6">
                                            <label>Individual / Corporation </label>
                                            <input name="borrower_type"
                                                id={"borrower_type"}
                                                type="text"
                                                value={this.state.borrower_type === "individual" ? "Individual" : "Corporation"}
                                                className="form-control-gap"
                                                disabled
                                            />

                                        </div>

                                        {this.state.attachments.length > 0 && (
                                            <>
                                                <div className="borrowerProfileDocumentCard mt-4">
                                                    <br /><br />
                                                    {/* <br /><br />

                                                {this.state.attachments.length === 0 && (
                                                    <div className="row">
                                                        <div className="col-12 text-center">
                                                            <h3>No Documents Uploaded</h3>
                                                        </div>
                                                    </div>
                                                )} */}


                                                    {this.state.borrower_type === "corporation" && (
                                                        <>
                                                            {sectionIds.has("org_chart") &&
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Organization Chart</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"org_chart"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                            {sectionIds.has("incorporation_docs") &&
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Incorporation Documents</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"incorporation_docs"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            }
                                                        </>
                                                    )}


                                                    {sectionIds.has("t1") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">T1 Form</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"t1"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("assessment_notice") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Notice of Assessment</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"assessment_notice"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}

                                                    {(sectionIds.has("cash.statement") || sectionIds.has("securities.statement")) && (
                                                        <>
                                                            <div className="col-12">
                                                                <hr />
                                                            </div>
                                                            {this.state.borrower_type === "individual" ? (
                                                                <>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Personal Net Worth</h3>
                                                                    </div>
                                                                    <br /><br />
                                                                </>
                                                            ) : (
                                                                <>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Year End Financial Statements</h3>
                                                                    </div>
                                                                    <br /><br />
                                                                </>
                                                            )
                                                            }
                                                        </>
                                                    )}

                                                    {sectionIds.has("cash.statement") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Cash Balances</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"cash.statement"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("securities.statement") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Stocks & Securities Accounts</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"securities.statement"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                    {this.hasRealEstateSection(this.state.borrower_type, sectionIds) && (
                                                        <>
                                                            <div className="col-12">
                                                                <hr />
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <h3 className="title5 mb-0">Completed Real Estate</h3>
                                                            </div>
                                                            <br /><br />
                                                        </>
                                                    )}
                                                    {sectionIds.has("real_estate.land_title") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Land Title</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.land_title"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("real_estate.value") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Market Value Appraisal / Self Declared Value</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.value"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("real_estate.mortgage_docs") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Mortgage Documents</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.mortgage_docs"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("real_estate.leasing_agreement") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Leasing Agreement</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.leasing_agreement"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}


                                                    {sectionIds.has("real_estate.tax_assessment") && (
                                                        <>
                                                            <div className="col-12">
                                                                <div className="form-heading">
                                                                    <p className="p-3">Property Tax Assessment</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.tax_assessment"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}

                                                    {this.state.borrower_type === "individual" && (
                                                        <>
                                                            {sectionIds.has("real_estate.insurance") && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Property Insurance Documents</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.insurance"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                            {sectionIds.has("real_estate.strata") && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Strata Status Certificate</p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"real_estate.strata"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {sectionIds.has("life_insurance") && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Life Insurance Policy</h3>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"life_insurance"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}

                                                            {sectionIds.has("automobile_loans") && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Automobile Loan / Lease Documents</h3>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"automobile_loans"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}

                                                    {this.state.borrower_type === "corporation" && (
                                                        <>
                                                            {sectionIds.has("current_projects") && (
                                                                <>
                                                                    <div className="col-12">
                                                                        <hr />
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <h3 className="title5 mb-0">Current Projects</h3>
                                                                    </div>
                                                                    <div className="col-12 mt-4">
                                                                        <MyBorrowerDocumentTableComponent
                                                                            apiData={this.state.attachments}
                                                                            section={"current_projects"}
                                                                            multiple={false}
                                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                            deleteAttachment={null}
                                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            disableEdit
                                                                        />
                                                                    </div>
                                                                </>
                                                            )}
                                                        </>
                                                    )}


                                                    {sectionIds.has("other") && (
                                                        <>
                                                            <div className="col-12">
                                                                <hr />
                                                            </div>

                                                            <div className="col-12 mt-4">
                                                                <h3 className="title5 mb-0">Other Documents</h3>
                                                            </div>
                                                            <div className="col-12 mt-4">
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"other"}
                                                                    multiple={false}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={null}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    disableEdit
                                                                />
                                                            </div>
                                                        </>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                }
                            />
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        );
    }
}
export default LenderGAPOpportunityDetails;

