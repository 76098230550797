import React from 'react';
import { FileIconDoc } from '../../../Components/FileDisplay/FileDisplay';

export const MyBorrowerDocumentTableComponent = (props) => {
    const { handleChange, handleSaveNote, disableEdit } = props

    return (
        <>
            {props.apiData.map((data, i) => {
                return data.section.includes(props.section) && <> <tr className="view-all table-row mt-2 mb-2" key={i}>
                    <td style={{ width: "10%" }}>
                        <span className="file-folder"><FileIconDoc name={data.name} /></span>
                    </td>
                    <td style={{ width: "30%" }}>{data.name}</td>
                    <td style={{ width: "50%" }}>
                        <div className="outer-container">
                            {disableEdit ? (
                                <td>{data.comment}</td>
                            ) : (
                                <>
                                    <input
                                        type="text"
                                        className="borrowerDocumentNotes"
                                        placeholder="Add Notes"
                                        // id={"fileNote" + catIndex + '-' + index}
                                        name="fileNote"
                                        onChange={(e) => handleChange(e, i)}
                                        value={data.comment}
                                        maxLength="150"
                                        disabled={disableEdit}
                                    />
                                    <img className='cursor-pointer borrower-note-check'
                                        src={require('../../../../assets/img/checked-green.svg')}
                                        onClick={(e) => handleSaveNote(e, data.external_id, i)} width="30" alt="Edit" />
                                </>
                            )}

                        </div>
                    </td>
                    <td>
                        <div className="message cursor-pointer" onClick={() => props.downloadAttachment(data.external_id)}>Download</div>
                    </td>
                    <td><span>&nbsp;&nbsp;&nbsp;</span></td>
                    {props.deleteAttachment !== null && (
                        <td>
                            <div className="message cursor-pointer" onClick={() => props.deleteAttachment(data.external_id)}>Delete</div>
                        </td>
                    )}
                </tr>
                    {props.multiple ? (
                        < hr />
                    ) : (
                        <br />
                    )}
                </>
            })}
        </>
    )
}