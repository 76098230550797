import React from "react";
import { ReusableModal } from "../ReusableModal/ReusableModal";
import { Link } from "react-router-dom";
export const PromptModal = (props) => {
    const { openPrompt, closePrompt, onConfirm, logoutFlag } = props

    return (
        <>
            <ReusableModal
                show={openPrompt}
                close={closePrompt}
                id={"approval"}
                size={"md"}
                modalHeaderContent={""}
                bodyClass={"text-center popuptxt"}
                body={
                    <div className="publish-now">
                        <h4>You have unsaved changes</h4>
                        <p className="centertxtbold">
                          {logoutFlag ? "Are you sure you want to logout?" : "Are you sure you want to continue to another page?"}  
                            </p>
                        <div className="col-12 text-center mt-5 mb-5">
                            <span type="button" className="btn borderbtn samewidth cursor-pointer mr-3" onClick={closePrompt}>Cancel</span>
                            <span onClick={onConfirm} className="btn primarybtn samewidth cursor-pointer" >Continue</span>
                        </div>
                    </div>
                }
            />
        </>
    );
}


export const ProfileConfirmationModal = (props) => {
    const { showConfirmation, closeConfirmation } = props

    return (
        <>
            <ReusableModal
                show={showConfirmation}
                close={closeConfirmation}
                id={"confirmation"}
                size={"md"}
                modalHeaderContent={""}
                bodyClass={"text-center popuptxt confirmPadding"}
                hideCloseButton={true}
                body={
                    <div>
                        <h4>Your profile is saved and pending review</h4>
                        <p className="centertxtbold pb-0 pl-0 pr-0">
                        Your edited profile will be reviewed and processed within 1 business day. Your previously approved information remains on your profile.</p>
                        <div className="col-12 text-center mt-5 mb-3">
                            <Link to="/my-profile" className="btn primarybtn samewidth">Continue </Link>
                        </div>
                    </div>
                }
            />
        </>
    );
}
