import React from 'react';

export const SubmitButton = (props) => {
    const { saveLoading, btnClass, title, isDisable } = props;
    return (
        <button type="submit" disabled={saveLoading === false ? isDisable : saveLoading} className={btnClass}>
            {
                saveLoading === true &&
                <i className="fa fa-spinner fa-spin mr-2"></i>
            } {title}
        </button>
    )
}

