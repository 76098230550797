import React from "react";

const defaultColors = [
    '#2ecc71', // emerald
    '#3498db', // peter river
    '#8e44ad', // wisteria
    '#e67e22', // carrot
    '#e74c3c', // alizarin
    '#1abc9c', // turquoise
    '#FF3D57', // midnight blue
];

function sumChars(str) {
    let sum = 0;
    for (let i = 0; i < str.length; i++) {
        sum += str.charCodeAt(i);
    }

    return sum;
}

export const initials = (name) => {
    var initials = name.match(/\b\w/g) || [];
    initials = ((initials.shift() || '') + (initials.pop() || '')).toUpperCase();
    return initials;
}

const contrast = (hex) => {
    if (hex.indexOf('#') === 0) {
        hex = hex.slice(1);
    }
    // convert 3-digit hex to 6-digits.
    if (hex.length === 3) {
        hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
    }
    if (hex.length !== 6) {
        throw new Error('Invalid HEX color.');
    }
    // invert color components
    var r = (255 - parseInt(hex.slice(0, 2), 16)).toString(16),
        g = (255 - parseInt(hex.slice(2, 4), 16)).toString(16),
        b = (255 - parseInt(hex.slice(4, 6), 16)).toString(16);
    // pad each with zeros and return
    return '#' + padZero(r) + padZero(g) + padZero(b);
}

function padZero(str, len) {
    len = len || 2;
    var zeros = new Array(len).join('0');
    return (zeros + str).slice(-len);
}

const Avatar = (props) => {
    let {
        borderRadius = '100%',
        src,
        alt = "",
        color,
        colors = defaultColors,
        size = 40,
        style,
        className
    } = props;

    const addPx = (size) => {
        return size + "px";
    }


    //log.info("alt", alt, src);
    let inner;
    if (!alt) {
        inner = ""
    }

    const abbr = initials(alt);
    size = addPx(size);

    const imageStyle = {
        display: 'inline-block',
        borderRadius
    };

    const innerStyle = {
        lineHeight: size,
        textAlign: 'center',
        color: '#FFFFFF',
        fontWeight: "bold",
        borderRadius
    };


    if (size) {
        imageStyle.width = innerStyle.width = innerStyle.maxWidth = size;
        imageStyle.height = innerStyle.height = innerStyle.maxHeight = size;
    }

    let classes = [className, 'UserAvatar'];
    if (src && src !== "") {
        inner = <img className="UserAvatar--img" style={imageStyle} src={src} alt={alt} />
    } else {
        let background;
        if (color) {
            background = color;
        } else {
            // pick a deterministic color from the list
            let i = sumChars(alt) % colors.length;
            background = colors[i];
        }

        innerStyle.backgroundColor = background;

        inner = abbr;
    }

    if (innerStyle.backgroundColor) {
        classes.push(`UserAvatar--${contrast(innerStyle.backgroundColor)}`);
    }

    return (
        <div aria-label={alt} className={classes.join(' ')} style={style}>
            <div className="UserAvatar--inner" style={innerStyle}>
                {inner}
            </div>
        </div>
    )

}
export default Avatar;