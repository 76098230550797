import React from "react";
export const Loader = (props) => {
    const { size = '4rem' } = props;
    return (
        <div className="text-center mt-5">
            <div className="spinner-border text-primary m-3" style={{ width: size, height: size }} role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}