import React from 'react';
import { Loader } from '../../../Components/Loader/Loader';
import { Link } from 'react-router-dom';

export const BellNotification = (props) => {
    const { notifications, isLoadingState, handleSaveMpId } = props;
    const role = localStorage.getItem("role");
    const path = (type, mp_id, mp_type) => {
        if (role === "broker" && mp_type === "standard") {
            return `/opportunity/details-opportunity/${mp_id}/summary`;
        } else if ((role === "broker" || role === "borrower") && mp_type === "gap") {
            return `/gap-opportunity/${mp_id}/summary`;
        } else if (role === "lender" && mp_type === "gap") {
            return `/gap-opportunities/${mp_id}`;
        } else {
            if (type === "access_revoked") {
                return `/market-opportunities`;
            } else {
                return `/my-opportunities/opportunity-details/summary/${mp_id}`;
            }
        }
    }
    return (<div id="popover-basic">
        {isLoadingState === true ? <div style={{ width: '270px' }}><Loader /></div> :

            <>
                {notifications.length !== 0 ?
                    <>
                        {notifications.map((notification, i) => {
                            return <div className="notification" key={i}>
                                <p className="m-0">{notification.title}</p>
                                {typeof notification.message !== "object" ?
                                    <span>{notification.message}</span> :
                                    <span>{notification.message[0]}
                                        <Link className="primaryanchor" to={{ pathname: path(notification.type, notification.attributes.MPId, notification.attributes.MpType), state: { chat_group_id: "" } }} onClick={() => handleSaveMpId(notification.attributes.MPId)} onContextMenu={() => handleSaveMpId(notification.attributes.MPId)}>{notification.attributes.MpName ? notification.attributes.MpName : notification.attributes.MPName}</Link>
                                        {notification.message[2]}
                                    </span>
                                }
                                {notification.type === "access_requested" ?
                                    <Link to={{ pathname: path(notification.type, notification.attributes.MPId, notification.attributes.MpType), param: { openRequest: true } }} onClick={() => handleSaveMpId(notification.attributes.MPId)} onContextMenu={() => handleSaveMpId(notification.attributes.MPId)} className="primaryanchor pl-2">View Request</Link>
                                    : ""
                                }
                                {notification.type === "profile_invite" ?
                                    <Link to={{ pathname: path(notification.type, notification.attributes.MPId, notification.attributes.MpType), param: { openRequest: true } }} onClick={() => handleSaveMpId(notification.attributes.MPId)} onContextMenu={() => handleSaveMpId(notification.attributes.MPId)} className="primaryanchor pl-2">Profile Invite</Link>
                                    : ""
                                }
                                {notification.type === "access_granted" ?
                                    <Link to={{ pathname: path(notification.type, notification.attributes.MPId, notification.attributes.MpType), param: { openRequest: true } }} onClick={() => handleSaveMpId(notification.attributes.MPId)} onContextMenu={() => handleSaveMpId(notification.attributes.MPId)} className="primaryanchor pl-2">Access Granted</Link>
                                    : ""
                                }
                                {notification.type === "network_lender_invite" && <Link to={{ pathname: "/network" }} className="primaryanchor pl-2">View Invite</Link>}
                                {notification.type === "unread_messages" && <Link to={{ pathname: "/dashboard" }} className="primaryanchor pl-2">View Message</Link>}
                            </div>
                        })}
                    </> : <div className="notification">You have {notifications.length} notification.</div>}
            </>
        }
    </div>
    )
}
