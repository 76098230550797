import React from "react";
import Header from "../../../../Static/Header/Header";
import Footer from "../../../../Static/Footer/Footer";
import SidePanel from "../../../../Static/SidePanel/SidePanel";
import NameBar from "../../../../Static/NameBar/NameBar";
import axios from "axios";
import { apiBaseUrl } from "../../../../../../configs";
import refreshToken from "../../../../../../RefreshToken";
import Swal from "sweetalert2";
import OpportunityFilter from "../../../../../Components/OpportunityInfoListing/OpportunityFilter";
import OpportunitySort from "../../../../../Components/OpportunityInfoListing/OpportunitySort";
import { SetUpProfile, CreateGAPOpportunityLink } from "../../../../../Components/OpportunityInfoListing/SetUpProfile";
import { OpportunityCardLoader } from "../../../../../Components/ContentLoader/OpportunityCardLoader";
import GAPOpportunityManagement from "../../GAPOpportunityManagement/GAPOpportunityManagement";
import OpportunityManagementListGAP from "./OpportunityManagementListGAP";
import "../../../OpportunityManagement/OpportunityManagementList/OpportunityManagement.css"

class PublishedGAP extends React.Component {
    state = {
        isLoadingState: true,
        active: [],
        opportunityDetails: [],
        activeList: [],
        searchValue: "",
        networkError: false,
        visibility: "",
        modalPrivateToPublic: false,
        modalPublicToPrivate: false,
        profile_status: localStorage.getItem('profile_status'),
        role: localStorage.getItem('role'),
        openSave: false
    };

    componentDidMount = () => {
        this.getAllResponse();
    };

    getAllResponse = () => {
        const token = localStorage.getItem("token");
        axios.get(apiBaseUrl + "/mp/list_gap", {
            headers: {
                Authorization: token,
            },
        })
            .then((response) => {
                this.setState(
                    {
                        success: true,
                        opportunityDetails: response.data.profiles || [],
                        isLoadingState: false,
                    },
                    () => {
                        const active = this.state.opportunityDetails.filter(data => data.publish_status === "published" && (data.admin_status === "approved" || data.admin_status === "pending")).map(filteredData => {
                            return filteredData
                        })
                        this.setState({
                            active: active,
                            activeList: active
                        })
                    }
                );
            })
            .catch((error) => {
                this.setState({
                    error: true,
                    isLoadingState: false,
                });
                if (error.message === 'Network Error') {
                    this.setState({
                        networkError: true
                    })
                }
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getVisibilityStatus = () => {
        const token = localStorage.getItem('token');
        const mp_id = this.state.displayId;
        const data = {
            "visibility": this.state.status
        }
        axios.put(apiBaseUrl + '/mp/visibility/' + mp_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.closePrivateToPublic();
                this.closePublicToPrivate();
                this.getAllResponse();
            })
            .catch(error => {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',
                    timer: 2000
                })
                if (error.message === 'Network Error') {
                    this.setState({
                        networkError: true
                    })
                }
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }
    filteredData = (data) => {
        this.setState({
            active: data
        })
    }
    sortData = (data) => {
        this.setState({
            active: data
        })
    }
    render() {
        return (
            <React.Fragment>
                <Header />
                <div id="wrapper">
                    <SidePanel />

                    <div id="content-wrapper" className="d-flex flex-column bg-white">
                        <div className="pagetitle-header">
                            <div className="container-fluid">
                                <div className="row">
                                    <NameBar pageTitle="GAP Opportunity Management" />
                                </div>
                            </div>
                        </div>

                        <div id="content" className="graybg">
                            <div className="container-fluid tabs-bg p-0">
                                <div className="row">
                                    <GAPOpportunityManagement />
                                    <div className="col-md-4">
                                        <OpportunityFilter
                                            opportunityDetails={this.state.activeList}
                                            filteredData={(data) => this.filteredData(data)}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="container-fluid p-0">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="tab-content">
                                            <div className="tab-pane active pad-all-20" id="all" role="tabpanel" >
                                                <div className="row opportunity-margin">
                                                    <div className="col-4">
                                                        <h5 className="gray-title"> You have {this.state.active && this.state.active.length} opportunities</h5>
                                                    </div>
                                                    <CreateGAPOpportunityLink />
                                                    <OpportunitySort
                                                        opportunityDetails={this.state.active && this.state.active}
                                                        sortData={(data) => this.sortData(data)}
                                                    />
                                                </div>
                                                <div className="row deals no-background scrolling-section">
                                                    <div className="col-12">
                                                        {this.state.isLoadingState === true ? (
                                                            <OpportunityCardLoader loop={3} />
                                                        ) : (
                                                            <div>
                                                                {this.state.active.map((details, i) => {
                                                                    return (
                                                                        <div key={i}>
                                                                            <OpportunityManagementListGAP
                                                                                index={i}
                                                                                amount={details.amount_requested}
                                                                                datePosted={details.created_at}
                                                                                id={details.mp_id}
                                                                                name={details.name}
                                                                                closingDate={details.closing_at}
                                                                                visibility={details.visibility}
                                                                                publish_status={details.publish_status}
                                                                                admin_status={details.admin_status}
                                                                                image_urls={details.image_urls}
                                                                                activeTabName="all"
                                                                                term={details.loan_term}
                                                                                proposed_fee={details.proposed_fee}
                                                                                getAllResponse={(data) => this.getAllGAP(data)}
                                                                                opportunity={details}
                                                                            />
                                                                        </div>
                                                                    );
                                                                })}
                                                                {(this.state.active && this.state.active.length === 0) && this.state.profile_status === "pending" ?
                                                                    <SetUpProfile
                                                                        display="Thank you for completing your profile. Your information is now under review by the Fundever team for Approval"
                                                                    />
                                                                    : ""}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <Footer />
            </React.Fragment >
        );
    }
}
export default PublishedGAP;
