import Axios from "axios";
import refreshToken from "../../RefreshToken";
import { apiBaseUrl } from "../../configs";
import { logger } from '../../logger';

const log = logger("BaseApi");


const handleError = (error) => {
    log.warn("error", error);
    if (error.response !== undefined) {
        if (error.response.data.code === 401) {
            refreshToken();
        } else if (error.response.data.code === 403) {
            return error.response.data.code;
        }
        return error.response.data.code;
    }
}

export default class BaseApi {
    static accessToken = localStorage.getItem("token");;
    static headers = {
        Authorization: BaseApi.accessToken
    }

    static async get(endpoint) {
        const res = await Axios.get(apiBaseUrl + endpoint, {
            headers: BaseApi.headers
        })
            .then(response => {
                if (response.code && response.code === 401) {
                    refreshToken();
                    log.info("Error : ", response);
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async put(endpoint, payload) {
        const res = await Axios.put(apiBaseUrl + endpoint, payload, {
            headers: BaseApi.headers
        })
            .then(response => {
                if (response.code && response.code === 401) {
                    refreshToken();
                    log.info("Error : ", response);
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async post(endpoint, payload, headers) {
        const res = await Axios.post(apiBaseUrl + endpoint, payload, {
            headers: BaseApi.headers
        })
            .then(response => {
                if (response.code && response.code === 401) {
                    refreshToken();
                    log.info("Error : ", response);
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }

    static async delete(endpoint) {
        const res = await Axios.delete(apiBaseUrl + endpoint, {
            headers: BaseApi.headers
        })
            .then(response => {
                if (response.code && response.code === 401) {
                    refreshToken();
                    log.info("Error : ", response);
                    return false;
                } else {
                    return response;
                }
            })
            .catch(error => {
                return handleError(error);
            });
        return await res;
    }
}