import React from 'react';
import { Link } from 'react-router-dom';


class LPHeader extends React.Component {


  signUpRole() {
    localStorage.setItem('signUpRole', 'broker')
  }

  signUpRoleLender() {
    localStorage.setItem('signUpRole', 'lender')
  }

  signUpRoleBorrower() {
    localStorage.setItem('signUpRole', 'borrower')
  }

  render() {


    return (
      <div >
        <header className="blueheader">
          <div className="container">
            <div className="navigation-wrap start-header start-style">
              <div className="container">
                <div className="row">
                  <div className="col-12">
                    <nav className="navbar navbar-expand-md navbar-light">
                      <Link to="/" className="navbar-brand logo" >
                        <img src={require('../../../assets/img/white-logo.svg')} alt="" />
                      </Link>
                      <button className="navbar-toggler" type="button" data-toggle="collapse"
                        data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                        aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto py-4 py-md-0">
                          <li className="nav-item active">
                            <Link className="nav-link" to="/borrower" onClick={() => this.signUpRoleBorrower()}>
                              Borrowers
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/broker" onClick={() => this.signUpRole()}>
                              Brokers
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/lender" onClick={() => this.signUpRoleLender()}>
                              Lenders
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/brokerage">
                              Brokerage
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/gap">
                              GAP
                            </Link>
                          </li>
                          <li className="nav-item">
                            <Link className="nav-link" to="/contact-us">
                              Contact Us
                            </Link>
                          </li>
                          <li className="nav-item loginbtn">
                            <Link className="nav-link" to="/login">Log In</Link>
                          </li>
                        </ul>
                      </div>
                    </nav>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
      </div >
    );
  }
}

export default LPHeader;