import React from "react";
import zxcvbn from 'zxcvbn';

export const PasswordMeter = (props) => {
    const { field } = props;
    const createPasswordLabel = (result) => {
        switch (result.score) {
            case 0:
                return 'Weak';
            case 1:
                return 'Weak';
            case 2:
                return 'Fair';
            case 3:
                return 'Strong';
            case 4:
                return 'Strong';
            default:
                return 'Weak';
        }
    }
    const testedResult = zxcvbn(field);
    return (
        <div className="password-strength-meter mb-3">
            <label className="password-strength-meter-label">
                {field && (
                    <>
                        <span>Password Strength: </span>
                        <span className={`pr-3 password-strength-label-result-${createPasswordLabel(testedResult)}`}>
                            {createPasswordLabel(testedResult)}
                        </span>
                    </>
                )}
            </label>
            <progress
                className={`password-strength-meter-progress strength-${createPasswordLabel(testedResult)}`}
                value={testedResult.score}
                max="3"
            />
        </div>
    )
}