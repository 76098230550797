import BaseApi from "../BaseApi";

export async function getNotification(limit, page) {
    const res = await BaseApi.get('/notifications?limit=' + limit + '&page=' + page).then(response => { return response }).catch(error => { return error });
    return res;
}
export async function getNotificationBell() {
    const res = await BaseApi.get('/notifications?limit=15').then(response => { return response }).catch(error => { return error });
    return res;
}
export async function getDashboardMessage() {
    const res = await BaseApi.get('/mp/unread_messages').then(response => { return response }).catch(error => { return error });
    return res;
}

export async function getDashboardArticles() {
    const res = await BaseApi.get('/articles').then(response => { return response }).catch(error => { return error });
    return res;
}

export async function getSingleArticles(external_id) {
    const res = await BaseApi.get(`/article/${external_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}