import React from "react";
import { Link, Redirect, withRouter } from "react-router-dom";
import { apiBaseUrl } from "../../../../configs";
import Axios from "axios";
import refreshToken from "../../../../RefreshToken";
import { BellNotification } from "./Notification";
import { DropDown } from "../../../Components/DropDown/DropDown";
import { getNotificationBell } from "../../../../Store/Api/Dashboard/DashboardApi";

class Header extends React.Component {
  state = {
    isLoadingState: true,
    notifications: [],
    notificationTaget: "",
    notificationShow: false,
    count: "",
  };
  componentDidMount = () => {
    if (this.isAuthenticated) {
      this.notificationCount();
      this.getProfile();
    }
  };
  notificationCount = () => {
    const token = localStorage.getItem("token");
    // Axios.get(apiBaseUrl + '/notifications​/new', {
    Axios.get(apiBaseUrl + "/notifications/new", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        this.setState({
          count: response.data.count,
        });
      })
      .catch((error) => {
        if (error.message === "Network Error") {
          this.setState({
            networkError: true,
          });
        }
        if (error.response !== undefined) {
          if (error.response.data.code === 401) {
            refreshToken();
          }
        }
      });
  };
  handleLogout = () => {
    if (!this.props.isBlocking) {
      localStorage.removeItem("token");
      localStorage.clear();
      this.setState();
    } else {
      this.props.logout()
    }

    // history.go(0);
  };



  isAuthenticated() {
    const token = localStorage.getItem("token");
    if (token && token.length > 10) {
      return true;
    }
  }

  getProfile = () => {
    const token = localStorage.getItem("token");
    //if (profile_status !== 'approved') {
    Axios.get(apiBaseUrl + "/my_profile", {
      headers: {
        Authorization: token,
      },
    })
      .then((response) => {
        if (response.data.account.status === "suspended") {
          this.props.history.push("/login");
        }
        localStorage.setItem("profile_status", response.data.account.status);
        localStorage.setItem(
          "completed_status",
          response.data.account.profile.completed
        );
        localStorage.setItem(
          "mp_auto_agree",
          response.data.account.settings.mp_auto_agree
        );
      })
      .catch((error) => {
        if (error.response !== undefined) {
          if (error.response.data.code === 401) {
            refreshToken();
          }
          if (error.response.data.code === 403) {
            localStorage.removeItem("token");
            localStorage.clear();
            this.props.history.push("/login");
          }
        }
      });
    //}
  };

  clickNotification = (event) => {
    this.setState(
      {
        notificationTaget: event.notificationTaget,
        notificationShow: !this.state.notificationShow,
      },
      () => {
        if (this.state.notificationShow === true) {
          getNotificationBell()
            .then((response) => {
              if (this.props.toggleLoadingState) {
                this.props.toggleLoadingState(true);
              }

              this.setState(
                {
                  notifications: response.data.notifications || [],
                  isLoadingState: false,
                },
                () => {
                  let notifications = this.state.notifications;
                  this.state.notifications.map((notification, i) => {
                    const message = notification.message;
                    var res = message.split("#");
                    notifications[i].message = res;
                    return this.setState({
                      notifications: notifications,
                    });
                  });
                  this.notificationCount();
                  if (this.props.toggleLoadingState) {
                    this.props.toggleLoadingState(false);
                  }
                }
              );
            })
            .catch((error) => {
              this.setState({
                isLoadingState: false,
              });
            });
        }
      }
    );
  };
  handleSaveMpId = (mp_id) => {
    localStorage.setItem("mp_id", mp_id);
  };
  render() {
    const isAlreadyAuthenticated = this.isAuthenticated();
    const role = localStorage.getItem("role");

    return (
      <div>
        {!isAlreadyAuthenticated ? (
          <Redirect to={{ pathname: "/login" }} />
        ) : (
          <header className="blueheader" id="blueHeader">
            <div className="container-fluid">
              <div className="navigation-wrap start-header start-style">
                <div className="">
                  <div className="row">
                    <div className="col-12">
                      <nav className="navbar navbar-expand-md navbar-light">
                        {/* <button id="sidebarToggleTop" className="btn btn-link d-md-none rounded-circle mr-3 whitebtn">
													<i className="fa fa-bars"></i>
												</button> */}
                        <div
                          className="navbar-brand logo"
                          href="/"
                          target="_blank"
                        >
                          <Link to="/dashboard">
                            <img
                              src={require("../../../../assets/img/white-logo.svg")}
                              alt=""
                            />
                          </Link>
                        </div>
                        <ul className="navbar-nav ml-auto float-right">
                          <li className="nav-item">
                            <DropDown
                              open={this.state.notificationShow}
                              position={"right"}
                              enableTopArrow={true}
                              style={{ width: "278px", top: "35px" }}
                              content={
                                <div>
                                  <BellNotification
                                    notificationShow={
                                      this.state.notificationShow
                                    }
                                    notificationTaget={
                                      this.state.notificationTaget
                                    }
                                    isLoadingState={this.state.isLoadingState}
                                    notifications={this.state.notifications}
                                    handleSaveMpId={(data) =>
                                      this.handleSaveMpId(data)
                                    }
                                  />
                                </div>
                              }
                            >
                              <span
                                className="nav-link cursor-pointer"
                                onClick={() => this.clickNotification(this)}
                              >
                                <i
                                  className="fa fa-bell-o"
                                  aria-hidden="true"
                                ></i>
                                {this.state.count > 0 ? (
                                  <div className="count">
                                    {this.state.count > 99
                                      ? "99+"
                                      : this.state.count}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </span>
                            </DropDown>
                            <div></div>
                          </li>
                          <li className="nav-item">
                            <div className="dropdown">
                              <span
                                className="nav-link dropdown-toggle cursor-pointer"
                                id="dropdownMenuButton"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                              >
                                My Account
                              </span>
                              <div
                                className="dropdown-menu"
                                aria-labelledby="dropdownMenuButton"
                              >
                                {role !== "admin" && (
                                  <>
                                    <Link
                                      to={
                                        role === "broker"
                                          ? "/my-profile"
                                          : role === "borrower"
                                            ? "/my-profile"
                                            : role === "lender"
                                              ? "/my-profile"
                                              : null
                                      }
                                      className="dropdown-item"
                                    >
                                      My Profile
                                    </Link>
                                    <Link
                                      to="/dashboard"
                                      className="dropdown-item"
                                    >
                                      Dashboard
                                    </Link>
                                  </>
                                )}
                                <Link
                                  to="/login"
                                  className="dropdown-item"
                                  onClick={this.handleLogout}
                                >
                                  Log Out
                                </Link>

                              </div>
                            </div>
                          </li>
                        </ul>
                      </nav>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </header>
        )}
      </div>
    );
  }
}

export default withRouter(Header);
