import React, { Component, Suspense, lazy } from "react";
import { Route, BrowserRouter, Switch } from "react-router-dom";
import history from './history';
import "./index.css";
import ProtectedRoute from './app/Dashboard/Auth/ProtectedRoute';
import LoadingBar from 'react-top-loading-bar'
import ChangePassword from './app/Dashboard/Auth/ResetPasswordForm/ChangePassword';
import CreateGAPOpportunity from "./app/Dashboard/Oppportunity/GAP/CreateGAPOpportunity";
import BorrowerProfile from "./app/Dashboard/Oppportunity/GAP/BorrowerProfile";
import MyBorrowerProfile from "./app/Dashboard/Dashboard/BorrowerProfile/MyBorrowerProfile";
import GAPOpportunitySummary from "./app/Admin/GAPOpportunities/GAPOpportunitySummary";
import AllGAP from "./app/Dashboard/Oppportunity/GAP/GAPOpportunityManagement/GAPOpportunityManagementList/AllGAP";
import PublishedGAP from "./app/Dashboard/Oppportunity/GAP/GAPOpportunityManagement/GAPOpportunityManagementList/PublishedGAP";
import FundedGAP from "./app/Dashboard/Oppportunity/GAP/GAPOpportunityManagement/GAPOpportunityManagementList/FundedGAP";
import SavedGAP from "./app/Dashboard/Oppportunity/GAP/GAPOpportunityManagement/GAPOpportunityManagementList/SavedGAP";
import CancelledGAP from "./app/Dashboard/Oppportunity/GAP/GAPOpportunityManagement/GAPOpportunityManagementList/CancelledGAP";
import MarketGAPOpportunities from "./app/Dashboard/Oppportunity/GAP/GAPMarketOpportunities/MarketGAPOpportunities";
import LenderGAPOpportunityDetails from "./app/Dashboard/Oppportunity/LenderOpportunityDetails/LenderGAPOpportunityDetails";

const GeneralLandingPage = lazy(() => import('./app/Website/generalLandingPage'));
const DeveloperLandingPage = lazy(() => import('./app/Website/developerLandingPage'));
const BrokerLandingPage = lazy(() => import('./app/Website/brokerLandingPage'));
const GapLandingPage = lazy(() => import('./app/Website/gapLandingPage'));
const BrokerageLandingPage = lazy(() => import('./app/Website/brokerageLandingPage'));
const LenderLandingPage = lazy(() => import('./app/Website/lenderLandingPage'));
const ContactUs = lazy(() => import('./app/Website/ContactUs/ContactUs'));
const Login = lazy(() => import('./app/Dashboard/Auth/Login'));
const Terms = lazy(() => import('./app/Website/Terms/Terms'));
const Privacy = lazy(() => import('./app/Website/Privacy/Privacy'));
const ResetPasswordForm = lazy(() => import('./app/Dashboard/Auth/ResetPasswordForm/ResetPasswordForm'));
const FAQ = lazy(() => import('./app/Website/FAQ/FAQ'));
const SignUp = lazy(() => import('./app/Dashboard/Auth/SignUp/SignUp'));
const DashBoard = lazy(() => import('./app/Dashboard/Dashboard/DashBoard'));
const CreateProfile = lazy(() => import('./app/Dashboard/Dashboard/CreateProfile/CreateProfile'));
const OpportunityDetails = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityDetails/OpportunityDetails'));
const CreateOpportunity = lazy(() => import('./app/Dashboard/Oppportunity/CreateOpportunity/CreateOpportunity'));
const Request = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/Request/Request'));
const PropertyInfo = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/PropertyInfo/PropertyInfo'));
const StructureInfo = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/StructureInfo/StructureInfo'));
const FinancialInfoPNC = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/FinancialInfoPNC/FinancialInfoPNC'));
const ProjectInfo = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/ProjectInfo/ProjectInfo'));
const NoMatchPage = lazy(() => import('./app/Dashboard/Static/404-Page/404-page'));
// const ChangePassword = lazy(() => import('./app/Dashboard/Auth/ResetPasswordForm/ChangePassword'));
const CheckEmail = lazy(() => import('./app/Dashboard/Auth/CheckEmail/CheckEmail'));
const BlogArticle = lazy(() => import('./app/Dashboard/Dashboard/Blog/BlogArticle'));
const AccessControlTab = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityFullInfo/AccessControlTab/AccessControlTab'));
const OpportunityDetailsLender = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityDetailsLender/OpportunityDetailsLender'));
const MarketOpportunities = lazy(() => import('./app/Dashboard/Oppportunity/MarketOpportunities/MarketOpportunities'));
const LenderOpportunityDetails = lazy(() => import('./app/Dashboard/Oppportunity/LenderOpportunityDetails/LenderOpportunityDetails'));
const MyProfile = lazy(() => import('./app/Dashboard/Dashboard/MyProfile/MyProfile'));
const EditProfile = lazy(() => import('./app/Dashboard/Dashboard/MyProfile/EditProfile'));
const ResetPassword = lazy(() => import('./app/Dashboard/Dashboard/MyProfile/ResetPassword'));
const LenderProfileCreation = lazy(() => import('./app/Dashboard/LenderDashboard/LenderProfileCreation/LenderProfileCreation'));
const ReviewAndFinish = lazy(() => import('./app/Dashboard/Oppportunity/ReviewAndFinish/ReviewAndFinish'));
const LenderDashBoard = lazy(() => import('./app/Dashboard/LenderDashboard/LenderDashBoard'));
const LenderMyProfile = lazy(() => import('./app/Dashboard/LenderDashboard/MyProfile/LenderMyProfile'));
const AdminDashBoard = lazy(() => import('./app/Admin/AdminDashboard'));
const Opportunities = lazy(() => import('./app/Admin/Opportunities/Opportunities'));
const GAPOpportunities = lazy(() => import('./app/Admin/Opportunities/GAPOpportunities'));
const All = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityManagement/OpportunityManagementList/All'));
const Published = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityManagement/OpportunityManagementList/Published'));
const Cancelled = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityManagement/OpportunityManagementList/Cancelled'));
const Saved = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityManagement/OpportunityManagementList/Saved'));
const Funded = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityManagement/OpportunityManagementList/Funded'));
const MyOpportunities = lazy(() => import('./app/Dashboard/Oppportunity/MyOpportunitiesLender/MyOpportunities'));
const UsersProfile = lazy(() => import('./app/Admin/Users/UserProfile'));
const Users = lazy(() => import('./app/Admin/Users/Users'));
const UserOpportunity = lazy(() => import('./app/Admin/Opportunities/UserOpportunity/UserOpportunity'));
const LenderNetworkList = lazy(() => import('./app/Dashboard/LenderNetwork/LenderNetworkList/BrokerNetwork/LenderNetworkList'));
const LenderNetwork = lazy(() => import('./app/Dashboard/LenderNetwork/LenderNetworkList/Lender/LenderNetwork'));
const ViewAllDocs = lazy(() => import('./app/Dashboard/Oppportunity/OpportunityDetails/ViewDocuments/ViewAllDocs'));
const AdminManagement = lazy(() => import('./app/Admin/Admins/AdminsManagement'));


export default class Routes extends Component {
  constructor(props) {
    super(props);
    this.state = {
      roleCheck: '',
      role: localStorage.getItem('role'),
      progress: 60
    }
  }
  componentDidMount = () => {
    this.setState({
      progress: 96
    })
  }

  componentDidUpdate = (prevProps, prevState) => {
    if (prevState.progress !== this.state.progress) {
      this.setState({
        progress: 96
      })
    }
  }

  setProgress = (val) => {
    this.setState({
      progress: val
    })
  }

  isAuthenticated() {
    const token = localStorage.getItem('token');

    if (token && token.length > 10) {
      return true;
    }
  }

  render() {
    const isAlreadyAuthenticated = this.isAuthenticated();
    const allowed_mp_types = localStorage.getItem('allowed_mp_types');
    return (
      <BrowserRouter history={history}>
        {/*<BrowserRouter basename='/fundever_temp/build/'> only for deployment*/}

        <Suspense fallback={<LoadingBar
          color='#0f669e'
          progress={96}
          onLoaderFinished={() => this.setProgress(0)}
        />}>
          <Switch>
            {isAlreadyAuthenticated ? <Route exact path="/" component={this.state.role === 'broker' ? DashBoard : (this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev') ? AdminDashBoard : this.state.role === 'lender' ? LenderDashBoard : DashBoard} /> : <Route exact path="/" component={GeneralLandingPage} />}
            <Route exact path="/borrower" component={DeveloperLandingPage} />
            <Route exact path="/broker" component={BrokerLandingPage} />
            <Route exact path="/gap" component={GapLandingPage} />
            <Route exact path="/brokerage" component={BrokerageLandingPage} />
            <Route exact path="/lender" component={LenderLandingPage} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/faq" component={FAQ} />
            <Route exact path="/login" component={Login} history={history} />
            <Route exact path="/terms" component={Terms} />
            <Route exact path="/privacy" component={Privacy} />
            <Route exact path="/reset-password" component={ResetPasswordForm} />
            <Route exact path="/reset_password/:token?" component={CheckEmail} />
            <Route exact path="/change-password" component={ChangePassword} />
            <Route exact path="/sign-up" component={SignUp} />
            <Route exact path="/activate/:token" component={SignUp} />
            <Route exact path="/blog/:external_id" component={BlogArticle} />
            {(this.state.role === 'broker' || this.state.role === 'borrower') && <ProtectedRoute exact path="/dashboard" component={DashBoard} />}
            {this.state.role === 'lender' && <ProtectedRoute exact path="/dashboard" component={LenderDashBoard} />}
            {(this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev') && <ProtectedRoute exact path="/dashboard" component={AdminDashBoard} />}
            <ProtectedRoute exact path="/create-profile" component={CreateProfile} />
            {this.state.role === 'broker' && <ProtectedRoute exact path="/my-profile" component={MyProfile} />}
            {this.state.role === 'borrower' && <ProtectedRoute exact path="/my-profile" component={MyBorrowerProfile} />}
            {this.state.role === 'lender' && <ProtectedRoute exact path="/my-profile" component={LenderMyProfile} />}
            <ProtectedRoute exact path="/lender-profile" component={LenderProfileCreation} />
            <ProtectedRoute exact path="/edit-profile" component={EditProfile} />
            <ProtectedRoute exact path="/password-reset" component={ResetPassword} />
            <ProtectedRoute exact path="/create-opportunity" component={this.state.role === 'broker' ? CreateOpportunity : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/create-gap" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes("gap")) || this.state.role === "borrower" ? CreateGAPOpportunity : NoMatchPage} />
            {/* <ProtectedRoute exact path="/gap-guarantor-profile" component={this.state.role === 'broker' || this.state.role === "borrower" ? GuarantorProfile : NoMatchPage} /> */}
            <ProtectedRoute exact path="/opportunity" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? All : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/all" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? All : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/published" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? Published : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/funded" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? Funded : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/cancelled" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? Cancelled : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/saved" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? Saved : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/details-opportunity/:mp_id?/:slug?" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? OpportunityDetails : this.state.role === 'lender' ? OpportunityDetailsLender : NoMatchPage} />
            <ProtectedRoute exact path="/opportunity/opportunity-documents/:mp_id?" component={(this.state.role === 'broker' || this.state.role === 'borrower') ? ViewAllDocs : NoMatchPage} />
            <ProtectedRoute exact path="/request/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? Request : NoMatchPage} />
            <ProtectedRoute exact path="/property-info/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role ? PropertyInfo : NoMatchPage} />
            <ProtectedRoute exact path="/structure-info/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role ? StructureInfo : NoMatchPage} />
            <ProtectedRoute exact path="/financial-info/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role ? FinancialInfoPNC : NoMatchPage} />
            <ProtectedRoute exact path="/project-info/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role ? ProjectInfo : NoMatchPage} />
            <ProtectedRoute exact path="/access-control/:mp_id?" component={this.state.role === 'broker' || this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role ? AccessControlTab : NoMatchPage} />
            {/********** GAP OPPORTUNITIES **********/}
            <ProtectedRoute exact path="/gap-management/gap-opportunity/:mp_id?/:slug?" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? CreateGAPOpportunity : NoMatchPage} />
            {/* <ProtectedRoute exact path="/opportunity/gap-access/:mp_id?" component={(this.state.role === 'broker' && allowed_mp_types.includes('gap')) ? GAPAccessControl : NoMatchPage} /> */}
            <ProtectedRoute exact path="/gap-management/gap-borrower-profile/:mp_id?" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === "borrower" ? BorrowerProfile : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? AllGAP : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/all" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? AllGAP : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/published" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? PublishedGAP : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/funded" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? FundedGAP : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/cancelled" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? CancelledGAP : NoMatchPage} />
            <ProtectedRoute exact path="/gap-management/saved" component={(this.state.role === 'broker' && allowed_mp_types !== null && allowed_mp_types.includes('gap')) || this.state.role === 'borrower' ? SavedGAP : NoMatchPage} />
            {/********** END GAP OPPORTUNITIES **********/}
            <ProtectedRoute exact path="/network"
              component={
                (() => {
                  switch (this.state.role) {
                    case 'broker':
                      return (LenderNetworkList)
                    case 'lender':
                      return (LenderNetwork)
                    default:
                      return (NoMatchPage)
                  }
                })()
              }
            />
            <ProtectedRoute exact path="/opportunity/review/:mp_id?" component={ReviewAndFinish} />

            {/* Lender's Routes */}
            <ProtectedRoute exact path="/market-opportunities" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('standard') ? MarketOpportunities : NoMatchPage} />
            <ProtectedRoute exact path="/gap-opportunities" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('gap') ? MarketGAPOpportunities : NoMatchPage} />
            <ProtectedRoute exact path="/gap-opportunities/:mp_id?" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('gap') ? LenderGAPOpportunityDetails : NoMatchPage} />
            {/* <ProtectedRoute exact path="/opportunity/details-opportunity/:mp_id" component={this.state.role === 'lender' ? OpportunityDetailsLender : NoMatchPage} /> */}
            <ProtectedRoute exact path="/my-opportunities/details-opportunity/:mp_id" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('standard') ? OpportunityDetailsLender : NoMatchPage} />
            <ProtectedRoute exact path="/my-opportunities/:slug?" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('standard') ? MyOpportunities : NoMatchPage} />
            <ProtectedRoute exact path="/my-opportunities/opportunity-details/:slug/:mp_id?" component={this.state.role === 'lender' && allowed_mp_types !== null && allowed_mp_types.includes('standard') ? LenderOpportunityDetails : NoMatchPage} />
            <ProtectedRoute exact path="/lender-profile" component={LenderProfileCreation} />

            {/* admin routes */}
            <ProtectedRoute exact path="/users" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? Users : NoMatchPage} />
            <ProtectedRoute path="/users/user-profile/:external_id" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? UsersProfile : NoMatchPage} />
            <ProtectedRoute exact path="/opportunities" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? Opportunities : NoMatchPage} />
            <ProtectedRoute exact path="/opportunities-gap" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? GAPOpportunities : NoMatchPage} />
            <ProtectedRoute exact path="/opportunities-gap/summary/:mp_id?" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? GAPOpportunitySummary : NoMatchPage} />
            <ProtectedRoute exact path="/opportunities/opportunity/:slug?/:mp_id?" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? UserOpportunity : NoMatchPage} />
            <ProtectedRoute exact path="/admin-management" component={this.state.role === 'admin' || this.state.role === 'admin_super' || this.state.role === 'admin_bizdev' ? AdminManagement : NoMatchPage} />

            <ProtectedRoute path="/not-found" component={NoMatchPage} />
            <Route component={NoMatchPage} />
          </Switch>
        </Suspense>
      </BrowserRouter >
    );
  }
}