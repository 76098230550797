import React from "react";
class AddButton extends React.Component {
    render() {
        return (
            <div>
                <div className="col-12 mt-4">
                    <h1 className="title5">{this.props.title}</h1>
                </div>
                <div className="col-12">
                    <div className={this.props.classname + " add-principal"}>
                        <button type="button" className="solidbtn" disabled={this.props.disabled} onClick={this.props.addMoreHandler}>{this.props.btnLabel}</button>
                        {this.props.htmlElem}
                    </div>
                </div>
            </div>
        );
    }
}


export default AddButton;