import * as actionCreators from '../actionTypes';

const initialState = {
  chatGroup:[],
  isLoading: false,
};

const messageChatReducer = (state = initialState, action) => {
  
  switch (action.type) {
    case actionCreators.CAHT_GROUP_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.CAHT_GROUP_SUCCESS: {
      return {
        ...state,
        chatGroup: action.payload,
        isLoading: false
      }
    }
    case actionCreators.CAHT_GROUP_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return { ...state };
  }
};
export default messageChatReducer;