import { logger } from "../../logger";

const log = logger("validate");

export const validate = (obj, e = null) => {
    let errors = [];
    const Form = document.getElementById('Form');
    if (!Form.getAttribute("ListenEnvent") && Form !== null) {
        Form.addEventListener("change", (event) => {
            validate(obj, event);
        });
        Form.addEventListener("keyup", (event) => {
            validate(obj, event);
        });
        Form.addEventListener("click", (event) => {
            validate(obj, event);
        });
        Form.setAttribute("ListenEnvent", "change");
    }

    for (const [name, arr] of Object.entries(obj)) {
        if (e !== null) {
            //log.info("Event == ", `${e.target.name}: ${e.target.value} : ${name}`);
        }
        // if (e !== null && e.target.name !== id) {
        //     return false;
        // }

        let eles = document.getElementsByName(name);

        // if (errorElement && errorElement !== null) {
        //     errorElement.remove();
        // }
        const errClass = "is-invalid";
        if (eles === null) {
            return false;
        }

        for (let j = 0; j < eles.length; j++) {

            let ele = eles[j];

            const id = ele.getAttribute("id");
            if (id !== null) {
                log.info("Ele Id ", name, id);
                let errorElement = document.getElementById('error_' + id);

                if (errorElement === null) {
                    ele.insertAdjacentHTML('afterend', '<div id="error_' + id + '" class="invalid-feedback"></div>');
                    errorElement = document.getElementById('error_' + id);
                }
                if (errorElement === null) {
                    return false;
                }
                arr.map((obj) => {
                    if (!errors.includes(name)) {
                        switch (obj.rule) {
                            case "required":

                                if (ele.value === undefined || ele.value.trim() === "") {
                                    let classNames = ele.className.split(" ");
                                    if (classNames.indexOf(errClass) === -1) {
                                        ele.className += " " + errClass;
                                    }
                                    errorElement.innerHTML = obj.message;
                                    errors.push(name);
                                    return false;
                                } else {
                                    if (errorElement !== null) {
                                        errorElement.innerHTML = "";
                                        let classNames = ele.className.split(" ");
                                        let errorIndex = classNames.indexOf(errClass);
                                        if (errorIndex !== -1) {
                                            classNames.splice(errorIndex, 1);
                                            ele.className = classNames.join(" ");
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "email":
                                if (ele.value.length > 0) {
                                    if (!validateEmail(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "alphanumeric":
                                if (ele.value.length > 0) {
                                    if (!alphanumeric(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "min":
                                if (ele.value.length > 0) {
                                    if (!min(ele.value, obj.min)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "minOneUppercase":
                                if (ele.value.length > 0) {
                                    if (!atleastOneCap(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML += obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "minOneNumber":
                                if (ele.value.length > 0) {
                                    if (!atleastOneNumber(ele.value)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;
                            case "compare":
                                if (ele.value.length > 0) {
                                    if (!compareExternalValue(ele.value, obj.to)) {
                                        let classNames = ele.className.split(" ");
                                        if (classNames.indexOf(errClass) === -1) {
                                            ele.className += " " + errClass;
                                        }
                                        log.info("errorElement:", errorElement, obj.message);
                                        errorElement.innerHTML = obj.message;
                                        errors.push(name);
                                        return false;
                                    } else {
                                        if (errorElement !== null) {
                                            errorElement.innerHTML = "";
                                            let classNames = ele.className.split(" ");
                                            let errorIndex = classNames.indexOf(errClass);
                                            if (errorIndex !== -1) {
                                                classNames.splice(errorIndex, 1);
                                                ele.className = classNames.join(" ");
                                            }
                                        }
                                        return true;
                                    }
                                }
                                break;

                            default:
                                return true;
                        }
                    }

                    return obj;

                })
            }

        }


    }
    log.info("Error :", errors);
    if (errors.length > 0) {
        return false;
    } else {
        return true;
    }

}

const min = (inputtxt, minValue) => {
    return inputtxt.length >= minValue;
}

const compareExternalValue = (inputtxt, to) => {
    let extValue = document.getElementById(to).value;
    return inputtxt === extValue;
}

const atleastOneCap = (inputtxt) => {
    return inputtxt.search(/[A-Z]/) !== -1;
}

const atleastOneNumber = (inputtxt) => {
    return inputtxt.search(/[0-9]/) !== -1;
}

const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    return re.test(String(email).toLowerCase());
}

const alphanumeric = (inputtxt) => {
    var letterNumber = /^[0-9a-zA-Z\s]+$/;
    if (inputtxt.match(letterNumber)) {
        return true;
    } else {
        return false;
    }
}