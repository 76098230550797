import React from "react";
import { ReusableModal } from "../ReusableModal/ReusableModal";
import { FileUpload } from "../FileUpload/FileUpload";
import { logger } from '../../../logger';

const log = logger("ProfileImageUpload");
export const ProfileImageUpload = (props) => {
    const { showModal, handleDrop, fileNames, removePreview, close, handleConfirm } = props;
    // const confirmSubmit = (e) => {
    //     props.handleConfirm;
    // }
    log.info("fileNames", fileNames);
    return (
        <>
            <ReusableModal
                show={showModal}
                close={close}
                backdrop="static"
                id={"edit-profile"}
                size={"md"}
                modalHeaderContent={<div className="pagetitle profile-header">
                    <h1>
                        Edit Your profile Photo
                    </h1>
                </div>}
                bodyClass={""}
                body={
                    <React.Fragment>
                        <div className="uploadOuter mt-0">
                            {fileNames.length === 0 || fileNames[0].url === "" ?
                                <div className={"mb-4"}>
                                    <FileUpload
                                        handleDrop={handleDrop}
                                        fileType={"image/*"}
                                        maxFileUpload={1}
                                        multipleFiles={false}
                                        width={"auto"}
                                        html_view={
                                            <React.Fragment>
                                                <div>
                                                    <i className="fa fa-cloud-upload dragimgicon" aria-hidden="true"></i>
                                                </div>
							                drag and drop IMAGE here Or
							                <div>
                                                    <a href="#browse">Browse File</a>
                                                </div>
                                            </React.Fragment>
                                        }
                                    /></div> :
                                <React.Fragment>
                                    <div className="preview-sec thumbsContainer mb-4">
                                        {fileNames.length > 0 && fileNames.map(
                                            (fileName, i) => (
                                                fileName.url !== "" &&
                                                <div className="previewList" key={i}>
                                                    <h5>{fileName.name}</h5>
                                                    <div className="thumb">
                                                        <div className="thumbInner">
                                                            <img src={fileName.url} alt={fileName.name} />
                                                        </div>
                                                    </div>
                                                    <span className="btn btn-danger cursor-pointer" onClick={() => removePreview(i, fileName.external_id)}>X</span>
                                                </div>
                                            ))}
                                    </div>

                                </React.Fragment>
                            }
                            <div className="pt-3 pb-3">
                                <button className="approvebtn message btn-outline-primary btn borderbtnLight mr-4" onClick={close}>Cancel</button>
                                <button className="approvebtn message btn-outline-primary btn ml-4" onClick={handleConfirm} >Confirm</button>
                            </div>
                        </div>
                    </React.Fragment>
                }
            />
        </>
    );
}
