import React from "react";
import { Modal } from "react-bootstrap";
export const ReusableModal = (props) => {
  const { show, close, body, modalHeaderContent, size, bodyClass, id, hideCloseButton, backdrop } = props
  const closeButton = hideCloseButton ? false : true;
  return (
    <Modal size={size} id={id} show={show} onHide={close} backdrop={backdrop ? backdrop : "static"}>
      <Modal.Header className="borderlessbottom">
        {modalHeaderContent}
        {closeButton && <button className="close" type="button" onClick={close} >
          <span>×</span>
        </button>}
      </Modal.Header>
      <Modal.Body className={bodyClass}>
        {body}
      </Modal.Body>
    </Modal>
  );
}
