import React from 'react';

export const Greeting = ({ name }) => {
    var now = new Date();
    var hrs = now.getHours();
    var msg = "";
    if (hrs > 4) msg = "Good Morning";      // After 4am
    if (hrs > 12) msg = "Good Afternoon";    // After 12pm
    if (hrs > 17) msg = "Good Evening";      // After 5pm
    if (hrs > 22 || (hrs <= 4 && hrs >= 0)) msg = "Hope you had a great day today";      // After 10pm

    return (
        name === undefined ?
            <h4>{msg}</h4> :
            <h4>{msg}, {name}</h4>
    )
}