import React from "react";
class AddButtonSmall extends React.Component {
    render() {
        return (
            <div>
                <div className="col-12">
                    <button type="button" className="add-new cursor-pointer" onClick={this.props.addMoreHandler}>{this.props.btnLabel}</button>
                </div>
            </div> 
        );
    }
}


export default AddButtonSmall;