import BaseApi from "../BaseApi";

// create opportunity
export async function createOpportunityApi(data) {
    const res = await BaseApi.post(`/mp/create`, data).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function createGapOpportunityApi(data) {
    const res = await BaseApi.post(`/mp/create_gap`, data).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function removeSummaryImagesApi(external_id) {
    const res = await BaseApi.delete(`/mp/summary/image/${external_id}`).then(response => { return response }).catch(error => { return error });
    return res;
}

export async function primaryImageApi(external_id, data) {
    const res = await BaseApi.put(`/mp/summary/${external_id}/primary_image`, data).then(response => { return response }).catch(error => { return error });
    return res;
}