import React from "react";
import { Form } from "react-bootstrap";
import Autocomplete from "react-google-autocomplete";

export const AutoCompleteAddress = (props) => {
  const { state, updateField, addressName, handlePlaceChanged, gridClass } = props
  const onPasteData = (e) => {
    e.preventDefault();
    return false;
  }
  return (
    <>
      <Form.Group className={gridClass}>
        <Form.Label>{addressName}Address</Form.Label>
        <Autocomplete
          onPlaceSelected={(place) => handlePlaceChanged(place)}
          types={[]}
          componentRestrictions={{ country: ["ca", "us"] }}
          value={state.address}
          onChange={(e) => updateField(e)}
          className="form-control form-control-user"
          placeholder="Type or Select Your Address"
          id="address"
          name="address"
          onPaste={(e) => onPasteData(e)}
        />

      </Form.Group>
      <Form.Group className={gridClass}>
        <Form.Label>City</Form.Label>
        {state.validationError ?
          <Form.Control
            type="text"
            className="form-control form-control-user"
            id="city"
            name="city"
            pattern="[A-Za-z ]*$"
            value={state.city}
            onChange={(e) => updateField(e)}
          /> :
          <Form.Control
            type="text"
            className="form-control form-control-user"
            id="city"
            name="city"
            value={state.city}
            onChange={(e) => updateField(e)}
          />
        }
      </Form.Group>
      <Form.Group className={gridClass}>
        <Form.Label>State/Province</Form.Label>
        {state.validationError ?
          <Form.Control
            type="text"
            className="form-control form-control-user"
            id="province"
            name="province"
            pattern="[A-Za-z ]*$"
            value={state.province}
            onChange={(e) => updateField(e)}
          /> :
          <Form.Control
            type="text"
            className="form-control form-control-user"
            id="province"
            name="province"
            value={state.province}
            onChange={(e) => updateField(e)}
          />
        }
      </Form.Group>
      <Form.Group className={gridClass}>
        <Form.Label>Country</Form.Label>
        {state.validationError ?
          <Form.Control
            type="text"
            pattern="[A-Za-z ]*$"
            className="form-control form-control-user"
            id="country"
            name="country"
            value={state.country}
            onChange={(e) => updateField(e)}
          /> :
          <Form.Control
            type="text"
            className="form-control form-control-user"
            id="country"
            name="country"
            value={state.country}
            onChange={(e) => updateField(e)}
          />
        }
      </Form.Group>
      <Form.Group className={gridClass}>
        <Form.Label>Postal Code</Form.Label>
        <Form.Control
          type="text"
          className="form-control form-control-user"
          id="postalCode"
          name="postalCode"
          value={state.postalCode}
          onChange={(e) => updateField(e)}
        />
      </Form.Group>
    </>
  );
}