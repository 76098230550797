import React from 'react'
import ContentLoader from 'react-content-loader'

export const OpportunityCardLoader = (props) => {
    const { loop = 1 } = props;
    return (
        <>
            {(() => {
                const options = [];
                for (let i = 0; i < loop; i++) {
                    options.push(<div key={i}><Row /></div>);
                }
                return options;
            })()}
        </>
    )
}

const Row = (props) => {
    return (
        <div className="deals__card cursor-pointer" >
            <div className="row">

                <div className="col-md-3">
                    <ContentLoader height={190} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                        <rect x="0" y="0" rx="0" ry="0" width="100%" height="190" />
                    </ContentLoader>
                </div>

                <div className="col-md-9">
                    <div className="deal__description">
                        <div className="float-right">
                            <ContentLoader height={10} style={{ width: '350px' }} speed={2} foregroundColor={"#dfdede"}>
                                <rect x="0" y="0" rx="0" ry="0" width="200" height="10" />
                                <rect x="210" y="0" rx="0" ry="0" width="80" height="10" />
                                <rect x="300" y="0" rx="0" ry="0" width="50" height="10" />
                            </ContentLoader>


                        </div>
                        <p className='amount-title'>
                            <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                <rect x="0" y="0" rx="0" ry="0" width="200" height="10" />
                            </ContentLoader>
                        </p>
                        <ContentLoader height={45} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                            <rect x="0" y="0" rx="0" ry="0" width="250" height="35" />
                        </ContentLoader>
                        <div className="primarytags">
                            <ContentLoader height={25} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                <rect x="10" y="0" rx="0" ry="0" width="100" height="25" />
                                <rect x="140" y="0" rx="0" ry="0" width="100" height="25" />
                            </ContentLoader>

                        </div>
                        <div className="row pt-4">
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="200" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                            <div className="col-md-2 col-6">
                                <p className="heading">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="100" height="10" />
                                    </ContentLoader>
                                </p>
                                <span className="desc">
                                    <ContentLoader height={15} style={{ width: '100%' }} speed={2} foregroundColor={"#dfdede"}>
                                        <rect x="0" y="0" rx="0" ry="0" width="150" height="12" />
                                    </ContentLoader>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}