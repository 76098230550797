import * as actionCreators from '../actionTypes';

const initialState = {
  propertyDetailsData: "",
  isLoading: false,
};

const propertyDetailsReducer = (state = initialState, action) => {
  // event save 
  
  switch (action.type) {
    //entry list
    case actionCreators.PROPERTY_DETAILS_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.PROPERTY_DETAILS_SUCCESS: {
      return {
        ...state,
        propertyDetailsData: action.payload,
        isLoading: false
      }
    }
    case actionCreators.PROPERTY_DETAILS_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    //entry list end
    default:
      return { ...state };
  }
};
export default propertyDetailsReducer;