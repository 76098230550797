import * as actionCreators from '../actionTypes';

const initialState = {
  equityStructureData: "",
  isLoading: false,
};

const equityStructureReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionCreators.EQUITY_DATA_CHECK:
      return {
        ...state,
        isLoading: true,
      };
    case actionCreators.EQUITY_DATA_SUCCESS: {
      return {
        ...state,
        equityStructureData: action.payload,
        isLoading: false
      }
    }
    case actionCreators.EQUITY_DATA_ERROR: {
      return {
        ...state,
        isLoading: false,
      }
    }
    default:
      return { ...state };
  }
};
export default equityStructureReducer;