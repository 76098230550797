export const sanitizeNumber = (num) => {
    if (isNaN(Number(num))) { return 0 } else { return Number(num); }
}

export const sanitizeDecimalNumber = (num) => {
    if (num === 0) {
        if (isNaN(Number(num))) { return 0 } else { return Number(num); }
    } else {
        if (isNaN(Number(num))) {
            return 0
        } else {
            if (Number.isInteger(num)) {
                return Number(num)
            } else {
                return Number(num).toFixed(2);
            }

        }
    }
}

export const numberFormatSanitizerInt = (num) => {
    // const pre = num.replace("$", '')
    var a = num.replace(/[^0-9.-]+/g, ""); // 1125, but a string, so convert it to number
    var integer = parseInt(a, 10);
    return integer;
}
export const numberFormatSanitizerFloat = (num) => {
    const pre = num.toString().replace(/[^0-9.-]+/g, '');
    var integer = parseFloat(pre, 10);
    return integer;
}

export const emptyNumberSanitizer = (num) => {
    if (num === 0) {
        return "";
    } else {
        return num;
    }
}

export const emptyLeadingSpaceSanitizer = (value) => {
    if (value === " ") {
        return value.replace(/^\s*/, "");
    } else {
        return value;
    }
}

export const trimInput = (value) => {
    var str = value;
    return str.trim();
}