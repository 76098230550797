import React from "react";
import { Form } from "react-bootstrap";
import Header from "../../Static/Header/Header";
import Footer from "../../Static/Footer/Footer";
import Axios from "axios";
import { apiBaseUrl } from "../../../../configs";
import refreshToken from "../../../../RefreshToken";
import { CreateGAPCard } from "./CreateGAPCard";
import { dateSanitizer } from "../../../Utils/dateSanitizer";
import { MyBorrowerDocumentTableComponent } from "../../Dashboard/BorrowerProfile/MyBorrowerDocumentTableComponent";
import { capitalizeText } from "../../../Utils/capitalizeText";
import { fileAttachmentUrl } from "../../../Utils/FileAttachmentUrl";
import { ProfileAvatar } from "../../../Components/Avatar/ProfileAvatar";
import { emptyLeadingSpaceSanitizer } from "../../../Utils/sanitizeNumber";
import { ReusableModal } from "../../../Components/ReusableModal/ReusableModal";
import { FileUpload } from "../../../Components/FileUpload/FileUpload";
import { deleteAttachmentApi } from "../../../../Store/Api/OpportunitiesApi/Attachments";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import moment from "moment";
import { PendingGAPChanges, ClosedGapOpp, PublishedOpp, PendingGapOpp } from "../../../Components/CloseOpportunityBanner/CloseOpportynityBanners";
import NumberFormat from "react-number-format";
import SidePanel from "../../Static/SidePanel/SidePanel";


class BorrowerProfile extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            borrower_name: "",
            borrower_type: "",
            email: "",
            phone: "",
            attachments: [],
            mp_id: "",
            external_id: "",
            fileNames: [{ url: '' }],
            fileNamesFromApi: [{ url: '' }],
            img_url: [],
            amount_requested: "",
            closingDate: "",
            loan_term: "",
            opportunityName: "",
            proposed_fee: "",
            sponsors: "",
            summary: "",
            use_of_funds: "",
            publish_status: "",
            admin_status: "",
            store_status: "",
            showModal: false,
            showWarningModal: false,
            success: false,
            role: localStorage.getItem('role')
        };
    }

    componentDidMount = () => {

        this.setState({
            mp_id: this.props.match.params.mp_id,
        }, () => {
            this.getBorrowerProfile(this.state.mp_id);
            this.getGapSummary(this.state.mp_id);

            if (this.state.role === "borrower") {
                this.getProfile();
            }

        })
    }

    getProfile = () => {
        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + '/my_profile', {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState(state => ({
                    img_url: [...this.state.img_url, response.data.account.image_url],
                    borrower_name: response.data.account.profile.name,
                    email: response.data.account.profile.email,
                    phone: response.data.account.profile.phone,
                }), () => {
                    let fileNames = this.state.fileNames;
                    this.state.img_url.map((data, i) => {
                        fileNames[i].url = data;
                        return this.setState({
                            fileNames, fileNamesFromApi: fileNames,
                        })
                    })
                });

            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    getBorrowerProfile = (mp_id) => {

        var token = localStorage.getItem('token');

        Axios.get(apiBaseUrl + "/mp/borrower_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    store_status: response.data.borrower_profile.store_status,
                    borrower_name: response.data.borrower_profile.name,
                    borrower_type: response.data.borrower_profile.type,
                    external_id: response.data.borrower_profile.external_id,
                    email: response.data.borrower_profile.email,
                    phone: response.data.borrower_profile.phone,
                    attachments: response.data.borrower_profile.attachments,
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    close = () => {
        this.setState({
            showModal: false,
            showWarningModal: false,
        })
    }

    getGapSummary = (mp_id) => {

        var token = localStorage.getItem('token');
        Axios.get(apiBaseUrl + "/mp/summary_gap/" + mp_id, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    amount_requested: response.data.summary.amount_requested,
                    closingDate: response.data.summary.closing_at === "" ? response.data.summary.closing_at : moment(response.data.summary.closing_at).toDate(),
                    loan_term: response.data.summary.loan_term === 0 ? "" : response.data.summary.loan_term,
                    opportunityName: response.data.summary.name,
                    proposed_fee: response.data.summary.proposed_fee,
                    sponsors: response.data.summary.sponsors,
                    summary: response.data.summary.summary,
                    use_of_funds: response.data.summary.use_of_funds,
                    publish_status: response.data.summary.publish_status,
                    admin_status: response.data.summary.admin_status,
                    store_status: response.data.summary.store_status,
                })
            })
            .catch(error => {
                this.setState({
                    error: true
                });
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });
    }

    downloadAttachment = (external_id, name) => {
        fileAttachmentUrl(external_id, name);
    }

    onConfirm = () => {
        this.navigateToNextLocation();
    }
    navigateToNextLocation = () => {
        this.unblock();
        this.props.history.push(this.state.nextLocation.pathname);
    }

    handleDateChange = (date, e) => {
        const dateValid = dateSanitizer(date);
        this.setState({
            isBlocking: true,
            closingDate: dateValid,
            dateValidationError: false
        });
    };

    handleEditProfile = () => {
        this.props.history.push({ pathname: '/my-profile/' });
    }

    onSubmit = (event) => {
        event.preventDefault();
        this.setState(() => ({
            isBlocking: false,
        }))
    };

    hasRealEstateSection = (borrowerType, sectionId) => {

        if (borrowerType === "individual") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment") || sectionId.has("real_estate.insurance") || sectionId.has("real_estate.strata")
        } else if (borrowerType === "corporation") {
            return sectionId.has("real_estate.land_title") || sectionId.has("real_estate.value")
                || sectionId.has("real_estate.mortgage_docs") || sectionId.has("real_estate.leasing_agreement")
                || sectionId.has("real_estate.tax_assessment")
        }
    }

    previousPage = () => {
        this.props.history.push("/gap-management/gap-opportunity/" + this.state.mp_id + "/summary")
    }

    publish = () => {

        if (this.state.opportunityName === "" || this.state.amount_requested === "" || this.state.loan_term === ""
            || this.state.proposed_fee === "" || this.state.closingDate === "" || this.state.use_of_funds === ""
            || this.state.borrower_name === "" || this.state.borrower_type === ""
            || (this.state.borrower_type === "individual" && this.state.personal_identification === "")) {

            this.openWarningModal();

        } else {

            const token = localStorage.getItem('token');

            Axios.put(apiBaseUrl + '/mp/publish/' + this.state.mp_id, "", {
                headers: {
                    Authorization: token
                }
            })
                .then(response => {
                    this.openStatusApproved();
                })
                .catch(error => {
                    this.setState({
                        error: true
                    });
                    if (error.response !== undefined) {
                        if (error.response.data.code === 401) {
                            refreshToken();
                        }
                    }
                });
        }
    }

    handleChange = (event) => {
        this.setState({
            [event.target.name]: emptyLeadingSpaceSanitizer(event.target.value),
        });
    }

    handleType = (event) => {

        this.setState({
            [event.target.name]: event.target.value,
        })
    }

    closeStatusApproved = () => {
        this.setState({ showModal: false });
        this.props.history.push("/gap-management/gap-opportunity/" + this.state.mp_id + "/summary");
    };

    openStatusApproved = () => {
        this.setState({ showModal: true });
    };

    openWarningModal = () => {
        this.setState({ showWarningModal: true });
    }

    downloadAttachment = (external_id, name) => {
        fileAttachmentUrl(external_id, name);
    }

    deleteAttachment = (external_id) => {
        deleteAttachmentApi(external_id).then(res => {
            this.setState({
                success: true
            })

            if (this.state.success) {
                Swal.fire({
                    position: 'top-end',
                    icon: 'success',
                    title: 'Document deleted',
                    showConfirmButton: false,
                    timer: 2000
                });
            }

            this.getBorrowerProfile(this.state.mp_id);
        });
    }

    editFileNote = (event, attachmentIndex) => {
        let attachment = this.state.attachments[attachmentIndex]
        attachment.comment = event.target.value
        this.setState({ attachment: this.state.attachments });
    };

    saveFileNote = (event, external_id, attachmentIndex) => {
        let attachment = this.state.attachments[attachmentIndex]
        let comment = attachment.comment

        const token = localStorage.getItem('token');
        const data = {
            "comment": comment,
        }
        Axios.put(apiBaseUrl + '/mp/attachment/' + external_id, data, {
            headers: { Authorization: token }
        }).then(response => {
            this.setState(state => ({
                success: true,
            }), () => {
                if (this.state.success) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Document Note Saved',
                        showConfirmButton: false,
                        timer: 2000
                    });

                }
                this.getBorrowerProfile(this.state.mp_id);
            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }
        })

    };

    handleDrop = (acceptedFiles, section) => {

        if (acceptedFiles.length !== 0) {
            acceptedFiles.map((fileName, i) => {
                const data = new FormData();
                data.append('file', fileName);
                this.uploadBorrowerAttachment(section, data);
                return fileName;
            });
        }
    }

    uploadBorrowerAttachment = (section_name, data) => {
        const external_id = this.state.external_id;
        const token = localStorage.getItem('token');
        Axios.post(apiBaseUrl + '/mp/borrower_gap/' + external_id + '/attachment/' + section_name, data, {
            headers: { Authorization: token }
        }).then(response => {
            this.setState(({
                success: true,
            }), () => {

                if (this.state.success) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Document successfully uploaded',
                        showConfirmButton: false,
                        timer: 2000
                    });
                }

                this.getBorrowerProfile(this.state.mp_id);
            });
        }).catch(error => {
            this.setState({
                error: true
            });
            if (error.response !== undefined) {
                if (error.response.data.code === 401) {
                    refreshToken();
                }
            }

            Swal.fire({
                position: 'top-end',
                icon: 'error',
                title: 'Document failed to upload',
                showConfirmButton: false,
                timer: 2000
            });
        })
    }

    handleSave = (e) => {

        var token = localStorage.getItem('token');
        const mp_id = this.state.mp_id;
        const external_id = this.state.external_id;

        const data = {
            "name": this.state.borrower_name,
            "type": this.state.borrower_type,
            "email": this.state.email,
            "phone": this.state.phone
        }

        Axios.put(apiBaseUrl + '/mp/borrower_gap/' + external_id, data, {
            headers: {
                Authorization: token
            }
        })
            .then(response => {
                this.setState({
                    success: true
                });

                if (this.state.success) {

                    Swal.fire({
                        position: 'top-end',
                        icon: 'success',
                        title: 'Borrower Profile changes saved',
                        showConfirmButton: false,
                        timer: 2000
                    });

                }

                this.getGapSummary(mp_id);
            })
            .catch(error => {
                if (error.response !== undefined) {
                    if (error.response.data.code === 401) {
                        refreshToken();
                    }
                }
            });

        // this.props.history.push("/opportunity/gap-access/" + this.state.mp_id);
    }

    nextStep = () => {
        this.props.history.push("/opportunity/gap-access/" + this.state.mp_id);
    }

    render() {

        const sectionIds = new Set(this.state.attachments.map((attachment) => attachment.section));
        const role = localStorage.getItem("role");

        return (
            <div>
                <Header />
                <SidePanel />
                {this.state.publish_status === "published" && this.state.admin_status === "approved" && this.state.store_status !== "pending" ? (
                    <PublishedOpp />
                ) : this.state.store_status === "pending" ? (
                    <PendingGAPChanges />
                ) : this.state.publish_status === "published" && this.state.admin_status === "pending" ? (
                    <PendingGapOpp />
                ) : this.state.publish_status === "closed" && (
                    <ClosedGapOpp />
                )}
                <div className="container">
                    <div className="col pl-0 pt-3 mb-3">
                        <div className="pagetitle steppertitle">
                            <h1>
                                <Link to={{ pathname: `/gap-management/gap-opportunity/` + this.state.mp_id + `/summary` }} ><i className="fa fa-arrow-left iconcolor" aria-hidden="true"></i></Link>
                                GAP Opportunity Summary
                            </h1>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-12 col-lg-12">
                            <form className="user" id="Form" onSubmit={this.onSubmit}>
                                {role === "broker" && (
                                    <CreateGAPCard
                                        title="Borrower Profile"
                                        createComponent={
                                            <>
                                                <div className="row">
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Name <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="borrower_name"
                                                            name="borrower_name"
                                                            value={this.state.borrower_name}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            placeholder="Enter Borrower Name"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Individual / Corporate <i className="fa fa-asterisk"></i></Form.Label>
                                                        <select
                                                            className="selectdropdown"
                                                            name="borrower_type"
                                                            id="borrower_type"
                                                            value={capitalizeText(this.state.borrower_type)}
                                                            onChange={this.handleType}
                                                        >
                                                            <option value="" >Select One</option>
                                                            <option value="individual" >Individual</option>
                                                            <option value="corporation" >Corporate</option>
                                                        </select>
                                                    </Form.Group>

                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Email <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            placeholder="Enter Email Address"
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Phone Number <i className="fa fa-asterisk"></i></Form.Label>
                                                        <NumberFormat
                                                            format="###-###-####"
                                                            mask="_"
                                                            className="form-control form-control-user"
                                                            placeholder="Enter phone number"
                                                            value={this.state.phone}
                                                            name="phone"
                                                            id="phone"
                                                            onChange={(e) => this.handleChange(e)} />
                                                    </Form.Group>

                                                    {this.state.admin_status !== "pending" && this.state.publish_status !== "closed" && (
                                                        <div className="col-12 text-right mt-2">
                                                            <button
                                                                type="button"
                                                                disabled={this.state.disabledSubmit}
                                                                className="solidbtn"
                                                                onClick={(e) => this.handleSave(e)}
                                                            >
                                                                Save Changes
                                                            </button>
                                                        </div>
                                                    )}
                                                    <br /> <br /> <br />
                                                    <div className="col-12">
                                                        <hr />
                                                    </div>
                                                    <br /> <br /> <br />
                                                    <div className="borrowerProfileDocumentCard">

                                                        {this.state.borrower_type === "corporation" && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Organization Chart</p>
                                                                </div>
                                                                {!sectionIds.has("org_chart") ? (
                                                                    <>
                                                                        <div class="uploadOuter">
                                                                            <FileUpload
                                                                                handleDrop={(e) => this.handleDrop(e, "org_chart")}
                                                                                fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                                multipleFiles={false}
                                                                                disable={false}
                                                                                html_view={
                                                                                    <>
                                                                                        <div>
                                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                        </div>
                                                                                        Drag and Drop Organization Chart here OR
                                                                                        <div>
                                                                                            <span className="browse">Browse File</span>
                                                                                        </div>
                                                                                    </>
                                                                                }
                                                                            />
                                                                        </div>
                                                                        <br />

                                                                    </>
                                                                ) : (
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"org_chart"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                )}
                                                                <div className="form-heading">
                                                                    <p className="p-3">Incorporation Documents</p>
                                                                </div>
                                                                <br />
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "incorporation_docs")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>      Drag and Drop Incorporation Documents here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"incorporation_docs"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                            </>
                                                        )}
                                                        <div className="form-heading">
                                                            <p className="p-3">T1 Form</p>
                                                        </div>
                                                        {!sectionIds.has("t1") ? (
                                                            <>
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "t1")}
                                                                        fileType={".pdf"}
                                                                        multipleFiles={false}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop T1 form here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                            </>
                                                        ) : (
                                                            <MyBorrowerDocumentTableComponent
                                                                apiData={this.state.attachments}
                                                                section={"t1"}
                                                                multiple={false}
                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                            />
                                                        )}

                                                        <div className="form-heading">
                                                            <p className="p-3">Notice of Assessment</p>
                                                        </div>
                                                        {!sectionIds.has("assessment_notice") ? (
                                                            <>
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "assessment_notice")}
                                                                        fileType={".pdf"}
                                                                        multipleFiles={false}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop Notice of Assessment file here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                            </>

                                                        ) : (
                                                            <MyBorrowerDocumentTableComponent
                                                                apiData={this.state.attachments}
                                                                section={"assessment_notice"}
                                                                multiple={false}
                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                            />
                                                        )}

                                                        {this.state.borrower_type === "individual" && (
                                                            <>
                                                                <div className="col-12 mt-4">
                                                                    <h3 className="title5 mb-0">Personal Net Worth</h3>
                                                                </div>
                                                                <br /><br />
                                                            </>
                                                        )}

                                                        {this.state.borrower_type === "corporation" && (
                                                            <>
                                                                <div className="col-12 mt-4">
                                                                    <h3 className="title5 mb-0">Year End Financial Statements</h3>
                                                                </div>
                                                                <br /><br />
                                                            </>
                                                        )}

                                                        <div className="form-heading">
                                                            <p className="p-3">Cash Balances</p>
                                                        </div>
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "cash.statement")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Statement of account here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"cash.statement"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <div className="form-heading">
                                                            <p className="p-3">Stocks & Securities Accounts</p>
                                                        </div>
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "securities.statement")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Statement of account here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"securities.statement"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        <div className="col-12 mt-4">
                                                            <h5 className="title5 mb-0">
                                                                Completed Real Estate
                                                            </h5>
                                                        </div>
                                                        <br />
                                                        {sectionIds.has("real_estate.land_title") && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Land Title</p>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "real_estate.land_title")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>Í
                                                                        Drag and Drop Land Title here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"real_estate.land_title"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        {sectionIds.has("real_estate.value") && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Market Value Appraisal / Self Declared Value</p>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "real_estate.value")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Market Value Appraisal / Self Declared Value here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"real_estate.value"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        {sectionIds.has("real_estate.mortgage_docs") && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Mortgage Documents</p>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "real_estate.mortgage_docs")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Mortgage Documents here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"real_estate.mortgage_docs"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        {sectionIds.has("real_estate.leasing_agreement") && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Leasing Agreement</p>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "real_estate.leasing_agreement")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Leasing Agreement here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"real_estate.leasing_agreement"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        {sectionIds.has("real_estate.tax_assessment") && (
                                                            <>
                                                                <div className="form-heading">
                                                                    <p className="p-3">Property Tax Assessment</p>
                                                                </div>
                                                                <br />
                                                            </>
                                                        )}
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "real_estate.tax_assessment")}
                                                                fileType={".pdf"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop Property Tax Assessment here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"real_estate.tax_assessment"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                        <br />
                                                        {this.state.borrower_type === "individual" && (
                                                            <>
                                                                {sectionIds.has("real_estate.insurance") && (
                                                                    <>
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Property Insurance Documents</p>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                )}
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "real_estate.insurance")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop Property Insurance Documents here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.insurance"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                                <br />
                                                                {sectionIds.has("real_estate.strata") && (
                                                                    <>
                                                                        <div className="form-heading">
                                                                            <p className="p-3">Strata Status Certificate</p>
                                                                        </div>
                                                                        <br />
                                                                    </>
                                                                )}
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "real_estate.strata")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop Strata Status Certificate here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.strata"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"real_estate.strata"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                                <br />
                                                                <div className="col-12 mt-4">
                                                                    <h5 className="title5 mb-0">
                                                                        Life Insurance Policy
                                                                    </h5>
                                                                </div>
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "life_insurance")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop Life Insurance Policy here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"life_insurance"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                                <br />
                                                                <div className="col-12 mt-4">
                                                                    <h5 className="title5 mb-0">
                                                                        Automobile Loan / Lease Documents
                                                                    </h5>
                                                                </div>
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "automobile_loans")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop Automobile Loan/Lease Documents here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"automobile_loans"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                            </>
                                                        )}
                                                        {this.state.borrower_type === "corporation" && (
                                                            <>
                                                                <div className="col-12 mt-4">
                                                                    <h5 className="title5 mb-0">
                                                                        Current Projects
                                                                    </h5>
                                                                </div>
                                                                <div class="uploadOuter">
                                                                    <FileUpload
                                                                        handleDrop={(e) => this.handleDrop(e, "current_projects")}
                                                                        fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                        multipleFiles={true}
                                                                        disable={false}
                                                                        html_view={
                                                                            <>
                                                                                <div>
                                                                                    <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                                </div>
                                                                                Drag and Drop current project files here OR
                                                                                <div>
                                                                                    <span className="browse">Browse File</span>
                                                                                </div>
                                                                            </>
                                                                        }
                                                                    />
                                                                </div>
                                                                <br />
                                                                <MyBorrowerDocumentTableComponent
                                                                    apiData={this.state.attachments}
                                                                    section={"current_projects"}
                                                                    multiple={true}
                                                                    downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                    deleteAttachment={(data) => this.deleteAttachment(data)}
                                                                    handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                    handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                />
                                                            </>
                                                        )}
                                                        <br />
                                                        <div className="col-12 mt-4">
                                                            <h5 className="title5 mb-0">
                                                                Other Documents
                                                            </h5>
                                                        </div>
                                                        <div class="uploadOuter">
                                                            <FileUpload
                                                                handleDrop={(e) => this.handleDrop(e, "other")}
                                                                fileType={".pdf,.doc,.docx,.xlsx,.jpg,.png,.jpeg,.txt,application/msword"}
                                                                multipleFiles={true}
                                                                disable={false}
                                                                html_view={
                                                                    <>
                                                                        <div>
                                                                            <img src={require('../../../../assets/img/upload_img.svg')} width="28" height="24" className="img-responsive dragimgicon" alt="upload" />
                                                                        </div>
                                                                        Drag and Drop any other applicable files here OR
                                                                        <div>
                                                                            <span className="browse">Browse File</span>
                                                                        </div>
                                                                    </>
                                                                }
                                                            />
                                                        </div>
                                                        <br />
                                                        <MyBorrowerDocumentTableComponent
                                                            apiData={this.state.attachments}
                                                            section={"other"}
                                                            multiple={true}
                                                            downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                            deleteAttachment={(data) => this.deleteAttachment(data)}
                                                            handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                            handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                        />
                                                    </div>
                                                </div>
                                                <br /><br />
                                            </>
                                        }
                                    />
                                )}

                                {role === "borrower" && (
                                    <CreateGAPCard
                                        title="Borrower Profile"
                                        createComponent={
                                            <>
                                                <div className="row">
                                                    <div className="col-12 text-center">
                                                        <ProfileAvatar
                                                            fileNames={this.state.fileNames}
                                                            enableEdit={false}
                                                            handleDrop={null}
                                                            removePreview={null}
                                                        />
                                                    </div>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Name <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="borrower_name"
                                                            name="borrower_name"
                                                            value={this.state.borrower_name}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            disabled={role === "borrower"}
                                                        />
                                                    </Form.Group>

                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Individual / Corporation <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="name"
                                                            name="name"
                                                            value={capitalizeText(this.state.borrower_type)}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            disabled
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Email <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="email"
                                                            name="email"
                                                            value={this.state.email}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            disabled={role === "borrower"}
                                                        />
                                                    </Form.Group>
                                                    <Form.Group className="form-group col-md-6">
                                                        <Form.Label>Phone Number <i className="fa fa-asterisk"></i></Form.Label>
                                                        <Form.Control
                                                            type="text"
                                                            className="form-control form-control-user"
                                                            id="phone"
                                                            name="phone"
                                                            value={this.state.phone}
                                                            onChange={(e) => this.handleChange(e)}
                                                            maxLength="25"
                                                            disabled={role === "borrower"}
                                                        />
                                                    </Form.Group>
                                                    <br /> <br /> <br />
                                                    <div className="col-12">
                                                        <hr />
                                                    </div>
                                                    <br /> <br /> <br />
                                                    <div className="borrowerProfileDocumentCard">

                                                        {this.state.attachments.length === 0 && (
                                                            <div className="row">
                                                                <div className="col-12 text-center">
                                                                    <h3>No Documents Uploaded</h3>
                                                                </div>
                                                            </div>
                                                        )}

                                                        {this.state.borrower_type === "corporation" && (
                                                            <>
                                                                {sectionIds.has("org_chart") &&
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="form-heading">
                                                                                <p className="p-3">Organization Chart</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"org_chart"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                }
                                                                {sectionIds.has("incorporation_docs") &&
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="form-heading">
                                                                                <p className="p-3">Incorporation Documents</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"incorporation_docs"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                }
                                                            </>
                                                        )}


                                                        {sectionIds.has("t1") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">T1 Form</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"t1"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("assessment_notice") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Notice of Assessment</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"assessment_notice"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}

                                                        {(sectionIds.has("cash.statement") || sectionIds.has("securities.statement")) && (
                                                            <>
                                                                <div className="col-12">
                                                                    <hr />
                                                                </div>
                                                                {this.state.borrower_type === "individual" ? (
                                                                    <>
                                                                        <div className="col-12 mt-4">
                                                                            <h3 className="title5 mb-0">Personal Net Worth</h3>
                                                                        </div>
                                                                        <br /><br />
                                                                    </>
                                                                ) : (
                                                                    <>
                                                                        <div className="col-12 mt-4">
                                                                            <h3 className="title5 mb-0">Year End Financial Statements</h3>
                                                                        </div>
                                                                        <br /><br />
                                                                    </>
                                                                )
                                                                }
                                                            </>
                                                        )}

                                                        {sectionIds.has("cash.statement") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Cash Balances</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"cash.statement"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("securities.statement") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Stocks & Securities Accounts</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"securities.statement"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                        {this.hasRealEstateSection(this.state.borrower_type, sectionIds) && (
                                                            <>
                                                                <div className="col-12">
                                                                    <hr />
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <h3 className="title5 mb-0">Completed Real Estate</h3>
                                                                </div>
                                                                <br /><br />
                                                            </>
                                                        )}
                                                        {sectionIds.has("real_estate.land_title") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Land Title</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"real_estate.land_title"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("real_estate.value") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Market Value Appraisal / Self Declared Value</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"real_estate.value"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("real_estate.mortgage_docs") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Mortgage Documents</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"real_estate.mortgage_docs"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("real_estate.leasing_agreement") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Leasing Agreement</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"real_estate.leasing_agreement"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}


                                                        {sectionIds.has("real_estate.tax_assessment") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <div className="form-heading">
                                                                        <p className="p-3">Property Tax Assessment</p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"real_estate.tax_assessment"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}

                                                        {this.state.borrower_type === "individual" && (
                                                            <>
                                                                {sectionIds.has("real_estate.insurance") && (
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="form-heading">
                                                                                <p className="p-3">Property Insurance Documents</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"real_estate.insurance"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                                {sectionIds.has("real_estate.strata") && (
                                                                    <>
                                                                        <div className="col-12">
                                                                            <div className="form-heading">
                                                                                <p className="p-3">Strata Status Certificate</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"real_estate.strata"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {sectionIds.has("life_insurance") && (
                                                                    <>
                                                                        <div className="col-12">
                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <h3 className="title5 mb-0">Life Insurance Policy</h3>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"life_insurance"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}

                                                                {sectionIds.has("automobile_loans") && (
                                                                    <>
                                                                        <div className="col-12">
                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <h3 className="title5 mb-0">Automobile Loan / Lease Documents</h3>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"automobile_loans"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}

                                                        {this.state.borrower_type === "corporation" && (
                                                            <>
                                                                {sectionIds.has("current_projects") && (
                                                                    <>
                                                                        <div className="col-12">
                                                                            <hr />
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <h3 className="title5 mb-0">Current Projects</h3>
                                                                        </div>
                                                                        <div className="col-12 mt-4">
                                                                            <MyBorrowerDocumentTableComponent
                                                                                apiData={this.state.attachments}
                                                                                section={"current_projects"}
                                                                                multiple={false}
                                                                                downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                                deleteAttachment={null}
                                                                                handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                                handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                            />
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </>
                                                        )}


                                                        {sectionIds.has("other") && (
                                                            <>
                                                                <div className="col-12">
                                                                    <hr />
                                                                </div>

                                                                <div className="col-12 mt-4">
                                                                    <h3 className="title5 mb-0">Other Documents</h3>
                                                                </div>
                                                                <div className="col-12 mt-4">
                                                                    <MyBorrowerDocumentTableComponent
                                                                        apiData={this.state.attachments}
                                                                        section={"other"}
                                                                        multiple={false}
                                                                        downloadAttachment={(data, name) => this.downloadAttachment(data, name)}
                                                                        deleteAttachment={null}
                                                                        handleChange={(e, attachmentIndex) => this.editFileNote(e, attachmentIndex)}
                                                                        handleSaveNote={(e, data, attachmentIndex) => this.saveFileNote(e, data, attachmentIndex)}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    </div>
                                                </div>
                                                <div className="col-12 text-right mt-5">
                                                    <button
                                                        type="button"
                                                        disabled={this.state.disabledSubmit}
                                                        className="solidbtn"
                                                        onClick={() => this.handleEditProfile()}
                                                    >
                                                        Edit Profile
                                                    </button>
                                                </div>
                                            </>
                                        }
                                    />
                                )}

                                <div className="col-12 text-right mt-5 create-bottom">
                                    <button type="button" className="cancelbtn mr-3 text-center" onClick={() => this.previousPage()} style={{ width: "22%" }} >
                                        Back
                                    </button>
                                    {this.state.publish_status === 'draft' && (
                                        <button
                                            type="button"
                                            disabled={this.state.disabledSubmit}
                                            className="primarybtn"
                                            onClick={() => this.publish()}
                                            style={{ width: "15%" }}
                                        >
                                            Publish
                                        </button>
                                    )}
                                </div>
                                <ReusableModal
                                    show={this.state.showModal}
                                    close={(e) => this.closeStatusApproved(e)}
                                    id={"publish"}
                                    size={"md"}
                                    modalHeaderContent={""}
                                    bodyClass={"text-center popuptxt"}
                                    body={
                                        <div className="publish-now">
                                            <h4>Congratulations</h4>
                                            <p className="centertxtbold">
                                                Your opportunity has been sent for review by the Fundever team. Once approved, your opportunity will be published and Fundever's Lender base will be automatically notified of your opportunity. The Fundever team will contact you should we need any more information.
                                            </p>
                                            <button className="solidbtnxs btn rounded" onClick={(e) => this.closeStatusApproved(e)}> Got it! </button>
                                        </div>
                                    }
                                />

                                <ReusableModal
                                    show={this.state.showWarningModal}
                                    close={(e) => this.close(e)}
                                    id={"publish"}
                                    size={"md"}
                                    modalHeaderContent={""}
                                    bodyClass={"text-center popuptxt"}
                                    body={
                                        <div className="publish-now">
                                            <h4>Please fill in these required fields in order to publish your opportunity</h4>
                                            <br />
                                            <div className="container pl-6">
                                                <div className="text-left">
                                                    {(this.state.opportunityName === "" || this.state.amount_requested === "" || this.state.loan_term === "" ||
                                                        this.state.proposed_fee === "" || this.state.closingDate === "" || this.state.use_of_funds === "") && (
                                                            <>
                                                                <p className="pt-2 pb-2 m-0 title-list">Opportunity Information</p>

                                                                <ul>
                                                                    {this.state.name === "" && <li>Opportunity Name</li>}
                                                                    {this.state.amount_requested === "" && <li>Amount Requested</li>}
                                                                    {this.state.loan_term === "" && <li>Loan Term</li>}
                                                                    {this.state.proposed_fee === "" && <li>Proposed Fee</li>}
                                                                    {this.state.closingDate === "" && <li>Financing Deadline</li>}
                                                                    {this.state.use_of_funds === "" && <li>Proposed Use of Funds</li>}
                                                                </ul>
                                                            </>
                                                        )}

                                                    {(this.state.borrower_name === "" || this.state.borrower_type === "" || this.state.email === "" || this.state.phone === "") && (
                                                        <>
                                                            <p className="pt-2 pb-2 m-0 title-list">Borrower Profile</p>
                                                            <ul>
                                                                {this.state.borrower_name === "" && <li>Borrower Name</li>}
                                                                {this.state.borrower_type === "" && <li>Individual / Corporation</li>}
                                                                {this.state.email === "" && <li>Email Address</li>}
                                                                {this.state.phone === "" && <li>Phone Number</li>}
                                                            </ul>
                                                        </>
                                                    )}
                                                </div>
                                            </div>
                                            <button className="solidbtnxs btn rounded mt-5" onClick={(e) => this.close(e)}> Got it! </button>
                                        </div>
                                    }
                                />

                            </form>
                        </div>
                    </div>
                </div>
                <Footer />
            </div >
        );
    }
}
export default BorrowerProfile;

